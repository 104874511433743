import { Paper, useTheme, Box, Badge, IconButton, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { AssemblyState } from '../../../../../entities/AssemblyState';
import { CommentBox, CommentBoxFilled, FullScreen } from '../../../../Icons/CdsIcons';
import { StyledIconButton } from '../../../../Styled/StyledIconButton';
import './CanvasControls.scss';
import { useCanvasState } from '../../../../../state/canvasState';
import { BrowserView, MobileView } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUserOutlined';
import { IpProtectionDialog } from '../../../../Trainer/Dialog/IpProtectionDialog/IpProtectionDialog';
import { CommentSideBar } from '../../../../Analytics/ProcedureReports/CommentsSideBar/CommentSideBar';
import { IProcedure } from '../../../../../entities/Analytics/AnalyticsProcedure';
import TutorialService from '../../../../../services/trainee/TraineeService';
import Loading from '../../../../Loading/Loading';

interface Props {
    zoom: (zoomLevel: number) => void;
    assemblyState: AssemblyState;
    zoomChange: number;
    isTrainer?: boolean;
    procedure?: IProcedure | undefined;
    stepIndex?: number;
    is2dStep?: boolean;
}

export const FullScreenMode = ({ playing }) => {
    const enterFullScreenMode = () => {
        if (document.fullscreenElement) {
            document.exitFullscreen();
        } else {
            document.body.requestFullscreen();
        }
    };
    const { t } = useTranslation();

    return (
        <StyledIconButton
            selected={document.fullscreenElement !== null}
            canFlip={true}
            tooltip={t('3D_VIEWER.FULLSCREEN_MODE')}
            onClick={() => enterFullScreenMode()}
            disabled={playing}
        >
            <FullScreen />
        </StyledIconButton>
    );
};

const CanvasControls: React.FC<Props> = (props) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [showIpProtectionDialog, setShowIpProtectionDialog] = useState<boolean>(false);
    const currentlyPlaying = useCanvasState((state) => state.currentlyPlaying);
    const [isFullscreen, setIsFullscreen] = useState(false);
    const [value] = useDebounce(props.zoomChange, 300);
    const [showRightPaneComment, setShowRightPaneComment] = useState<boolean>(false);
    const [stepCommentThreads, setStepCommentThreads] = useState<any>([]);
    const [stepComments, setStepComments] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [threadUpdate, setThreadUpdate] = useState<boolean>(false);
    const [threadLoading, setThreadLoading] = useState<boolean>(false);
    const [totalUnreadComments, setTotalUnreadComments] = useState<number>(0);

    useEffect(() => {
        const onFullscreenChange = () => {
            setIsFullscreen(Boolean(document.fullscreenElement));
        };
        document.addEventListener('fullscreenchange', onFullscreenChange);
        return () => document.removeEventListener('fullscreenchange', onFullscreenChange);
    }, []);

    const zoom = (zoomIn: boolean) => {};

    const ipProtectionDialog = () => {
        setAnchorEl(null);
        setShowIpProtectionDialog(true);
    };

    const closeIpProtectionDialogHandler = () => {
        setShowIpProtectionDialog(false);
        setAnchorEl(null);
    };

    const listStepCommentThread = (procedureId: string) => {
        setThreadLoading(true);
        TutorialService.listStepCommentThreads(procedureId)
            .then((e) => {
                const filteredData = e?.data?.data?.filter((obj) => obj.stepNo === props.stepIndex);
                setStepCommentThreads(filteredData);
                let unreadCount: any = 0;
                filteredData?.map((threadData) => {
                    unreadCount += threadData.unreadCount;
                });
                setTotalUnreadComments(unreadCount);
                setThreadLoading(false);
            })
            .catch(({ response }) => {
                setThreadLoading(false);
            });
    };
    //logic needs to be changed

    useEffect(() => {
        if (props.procedure) {
            listStepCommentThread(props.procedure.procedureId);
            setThreadUpdate(false);
        }
    }, [threadUpdate, props.stepIndex]);

    const commentSideBarDialogHandler = () => {
        setAnchorEl(null);
        setShowRightPaneComment(true);
    };

    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <>
            {loading && <Loading />}
            {
                <CommentSideBar
                    listAllThreads={() => {
                        if (props?.procedure) {
                            listStepCommentThread(props?.procedure?.procedureId);
                        }
                    }}
                    showRightPaneComment={showRightPaneComment}
                    setShowRightPaneComment={setShowRightPaneComment}
                    isTrainer={props.isTrainer}
                    procedure={props.procedure}
                    stepIndex={props.stepIndex}
                    stepComments={stepComments}
                    stepCommentThreads={stepCommentThreads}
                    threadUpdate={threadUpdate}
                    setThreadUpdate={setThreadUpdate}
                    threadLoading={threadLoading}
                />
            }
            <IpProtectionDialog
                openDialog={showIpProtectionDialog}
                closeDialog={closeIpProtectionDialogHandler}
            />
            <Paper
                variant="outlined"
                className="mobile-canvas-control-container-right canvas-control-container-end canvas-control-container-buttons"
            >
                {props.is2dStep && (
                    <>
                        <StyledIconButton
                            tooltip={
                                <div>
                                    <BrowserView>
                                        <div>{t('3D_VIEWER.DRAG_TOOLTIP')}</div>
                                        <br />
                                        <div>
                                            <strong>{t('3D_VIEWER.SHORTCUTS')}</strong>
                                        </div>
                                        <br />
                                        <div>{t('3D_VIEWER.ROTATE')}</div>
                                    </BrowserView>
                                    <MobileView>
                                        <div>{t('3D_VIEWER.DRAG_TOOLTIP')}</div>
                                        <br />
                                        <div>
                                            <strong>{t('3D_VIEWER.SHORTCUTS')}</strong>
                                        </div>
                                        <br />
                                        <div>{t('3D_VIEWER.MOBILE_ROTATE')}</div>
                                    </MobileView>
                                </div>
                            }
                            disabled={currentlyPlaying}
                        >
                            {/* <Grab /> */}
                            <HelpOutlineOutlinedIcon sx={{ strokeWidth: '0.4px' }} />
                        </StyledIconButton>
                    </>
                )}
                <Box
                    component="div"
                    sx={{ marginRight: '5px', display: { xs: 'none', md: 'flex' } }}
                >
                    <FullScreenMode playing={currentlyPlaying} />
                </Box>
                <Box
                    component="div"
                    sx={{ marginRight: '5px', display: { xs: 'none', md: 'flex' } }}
                >
                    <Tooltip
                        enterTouchDelay={0}
                        leaveTouchDelay={1500}
                        title={t('NAVBAR.IP_PROTECTION')}
                        placement="top"
                        arrow
                        componentsProps={{
                            tooltip: {
                                sx: {
                                    bgcolor: 'common.black',
                                    '& .MuiTooltip-arrow': {
                                        color: 'common.black',
                                    },
                                    p: 1.5,
                                    fontSize: '13px',
                                    maxWidth: '420px',
                                },
                            },
                        }}
                    >
                        <IconButton
                            disableRipple
                            disabled={currentlyPlaying}
                        >
                            <VerifiedUserIcon
                                color="primary"
                                onClick={ipProtectionDialog}
                            />
                        </IconButton>
                    </Tooltip>
                </Box>

                {!props.isTrainer && (
                    <Box
                        component="div"
                        sx={{ marginRight: '5px', display: { xs: 'none', md: 'flex' } }}
                    >
                        <StyledIconButton
                            tooltip={t('NAVBAR.COMMENTS')}
                            disabled={currentlyPlaying}
                            onClick={commentSideBarDialogHandler}
                        >
                            <Badge
                                badgeContent={totalUnreadComments}
                                color="primary"
                            >
                                {totalUnreadComments > 0 ? <CommentBoxFilled color="primary" /> : <CommentBox color="primary" />}
                            </Badge>
                        </StyledIconButton>
                    </Box>
                )}
            </Paper>
        </>
    );
};

export default CanvasControls;
