import React, { useEffect, useState } from 'react';
import { DialogActions, DialogContent, Checkbox, FormControlLabel, Button, Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ISelectedFilters } from './SubstitutionHeader';
interface LanguageDialogProps {
    onClose: () => void;
    onApply: (selectedLanguages: ISelectedFilters[]) => void;
    filterList: any;
    applyList: ISelectedFilters[];
}
const LanguageDialog: React.FC<LanguageDialogProps> = ({ onClose, onApply, filterList, applyList }) => {
    const { t } = useTranslation();
    const [selectedLanguages, setSelectedLanguage] = useState<ISelectedFilters[]>(applyList);
    const handleCheckboxChange = (language: string, keyValue) => {
        // setSelectedLanguage((prev) => (prev.value === language ? prev.filter((lang) => lang !== language);: { value: language, matchValue: keyValue }));
        setSelectedLanguage((prev) => {
            const exists = prev.some((lang) => lang.value === language);
            if (exists) {
                return prev.filter((lang) => lang.value !== language);
            } else {
                return [...prev, { matchValue: keyValue, value: language }];
            }
        });
    };

    const handleReset = () => {
        setSelectedLanguage([]);
        onApply([]);
        onClose();
    };
    const handleApply = () => {
        onApply(selectedLanguages);
        onClose();
    };
    return (
        <>
            <DialogContent
                sx={{
                    padding: '0px !important',
                    width: '380px',
                    maxWidth: '420px',
                    '& .MuiDialogContent-root': {
                        padding: '240px',
                        minWidth: '358px',
                    },
                    '& .MuiMenu-list': {
                        padding: 0,
                    },
                }}
            >
                <Box sx={{ display: 'flex', gap: '20px', height: '256px', width: '100%', borderBottom: '1px solid rgba(0, 0, 0, 0.2)' }}>
                    <Box
                        component={'div'}
                        sx={{
                            backgroundColor: '#f3f7fc',
                            justifyContent: 'center',
                            height: '100%',
                        }}
                    >
                        <Typography sx={{ padding: '8px', backgroundColor: '#d4e8ff', fontWeight: 'bold', fontSize: '16px', height: 'auto' }}>{filterList.label}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '15px' }}>
                        {filterList.filterValues.map((lang, index) => {
                            // console.log(selectedLanguages[index]?.value?.includes(lang),"      ",index,"      ",selectedLanguages)
                            return (
                                <FormControlLabel
                                    key={lang}
                                    control={
                                        <Checkbox
                                            sx={{ fontSize: '10px', padding: '5px' }}
                                            size="small"
                                            checked={selectedLanguages.some((item)=>item.value?.includes(lang))}
                                            onChange={() => handleCheckboxChange(lang, filterList.matchValues[index])}
                                        />
                                    }
                                    label={lang}
                                />
                            );
                        })}
                    </Box>
                </Box>
            </DialogContent>

            <DialogActions sx={{ padding: '8px' }}>
                <Button
                    onClick={handleReset}
                    variant="outlined"
                >
                    {t('COMMON.RESET')}
                </Button>
                <Button
                    onClick={handleApply}
                    variant="contained"
                >
                    {t('BUTTON.APPLY')}
                </Button>
            </DialogActions>
        </>
    );
};
export default LanguageDialog;
