import React, { useEffect, useState } from 'react';
import { TextField, Button, Chip, Box, Typography, InputAdornment, Tooltip, IconButton } from '@mui/material';
import { BasicDialog } from '../Trainer/Dialog/BasicDialog/BasicDialog';
import { useTranslation } from 'react-i18next';
import { DeleteOutline } from '@mui/icons-material';
const MAX_TAG_LENGTH = 25;
const TAG_LABEL_LENGTH = 10;

const TagManager = (props) => {
    const { availableTags, appliedTags, isOpen, onClose, handleSaveClick, handleDeleteClick } = props;
    const { t } = useTranslation();
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [tags, setTags] = useState<string[]>([]);
    const [applied, setApplied] = useState<string[]>([]);
    const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(true);

    useEffect(() => {
        setApplied(appliedTags);
    }, [appliedTags]);

    useEffect(() => {
        setTags(availableTags);
    }, [availableTags]);

    useEffect(() => {
        setIsSaveDisabled(false);
    }, [applied]);

    const handleAddTag = () => {
        if (searchTerm && !tags.includes(searchTerm)) {
            let tmpStr = searchTerm;
            tmpStr = tmpStr.trim();
            setTags([...tags, tmpStr]);
            setApplied([...applied, tmpStr]);
            setSearchTerm('');
        }
    };
    const handleSearchChange = (event) => {
        let searchValue:string = event.target.value;
        if(searchValue && searchValue[0].includes(" ")){
            searchValue = searchValue.trim();
        }
        if (event.target.value.length > MAX_TAG_LENGTH) {
            searchValue = event.target.value.slice(0, MAX_TAG_LENGTH);
        }
        setSearchTerm(searchValue);
    };
    const handleTagClick = (tag) => {
        if (applied.includes(tag)) {
            setApplied(applied.filter((t) => t !== tag));
        } else {
            setApplied([...applied, tag]);
        }
    };

    const onSaveClick = () => {
        setIsSaveDisabled(true);
        handleSaveClick(applied);
        setSearchTerm('');
        onClose();
    };

    const onCloseClick = () => {
        setApplied(appliedTags);
        onClose();
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleAddTag();
        }
    };

    return (
        <BasicDialog
            title={`${t('TAG_MANAGER.TAGS')}`}
            onOpen={isOpen}
            onClose={onCloseClick}
            actions={
                <>
                    <Button
                        variant="outlined"
                        onClick={onCloseClick}
                    >
                        {t('BUTTON.CANCEL')}
                    </Button>
                    <Button
                        disabled={isSaveDisabled}
                        variant="contained"
                        onClick={onSaveClick}
                        autoFocus
                    >
                        {t('BUTTON.SAVE')}
                    </Button>
                </>
            }
            fullWidth={true}
            maxWidth={'sm'}
        >
            <Box>
                <Box
                    component={'div'}
                    sx={{ display: 'flex', alignItems: 'center', margin: '0 0 20px', gap: '10px' }}
                >
                    <TextField
                        sx={{ width: '100%' }}
                        variant="outlined"
                        placeholder={t('TAG_MANAGER.SEARCH_PLACEHOLDER')}
                        value={searchTerm}
                        onChange={handleSearchChange}
                        onKeyDown={handleKeyDown}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment
                                    position={'end'}
                                    sx={{
                                        color: searchTerm.length >= MAX_TAG_LENGTH ? 'red' : '#1C1D1F',
                                        opacity: searchTerm.length >= MAX_TAG_LENGTH ? 1 : 0.4,
                                        fontWeight: 400,
                                    }}
                                >
                                    {searchTerm.length}/{MAX_TAG_LENGTH}
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleAddTag}
                        disabled={!searchTerm || tags.includes(searchTerm) || searchTerm.length > MAX_TAG_LENGTH}
                    >
                        {t('BUTTON.ADD')}
                    </Button>
                </Box>
                <Box
                    display="flex"
                    flexWrap="wrap"
                    gap={1}
                >
                    {tags.map((tag) => {
                        if (searchTerm && !tag.toLowerCase().includes(searchTerm.toLowerCase())) return null;
                        return (
                            <Tag
                                key={tag}
                                tag={tag}
                                isSelected={applied.includes(tag)}
                                handleClick={() => handleTagClick(tag)}
                                onDelete={() => handleDeleteClick(tag)}
                            />
                        );
                    })}

                    {!tags.length && t('TAG_MANAGER.NO_TAG_ADDED')}
                    {searchTerm && !tags.find((tag) => tag.toLowerCase().includes(searchTerm.toLowerCase())) && t('TAG_MANAGER.NO_TAG_FOUND')}
                </Box>
            </Box>
        </BasicDialog>
    );
};

export default TagManager;

export const Tag = (props) => {
    const { tag, handleClick = (e) => {}, sx = {}, isSelected = false, onDelete } = props;
    return (
        <Tooltip
            title={tag}
            disableHoverListener={tag.length <= TAG_LABEL_LENGTH}
        >
            <Chip
                key={tag}
                label={tag.length > TAG_LABEL_LENGTH ? tag.slice(0, TAG_LABEL_LENGTH) + '...' : tag}
                variant={isSelected ? 'filled' : 'outlined'}
                color={'primary'}
                onClick={handleClick}
                sx={sx}
                // uncomment when introducing delete tag
                // onDelete={onDelete}
                // deleteIcon={<IconButton sx={{fontSize: '15px!important'}}>
                //   <DeleteOutline fontSize='inherit' />
                // </IconButton>}
            />
        </Tooltip>
    );
};
