import { Typography, Box, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AnalyticsService from '../../../../services/Analytics/AnalyticsService';
import moment from 'moment';
import { AssignedStatusType } from '../../../../entities/Analytics/AnalyticsProcedure';
import { Toast } from '../../../Notifications/Notification';
import { useLocation } from 'react-router-dom';

interface props {
  data?: any;
}
export const AssignmentHistoryCard = ({ data }: props) => {
  const theme = useTheme();
  const {t} = useTranslation();
  const commonStyles = {
    border: 1,
    borderColor: 'text.primary',
  };

  return (
    <Box component="div" sx={{ padding: '30px 0px', display: 'flex', justifyContent: 'space-between', width: '100%', borderBottom: '1px dashed rgba(0, 0, 0, 0.1)' }}>
      <Box component="div" sx={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}>
        <Typography sx={{ fontSize: '12px', color: 'rgba(18, 24, 31, 0.6)' }}>{data.workInstructionName}</Typography>
        <Typography sx={{ fontSize: '16px', margin: '4px 0px 8px 0px' }}>{data.procedureName}</Typography>
        <Box component="span" className="style-text" sx={{ ...commonStyles, borderColor: 'grey.500', fontSize: '12px' }}>
           {t('ANALYTICS_SECTION.VERSION',{versionName:data.versionName})}{' '}
        </Box>
      </Box>
      <Box component="div">
        <Typography sx={{ fontSize: '12px', color: 'rgba(18, 24, 31, 0.6)' }}>{t('ANALYTICS_SECTION.TRAINEES')}</Typography>
        <Typography sx={{ fontSize: '16px', margin: '12px 0px' }}>{data.totalNumberOfTrainees?.length || 0}</Typography>
      </Box>
    </Box>
  );
};

export const AssignmentHistory = ({ assignmentHistory }) => {
  const { t } = useTranslation();

  return (
    <Box component="div" sx={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}>
      <Typography sx={{ fontSize: '14px', color: 'rgba(18, 24, 31, 0.6)' }}> {t('ANALYTICS_SECTION.ASSIGNMENT_HISTORY')}</Typography>

      {assignmentHistory?.map((record) => (
        <AssignmentHistoryCard data={record} />
      ))}
    </Box>
  );
};
