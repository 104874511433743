import { Card, Box, CardContent, Typography, Backdrop, FormControl, TextField, FormHelperText, IconButton, Menu, MenuItem, ClickAwayListener } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import './FolderCards.scss';
import { getFormattedDateFromUnixTime } from '../../../shared/utils/formateDate';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import Loading from '../../Loading/Loading';
import { Toast } from '../../Notifications/Notification';
import { DeleteEntityIcon, DuplicateIcon, FolderIcon, MoveIcon, RenameIcon, RestoreEntityIcon } from '../../Icons/CdsIcons';
import { useFormik } from 'formik';
import { DeleteFolderDialog } from '../../Trainer/Dialog/DeleteFolderDialog/DeleteFolderDialog';
import { DuplicateFolderDialog } from '../../Trainer/Dialog/DuplicateFolderDialog/DuplicateFolderDialog';
import MoreHoriz from '@mui/icons-material/MoreHoriz';
import TrainerService from '../../../services/trainer/TrainerService';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { useDispatch } from 'react-redux';
import { setDialogToShowAction } from '../../../redux/features/HomePage/HomePage.slice';
import { HOMEPAGE_DIALOG } from '../../../pages/Trainee/TraineeHome/constants';
import { RecycleBinActionButton } from '../RecycleBinActionButton';

interface Props {
    companyId?: string;
    isTrainer?: boolean;
    folder?: any;
    isCreateFolder?: boolean;
    setIsCreateFolder?: any;
    folders?: any;
    index?: any;
    setFolders?: any;
    onClick?: any;
    selected?: any;
    isRenameNavBar?: boolean;
    setIsRenameNavBar?: any;
    overview?: any;
    parentFolderId?: any;
    handleReloadData?: any;
    selectedFolderWiCard?: any;
    checkedMoveToFolderId?: any;
    setCheckedMoveToFolderId?: any;
    clearCardsSelection?: any;
    handleDuplicateFolder?: any;
    handleClickFolderWi?: any;
    onFolderIconClick?: any;
    newFolderTitle?: string;
    setParentFolderId?: any;
    setBreadCrumbRoute?: any;
    is2dMap?: any;
    showRecycleBinHome?: boolean;
    restoreDocument?: any;
    deletePermanentlyHandler?: any;
}

const FolderCards: React.FC<Props> = (props) => {
    const dispatch = useDispatch();

    const [contentReady, setContentReady] = React.useState<boolean>(true);
    const [isHover, setIsHover] = React.useState<boolean>(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [isRenaming, setIsRenaming] = React.useState<boolean>(false);
    const companyId = localStorage.getItem('company');
    const { t } = useTranslation();
    const [showDeleteFolderDialog, setShowDeleteFolderDialog] = React.useState<boolean>(false);
    const [showDuplicateFolderDialog, setShowDuplicateFolderDialog] = React.useState<boolean>(false);
    const [filterNonSelectedFolders, setFilterNonSelectedFolders] = React.useState<any>([]);
    const formik = useFormik({
        initialValues: {
            title: props.newFolderTitle || 'New Folder',
            description: '',
        },

        onSubmit: (values) => {
            if (props.isCreateFolder || isRenaming) {
                handleSubmit(values);
            }
        },
    });

    const handleMouseEnter = (e) => {
        setIsHover(true);
    };

    const handleMouseLeave = (e) => {
        setIsHover(false);
    };

    useEffect(() => {
        if (props.isCreateFolder || isRenaming) {
            formik.setValues({
                ...formik.values,
                title: props?.folder?.title ?? (props.newFolderTitle || 'New Folder'),
                description: props?.folder?.description ?? '',
            });
        }
    }, [props.folder, isRenaming]);

    const wrapperRef: any = useRef(null);

    const handleSubmit = (values) => {
        if (isRenaming) {
            const editedFolder = {
                title: values.title ? values.title : 'New Folder',
                description: values?.description,
            };
            TrainerService.editFolder(props.folder.folderId, editedFolder)
                .then((e) => {
                    props.handleReloadData();
                    Toast.success(t('OPERATION_SUCCESS', { ns: 'success' }), t(e.data.messageCode, { ns: 'success' }) || e.data.message);
                })
                .catch(({ response }) => {
                    props.handleReloadData();
                    Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
                });
            setIsRenaming(false);
            props.setIsRenameNavBar(false);
        } else {
            const newFolder = {
                parentFolderId: props.parentFolderId ? props.parentFolderId : '',
                title: values.title ? values.title : 'New Folder',
                description: values.description,
            };

            TrainerService.createFolder(newFolder)
                .then((e) => {
                    props.handleReloadData();

                    Toast.success(t('OPERATION_SUCCESS', { ns: 'success' }), t(e.data.messageCode, { ns: 'success' }) || e.data.message);
                })
                .catch(({ response }) => {
                    Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
                })
                .finally(() => {
                    props.setIsCreateFolder(false);
                });
        }
    };

    const submitting = useRef(false);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target) && (!submitting.current || isRenaming)) {
                submitting.current = true;
                formik.handleSubmit();
            }
        };

        const handleKeyPress = (event) => {
            if (event.key === 'Enter' && (!submitting.current || isRenaming)) {
                submitting.current = true;
                formik.handleSubmit();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('keydown', handleKeyPress);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [formik]);

    useEffect(() => {
        if (formik.isSubmitting) {
            submitting.current = true;
        }
    }, [formik.isSubmitting]);


    const theme = useTheme();

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        if (!props.selected) {
            props.onClick && props.onClick();
        }
        setAnchorEl(event.currentTarget);
    };

    const handleRemoveClick = () => {
        if (isRenaming) {
            setIsRenaming(false);
            props.setIsRenameNavBar(false);
        } else {
            props.setIsCreateFolder(false);
        }
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleRename = () => {
        setIsRenaming(true);
        setAnchorEl(null);
    };

    useEffect(() => {
        if (props.isRenameNavBar) {
            handleRename();
        }
    }, [props.isRenameNavBar]);

    const handleMove = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(null);
        let movePayload: any = [];
        props?.selectedFolderWiCard?.forEach((value) => {
            const parsedValue = JSON.parse(value);
            movePayload.push(parsedValue.id);
        });
        const filteredNonSelected = props.folders.filter(function (value) {
            return movePayload.indexOf(value.folderId) === -1;
        });

        setFilterNonSelectedFolders(filteredNonSelected);
        dispatch(setDialogToShowAction(HOMEPAGE_DIALOG.MOVE_ENTITY));
    };

    const handleDuplicateDialog = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(null);
        setShowDuplicateFolderDialog(true);
    };

    const closeDuplicateFolderDialogHandler = () => {
        setShowDuplicateFolderDialog(false);
    };

    const handleDeleteDialog = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(null);
        setShowDeleteFolderDialog(true);
    };

    const closeDeleteFolderDialogHandler = () => {
        setShowDeleteFolderDialog(false);
    };

    const handleClickAwayCards = (event) => {
        if (props.selectedFolderWiCard?.size > 0 || showDeleteFolderDialog || showDuplicateFolderDialog) {
            if (Array.from(event.target.classList).includes('multiselect-option')) {
                event.stopPropagation();
                return;
            }
            if (props.selectedFolderWiCard?.size > 0 || showDeleteFolderDialog || showDuplicateFolderDialog) {
                event.stopPropagation();
                return;
            } else {
                props.clearCardsSelection();
            }
        }
        if ((isRenaming || props.isCreateFolder) && !submitting.current) {
            formik.handleSubmit();
        }
    };

    return (
        <>
            {!contentReady && (
                <Backdrop
                    sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={!contentReady}
                >
                    <Loading />
                </Backdrop>
            )}
            <ClickAwayListener onClickAway={handleClickAwayCards}>
                <div ref={wrapperRef}>
                    <DeleteFolderDialog
                        openDialog={showDeleteFolderDialog}
                        closeDialog={closeDeleteFolderDialogHandler}
                        selectedFolderWiCard={props.selectedFolderWiCard}
                        clearCardsSelection={props.clearCardsSelection}
                        handleReloadData={props.handleReloadData}
                    />
                    <DuplicateFolderDialog
                        openDialog={showDuplicateFolderDialog}
                        closeDialog={closeDuplicateFolderDialogHandler}
                        selectedFolderWiCard={props.selectedFolderWiCard}
                        clearCardsSelection={props.clearCardsSelection}
                        handleReloadData={props.handleReloadData}
                        handleDuplicateFolder={props.handleDuplicateFolder}
                        parentFolderId={props.parentFolderId}
                    />

                    <Card
                        onMouseEnter={(e) => {
                            handleMouseEnter(e);
                        }}
                        onMouseLeave={(e) => {
                            handleMouseLeave(e);
                        }}
                        sx={{ position: 'relative', display: { xs: 'none', md: 'flex' }, backgroundColor: props.selected ? theme.palette.primary.main + '45' : isHover ? theme.palette.primary.main + '20' : 'white', border: isHover ? `2px solid ${theme.palette.primary.main + '1c'}` : null, borderRadius: '12px', boxShadow: 'rgba(149, 157, 165, 0.5) 0px 8px 24px', overflow: 'unset' }}
                        elevation={0}
                        className="work-instruction-card"
                        onClick={(e: any) => {
                            e.stopPropagation();
                            props.onClick && props.onClick();
                        }}
                        style={{ height: props.overview === 'extended_view' && props.showRecycleBinHome ? '164px' : props.overview === 'extended_view' ? '144px' : '280px', width: '100%', minWidth: props.overview === 'extended_view' ? '245px' : '400px', cursor: isHover && !props.restoreDocument ? 'pointer' : 'default' }}
                    >
                        <Box
                            component="div"
                            sx={{ margin: props.overview === 'extended_view' ? '24px 6px 0px 6px' : '54px 10px 0px 10px' }}
                        >
                            <FolderIcon overview={props.overview} />
                        </Box>
                        <Box
                            component="div"
                            sx={{ display: 'flex', flexDirection: 'column' }}
                            className={props.overview === 'extended_view' ? 'work-ins-card-right-extended-view' : 'work-ins-card-right'}
                        >
                            {(props.isCreateFolder || isRenaming) && (
                                <CardContent
                                    sx={{ display: 'flex', flexDirection: 'column', alignItems: 'inherit' }}
                                    className="work-ins-card-content"
                                >
                                    <FormControl
                                        sx={{ fontSize: props.overview === 'extended_view' ? '14px' : '20px', marginBottom: '8px', textAlign: 'left', height: '100%', width: '100%' }}
                                        className="tour-instruction-name"
                                    >
                                        <TextField
                                            InputProps={props.overview === 'extended_view' ? { style: { fontSize: '0.8rem' } } : {}}
                                            size={props.overview === 'extended_view' ? 'small' : undefined}
                                            label=""
                                            name="title"
                                            onChange={formik.handleChange}
                                            value={formik.values.title}
                                            placeholder={t('COMMON.NAME') as string}
                                            id="component-outlined"
                                            style={{ borderRadius: '8px !important' }}
                                            inputProps={{ maxLength: 55 }}
                                            required
                                            fullWidth
                                        />
                                    </FormControl>
                                    <FormControl
                                        sx={{ fontSize: props.overview === 'extended_view' ? '12px' : '20px', textAlign: 'left', height: '100%', width: '100%' }}
                                        className="tour-instruction-desc"
                                    >
                                        <TextField
                                            InputProps={props.overview === 'extended_view' ? { style: { fontSize: '0.8rem' } } : {}}
                                            size={props.overview === 'extended_view' ? 'small' : undefined}
                                            value={formik.values.description}
                                            sx={{ overflowY: 'scroll' }}
                                            name="description"
                                            id="outlined-multiline-static"
                                            onChange={formik.handleChange}
                                            placeholder={t('COMMON.DESCRIPTION_OPTIONAL') as string}
                                            multiline
                                            rows={props.overview === 'extended_view' ? 2 : 4}
                                            inputProps={{ maxLength: 200 }}
                                            required
                                            fullWidth
                                        />
                                        <FormHelperText
                                            id="component-helper-text"
                                            className="helperText"
                                            sx={{ fontSize: props.overview === 'extended_view' ? '10px' : null }}
                                        >
                                            {200 - formik.values.description?.length} {t('COMMON.CHARACTERS_LEFT') as string}
                                        </FormHelperText>
                                    </FormControl>
                                </CardContent>
                            )}

                            {!props.isCreateFolder && !isRenaming && (
                                <CardContent sx={{ flex: '1 0 auto', textAlign: 'left', padding: 0, marginTop: props.overview === 'extended_view' ? '20px' : '30px' }}>
                                    <Typography
                                        component="div"
                                        sx={{ maxHeight: '4rem', lineHeight: '24px', fontSize: props.overview === 'extended_view' ? '14px' : '20px', fontWeight: '600' }}
                                        className="card-work-ins-text one-line-ellipsis"
                                        title={props.folder.title}
                                    >
                                        {props.folder.title}
                                    </Typography>
                                    <Typography
                                        sx={{ marginBottom: '3px !important', fontSize: props.overview === 'extended_view' ? '12px' : '14px', fontWeight: '500', opacity: '70%' }}
                                        component="div"
                                        className="card-work-ins-text  multi-line-ellipsis"
                                        title={props.folder.description}
                                    >
                                        {props.folder.description}
                                    </Typography>
                                    {props.folder && (
                                        <Typography
                                            sx={{ fontSize: props.overview === 'extended_view' ? '12px' : '14px', fontWeight: '500', opacity: '70%' }}
                                            component="div"
                                            className="card-work-ins-text  multi-line-ellipsis"
                                        >
                                            {props.showRecycleBinHome ? getFormattedDateFromUnixTime(props.folder.deletedOn) : getFormattedDateFromUnixTime(props.folder.modifiedOn ? props.folder.modifiedOn : props.folder.createdOn)}
                                        </Typography>
                                    )}
                                </CardContent>
                            )}
                            {props.overview === 'normal_view' && (
                                <RecycleBinActionButton
                                    showRecycleBinHome={props.showRecycleBinHome}
                                    isTrainer={props.isTrainer}
                                    overview={props.overview}
                                    restoreDocument={props.restoreDocument}
                                    deletePermanentlyHandler={props.deletePermanentlyHandler}
                                />
                            )}
                        </Box>

                        {isHover && !props.showRecycleBinHome && !props.isCreateFolder && !isRenaming && (
                            <Box
                                sx={{
                                    alignItems: 'center',
                                    justifyContent: 'left',
                                    position: 'absolute',
                                    top: '12px',
                                    right: '12px',
                                    justifyItems: 'end',
                                }}
                            >
                                <IconButton
                                    size="small"
                                    onClick={handleClick}
                                    aria-controls={open ? 'account-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                    sx={{
                                        borderRadius: '5',
                                        p: 0,
                                        color: 'black',
                                    }}
                                >
                                    <MoreHoriz />
                                </IconButton>
                                <Menu
                                    anchorEl={anchorEl}
                                    id="account-menu"
                                    open={open}
                                    onClose={(e: any) => {
                                        e.stopPropagation();
                                        handleClose();
                                    }}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    sx={{ padding: '20px', alignContent: 'left', alignItems: 'left' }}
                                >
                                    <MenuItem
                                        onClick={(e) => {
                                            handleRename();
                                            e.stopPropagation();
                                        }}
                                    >
                                        <RenameIcon />
                                        &nbsp;{t('COMMON.RENAME')}
                                    </MenuItem>
                                    <MenuItem
                                        onClick={(e) => {
                                            handleMove(e);
                                            e.stopPropagation();
                                        }}
                                    >
                                        <MoveIcon />
                                        &nbsp;{t('COMMON.MOVE')}
                                    </MenuItem>
                                    <MenuItem
                                        onClick={(e) => {
                                            handleDuplicateDialog(e);
                                            e.stopPropagation();
                                        }}
                                    >
                                        <DuplicateIcon />
                                        &nbsp;{t('COMMON.DUPLICATE')}
                                    </MenuItem>
                                    <MenuItem
                                        onClick={(e) => {
                                            handleDeleteDialog(e);
                                            e.stopPropagation();
                                        }}
                                    >
                                        <DeleteOutlinedIcon sx={{ color: 'black' }} />
                                        &nbsp;{t('COMMON.DELETE')}
                                    </MenuItem>{' '}
                                </Menu>
                            </Box>
                        )}
                        {(props.isCreateFolder || isRenaming) && (
                            <Box
                                sx={{
                                    alignItems: 'center',
                                    justifyContent: 'left',
                                    position: 'absolute',
                                    top: '-8px',
                                    right: '-8px',
                                    justifyItems: 'end',
                                    backgroundColor: 'white',
                                    borderRadius: '50%',
                                }}
                            >
                                <IconButton
                                    size="small"
                                    onClick={handleRemoveClick}
                                    aria-controls={open ? 'account-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                    sx={{
                                        borderRadius: '5',
                                        p: 0,
                                        color: theme.palette.primary.main,
                                        backgroundColor: 'white',
                                    }}
                                >
                                    <RemoveCircleOutlineIcon sx={{ height: '24px', width: '24px' }} />
                                </IconButton>
                            </Box>
                        )}
                        {props.overview === 'extended_view' && props.showRecycleBinHome && props.isTrainer && (
                            <Box sx={{ position: 'absolute', right: '8px', bottom: '8px' }}>
                                <IconButton
                                    onClick={props.restoreDocument}
                                    sx={{
                                        borderRadius: '8px',
                                    }}
                                >
                                    <RestoreEntityIcon />
                                </IconButton>
                                <IconButton
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        props.deletePermanentlyHandler();
                                    }}
                                    sx={{
                                        borderRadius: '8px',
                                    }}
                                >
                                    <DeleteEntityIcon sx={{ color: theme.palette.primary.main }} />
                                </IconButton>
                            </Box>
                        )}
                    </Card>
                </div>
            </ClickAwayListener>
        </>
    );
};

export default FolderCards;
