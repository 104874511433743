import { Box, Button, Grid, Paper, Tooltip, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import WorkInstructionCards from '../../components/Cards/WorkInstructionCard/WorkInstructionCards';
import './RecycleBinHome.scss';
import { useParams } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useTranslation } from 'react-i18next';
import { useDocumentTitle } from '../../App';
import TourContext from '../../context/TourContext';
import { useContext } from 'react';
import useLoadDeleteEntities from '../../hooks/useLoadDeletedEntities';
import FolderCards from '../../components/Cards/FolderCards/FolderCards';
import ProcedureCards from '../../components/Cards/ProcedureCards/ProcedureCards';
import LibraryProcedureCards from '../../components/Cards/LibraryProcedureCards/LibraryProcedureCards';
import TableContainer, { TableColumnHeader } from '../../components/Analytics/ProcedureReports/TableContainer';
import { EntityName } from '../Trainee/TraineeHome/ReuseableComponents';
import { getFormattedDateFromUnixTime } from '../../shared/utils/formateDate';
import { DeleteDocumentPermanentlyDialog } from '../../components/Trainer/Dialog/DeleteDocumentPermanentlyDialog/DeleteDocumentPermanentlyDialog';
import Loading from '../../components/Loading/Loading';
import TutorialService from '../../services/trainee/TraineeService';
import { Toast } from '../../components/Notifications/Notification';
import { RecycleBinTopBar } from './RecycleBinTopBar';
import { AppliedFilterBar } from './AppliedFilterBar';
import { RecycleBinEmptyState } from './RecycleBinEmptyState';

interface Props {
    isTrainer?: boolean;
    isDelete?: boolean;
}

interface IMenuItem {
    id: string;
    title: string;
    paths: Array<string>;
}

interface IMainMenu {
    [key: string]: IMenuItem;
}

const documentsColumn = [
    {
        Header: <TableColumnHeader label={'COMMON.NAME'} />,
        accessor: 'name',
        Cell: EntityName,
    },
    {
        Header: <TableColumnHeader label={`COMMON.DESCRIPTION_WORK_INSTRUCTION`} />,
        accessor: 'description',
        disableSortBy: true,
        Cell: ({ value, row }) => (
            <Tooltip title={value}>
                <Typography
                    className="one-line-ellipsis"
                    sx={{ fontSize: '14px', fontWeight: 400, opacity: '70%', color: '#312D32' }}
                >
                    {value}
                </Typography>
            </Tooltip>
        ),
    },
    {
        Header: <TableColumnHeader label={'COMMON.BRAND'} />,
        accessor: 'brand',
        Cell: ({ value, row }) => <Typography sx={{ fontSize: '14px', fontWeight: 400, opacity: '70%', color: '#312D32' }}>{value}</Typography>,
    },
    {
        Header: <TableColumnHeader label={'COMMON.DELETED_DATE'} />,
        accessor: 'deletedDate',
        Cell: ({ value, row }) => <span style={{ fontSize: '14px', fontWeight: 400, opacity: '70%', color: '#312D32' }}>{getFormattedDateFromUnixTime(value)}</span>,
    },
    {
        header: '',
        accessor: 'id',
        disableSortBy: true,
        Cell: ({ value, row }) => {
            const { restoreDocument, deleteDocumentPermanentlyHandler } = row.original;
            return (
                <>
                    <Box className="show-on-hover">
                        <Button
                            variant="contained"
                            sx={{ mr: '12px' }}
                            onClick={(event) => {
                                event.stopPropagation();
                                restoreDocument(row.original.id, row.original.type);
                            }}
                        >
                            <TableColumnHeader label={'BUTTON.RESTORE'} />
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={(event) => {
                                event.stopPropagation();
                                deleteDocumentPermanentlyHandler(row.original.id, row.original.type);
                            }}
                        >
                            <TableColumnHeader label={'BUTTON.DELETE'} />
                        </Button>
                    </Box>
                </>
            );
        },
    },
];

const RecycleBinHome: React.FC<Props> = (props) => {
    const { t } = useTranslation();
    const [sort, setSort] = useState<string>('');
    const { loadDeleteData, documents, setDocuments, totalDocumentsLength, currPage, loading, setLoading, setCurrPage, imageObject, setSearchVal, searchVal, filterVal, setFilterVal, setSortVal, sortVal } = useLoadDeleteEntities();
    const [appliedFilterList, setAppliedFilterList] = useState<Array<any>>([]);
    const [overview, setOverview] = useState<string>('normal_view');
    const [selectedDocument, setSelectedDocument] = useState<any>({ id: '', type: '' });
    const [showDeleteDocumentDialog, setShowDeleteDocumentDialog] = useState<boolean>(false);
    const [documentsTableData, setDocumentsTableData] = useState<Array<any>>([]);
    const { isDeletedWorkInstructionBackButton, setIsDeletedWorkInstructionBackButton } = useContext(TourContext);
    const [restoreLoading, setRestoreLoading] = useState<boolean>(false);

    const { companyId } = useParams();
    useDocumentTitle('CDS Mentor Recycle Bin');
    useEffect(() => {
        loadDeleteData();
    }, []);

    const applyFilters = (filters) => {
        setAppliedFilterList(filters);
        const appliedFilters = {};
        filters.forEach(({ filter: filterKey, value: filterValue, applied }) => {
            if (applied) {
                if (!appliedFilters[filterKey]) {
                    appliedFilters[filterKey] = [];
                }
                appliedFilters[filterKey].push(filterValue);
            }
        });
        const appliedFilterValues = filters.filter((item) => item.applied).map((item) => item.value);
        setFilterVal(appliedFilterValues);
        setCurrPage(1);
        loadDeleteData(searchVal, sortVal, appliedFilterValues, 1);
    };

    const handleReloadData = () => {
        setCurrPage(1);
        loadDeleteData(searchVal, sortVal, filterVal, 1);
    };
    const theme = useTheme();

    const getDocumentsTableData = () => {
        const arr: any = [];
        documents?.map((document) => {
            arr.push({
                name: document.type === 'Folder' ? document.title : document.type === 'WorkInstruction' ? document.workInstructionName : document.procedureName,
                description: ['LibraryProcedure', 'Procedure'].includes(document.type) ? document.workInstructionName : document.workInstructionDescription ?? document.description ?? null,
                brand: document.type === 'WorkInstruction' ? document.brand : null,
                deletedDate: document.deletedOn,
                id: document._id,
                type: document.type,
                workInstructionImageURL: document.type === 'WorkInstruction' ? (imageObject ? imageObject[document.workInstructionId] : '') : '',
                companyId: companyId,
                restoreDocument: restoreDocument,
                deleteDocumentPermanentlyHandler: deleteDocumentPermanentlyHandler,
            });
        });
        setDocumentsTableData(arr);
    };

    useEffect(() => {
        if (overview === 'table_view') {
            getDocumentsTableData();
        }
    }, [overview, currPage, documents]);

    const deleteDocumentPermanentlyHandler = (id, type) => {
        setSelectedDocument({ id: id, type: type });
        setShowDeleteDocumentDialog(true);
    };
    const restoreDocument = async (id, type) => {
        setRestoreLoading(true);
        switch (type) {
            case 'Folder':
                await TutorialService.restoreBinFolder(id)
                    .then((response) => {
                        Toast.success(t('WI_LIST.FOLDER'), t('COMMON.ITEM_RESTORED_SUCCESSFULLY'));
                        handleReloadData();
                    })
                    .catch(({ response }) => {
                        Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
                    })
                    .finally(() => setRestoreLoading(false));
                break;
            case 'WorkInstruction':
                await TutorialService.restoreBinProcedures(id)
                    .then((response) => {
                        Toast.success(t('WI_LIST.WORK_INSTRUCTION'), t('COMMON.ITEM_RESTORED_SUCCESSFULLY'));
                        handleReloadData();
                    })
                    .catch(({ response }) => {
                        Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
                    })
                    .finally(() => setRestoreLoading(false));
                break;
            case 'Procedure':
                await TutorialService.restoreBinProcedure(id)
                    .then((response) => {
                        Toast.success(t('WI_LIST.PROCEDURE'), t('COMMON.ITEM_RESTORED_SUCCESSFULLY'));
                        handleReloadData();
                    })
                    .catch(({ response }) => {
                        Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
                    })
                    .finally(() => setRestoreLoading(false));
                break;
            case 'LibraryProcedure':
                await TutorialService.restoreBinProcedure(id)
                    .then((response) => {
                        Toast.success(t('ADD_WI.PROCEDURE_LIBRARY'), t('COMMON.ITEM_RESTORED_SUCCESSFULLY'));
                        handleReloadData();
                    })
                    .catch(({ response }) => {
                        Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
                    })
                    .finally(() => setRestoreLoading(false));
                break;
            default:
                break;
        }
    };
    const closeDeleteDocumentDialogHandler = () => {
        setShowDeleteDocumentDialog(false);
    };

    return (
        <>
            {showDeleteDocumentDialog && (
                <DeleteDocumentPermanentlyDialog
                    openDialog={showDeleteDocumentDialog}
                    closeDialog={closeDeleteDocumentDialogHandler}
                    selectedDocument={selectedDocument}
                    handleReloadData={handleReloadData}
                />
            )}
            {(loading || restoreLoading) && <Loading />}
            {(!loading || restoreLoading) && (
                <div>
                    <RecycleBinTopBar
                        setAppliedFilterList={setAppliedFilterList}
                        sort={sort}
                        setSort={setSort}
                        isTrainer={props.isTrainer}
                        overview={overview}
                        setOverview={setOverview}
                        loadDeleteData={loadDeleteData}
                        totalDocumentsLength={totalDocumentsLength}
                        setCurrPage={setCurrPage}
                        setSearchVal={setSearchVal}
                        searchVal={searchVal}
                        filterVal={filterVal}
                        applyFilters={applyFilters}
                        setFilterVal={setFilterVal}
                        setSortVal={setSortVal}
                        sortVal={sortVal}
                    />
                    {appliedFilterList.length > 0 ? (
                        <AppliedFilterBar
                            appliedFilterList={appliedFilterList}
                            applyFilters={applyFilters}
                            setAppliedFilterList={setAppliedFilterList}
                            isTrainer={props.isTrainer}
                        />
                    ) : null}
                    <InfiniteScroll
                        dataLength={documents?.length || 10}
                        next={() => {
                            loadDeleteData(searchVal, sortVal, filterVal, currPage);
                        }}
                        hasMore={(documents?.length || 0) <= totalDocumentsLength - 1}
                        loader={(documents?.length || 0) <= totalDocumentsLength - 1 ? <p>{t('COMMON.LOADING')}</p> : null}
                    >
                        {overview === 'table_view' ? (
                            <Paper
                                elevation={0}
                                className="instructions-title-container"
                                sx={{ display: 'flex' }}
                            >
                                <Box
                                    component="div"
                                    sx={{
                                        width: '100%',
                                        ' table tbody :not(tr:hover) .show-on-hover': {
                                            display: 'none',
                                        },
                                        ' table tbody tr:hover .show-on-hover': {
                                            display: 'inline-flex',
                                        },
                                        '> table': {
                                            ' th:nth-child(1)': { width: '25%' },
                                            ' th:nth-child(2)': { width: '25%' },
                                            ' th:nth-child(3)': { width: '15%' },
                                            ' th:nth-child(4)': { width: '15%' },
                                            ' th:nth-child(5)': { width: '20%' },
                                        },
                                    }}
                                >
                                    <TableContainer
                                        columns={documentsColumn}
                                        data={documentsTableData}
                                    />
                                </Box>
                            </Paper>
                        ) : (
                            <Grid
                                className="card-container"
                                container
                                spacing={{ xs: 3, md: 4 }}
                                direction={'row'}
                                alignItems={'stretch'}
                                sx={{ marginBottom: { xs: '80px', md: '0px' } }}
                            >
                                {documents &&
                                    documents.map((ws, index) => {
                                        return (
                                            <Grid
                                                item
                                                xs={12}
                                                md={overview === 'extended_view' ? 2.4 : 4}
                                                lg={overview === 'extended_view' ? 2.4 : 4}
                                                key={index}
                                            >
                                                {ws.type === 'WorkInstruction' ? (
                                                    <WorkInstructionCards
                                                        companyId={companyId}
                                                        workInstruction={ws}
                                                        isTrainer={props.isTrainer}
                                                        overview={overview}
                                                        workInstructionImageURL={imageObject ? imageObject[ws.workInstructionId] : ''}
                                                        showRecycleBinHome={true}
                                                        hideRecycleBinHome={true}
                                                        recycleBinCardHeight="60%"
                                                        isDelete={props.isDelete}
                                                        isDeletedWorkInstructionBackButton={isDeletedWorkInstructionBackButton}
                                                        restoreDocument={(event) => {
                                                            restoreDocument(ws._id, ws.type);
                                                        }}
                                                        deletePermanentlyHandler={() => deleteDocumentPermanentlyHandler(ws._id, ws.type)}
                                                    />
                                                ) : ws.type === 'Folder' ? (
                                                    <FolderCards
                                                        companyId={companyId}
                                                        folder={ws}
                                                        showRecycleBinHome={true}
                                                        isTrainer={props.isTrainer}
                                                        index={ws.folderId}
                                                        overview={overview}
                                                        handleReloadData={handleReloadData}
                                                        restoreDocument={(event) => {
                                                            restoreDocument(ws._id, ws.type);
                                                        }}
                                                        deletePermanentlyHandler={() => deleteDocumentPermanentlyHandler(ws._id, ws.type)}
                                                    />
                                                ) : ws.type === 'Procedure' && ws.visibilityScope === 'NORMAL' ? (
                                                    <ProcedureCards
                                                        procedure={ws}
                                                        showRecycleBinHome={true}
                                                        isTrainer={props.isTrainer}
                                                        index={ws.folderId}
                                                        overview={overview}
                                                        handleReloadData={handleReloadData}
                                                        restoreDocument={(event) => {
                                                            restoreDocument(ws._id, ws.type);
                                                        }}
                                                        deletePermanentlyHandler={() => deleteDocumentPermanentlyHandler(ws._id, ws.type)}
                                                    />
                                                ) : (
                                                    <LibraryProcedureCards
                                                        procedure={ws}
                                                        isTrainer={props.isTrainer}
                                                        showRecycleBinHome={true}
                                                        index={ws.folderId}
                                                        overview={overview}
                                                        handleReloadData={handleReloadData}
                                                        restoreDocument={(event) => {
                                                            restoreDocument(ws._id, ws.type);
                                                        }}
                                                        deletePermanentlyHandler={() => deleteDocumentPermanentlyHandler(ws._id, ws.type)}
                                                    />
                                                )}
                                            </Grid>
                                        );
                                    })}
                                {documents.length === 0 && (
                                    <RecycleBinEmptyState
                                        filterVal={filterVal}
                                        searchVal={searchVal}
                                    />
                                )}
                            </Grid>
                        )}
                    </InfiniteScroll>
                </div>
            )}
        </>
    );
};

export default RecycleBinHome;
