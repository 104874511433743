import { Box, Button, IconButton, Paper, Popper, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { AssemblyState } from '../../../../../entities/AssemblyState';
import { CardQuestion } from '../../../../../entities/Trainee/WorkInstructions';
import { LeftArrow, RightArrow, Tools } from '../../../../Icons/CdsIcons';
import PartsPopOver from '../../PopOverContent/Parts/PartsPopOver';
import StepsPopOver from '../../PopOverContent/Steps/StepsPopOver';
import ToolsPopover from '../../PopOverContent/Tools/ToolsPopover';
import { MenuBook } from '@mui/icons-material';
import { useCanvasState } from '../../../../../state/canvasState';
import { StyledIconButton } from '../../../../Styled/StyledIconButton';
import './StepInstructions.scss';
import usePpeList from '../../../../../hooks/ppeList';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

interface Props {
    nextStep: () => void;
    prevStep: () => void;
    selectedStep: (step: number) => void;
    assemblyState: AssemblyState;
    isTrainer?: boolean;
    updateQuestion: (question: CardQuestion) => void;
    isViewComment?: boolean;
    confirmRequiredQuestionsHaveBeenAnswered: any;
}

const StepInstructions: React.FC<Props> = (props) => {
    const canvasState = useCanvasState();

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [currentPopover, setCurrentPopover] = useState<string | null>(null);
    const [lastPopover, setLastPopover] = useState('steps');
    const [showSteps, setShowSteps] = React.useState(false);
    const [showParts, setShowParts] = React.useState(false);
    const [stepsAnchorEl, setStepsAnchorEl] = React.useState(null);
    const [isPartsOpen, setIsPartsOpen] = useState(false);
    const [isToolsOpen, setIsToolsOpen] = useState(false);
    const stepsRef = useRef<any>();
    const canBeOpen = showSteps && Boolean(stepsAnchorEl);
    const id = canBeOpen ? 'spring-popper' : undefined;
    const open = Boolean(anchorEl);
    const theme = useTheme();
    const isMobileView = useMediaQuery(theme.breakpoints.down('md'));
    const { showIntro, setShowIntro, introStep, setIntroStep } = usePpeList();

    const { search: searchQuery } = useLocation();
    const query = new URLSearchParams(searchQuery);
    const wiType = query.get('workInstructionType');

    useEffect(() => {
        if (showIntro === false) {
            setStepsAnchorEl(stepsRef.current);
            setShowSteps(true);
            canvasState.setShowStepsVisible(true);
        } else if (showIntro === true) {
            setStepsAnchorEl(null);
            setShowSteps(false);
            canvasState.setShowStepsVisible(false);
        }
    }, [stepsRef, showIntro, props.assemblyState.currentStep]);

    const handleStepsClickAction = () => {
        setStepsAnchorEl(stepsRef.current);
        let nextOpen = (previousOpen) => !previousOpen;
        setShowSteps(nextOpen);
        canvasState.setShowStepsVisible(!showSteps);
        setShowParts(false);
    };

    const handleStepsClick = () => {
        if (isMobileView) {
            // Retain the last popover (Step / Parts / Tools)
            if (lastPopover === 'parts') {
                if (isPartsOpen) {
                    // Close Parts
                    // handlePartsClosed();
                    setCurrentPopover(null);
                    // props.partSelected(null);
                    setPopoverOpenStatus(false, false);
                } else {
                    // Open Parts
                    handleClick(null, 'parts');
                }
            } else {
                handleStepsClickAction();
            }
        } else {
            handleStepsClickAction();
        }
    };

    const handleClick = (event: React.MouseEvent<HTMLButtonElement> | null, popover: string) => {
        setAnchorEl(stepsRef.current);
        setShowSteps(false);
        canvasState.setShowStepsVisible(false);
        setShowParts(true);
        setCurrentPopover(popover);
        setPopoverOpenStatus(popover === 'parts', popover === 'tools');
        if (isMobileView) {
            setLastPopover(popover === null ? 'steps' : popover);
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
        setPopoverOpenStatus(false, false);
    };

    const handlePartsClosed = () => {
        setCurrentPopover(null);
        setPopoverOpenStatus(false, false);
    };

    const setPopoverOpenStatus = (partsStatus: boolean, toolsStatus: boolean) => {
        setIsPartsOpen(partsStatus);
        setIsToolsOpen(toolsStatus);
    };

    useEffect(() => {
        setShowIntro(true);
    }, []);

    const { t } = useTranslation();

    return (
        <>
            <Box
                component="div"
                className="mobile-canvas-control-container"
            >
                <Paper
                    variant="outlined"
                    className="canvas-control-container canvas-control-container-buttons"
                >
                    <StyledIconButton
                        tooltip={t('3D_VIEWER.PREVIOUS_STEP')}
                        onClick={props.prevStep}
                        disabled={showIntro ? introStep === 1 : canvasState.currentlyPlaying}
                    >
                        <LeftArrow />
                    </StyledIconButton>
                    <StyledIconButton
                        tooltip={t('3D_VIEWER.NEXT_STEP')}
                        onClick={props.nextStep}
                        disabled={!showIntro && (props.assemblyState.currentStep >= props.assemblyState.totalSteps || canvasState.currentlyPlaying)}
                        style={{ marginRight: '5px' }}
                    >
                        <RightArrow />
                    </StyledIconButton>
                    <div
                        ref={stepsRef}
                        aria-describedby={id}
                    >
                        <IconButton
                            aria-label="delete"
                            size="small"
                            sx={{ display: { xs: 'none', md: 'none', lg: 'none', xl: 'none' } }}
                            onClick={(e) => {
                                if (!showIntro) handleStepsClick();
                            }}
                        >
                            <MenuBook
                                color="primary"
                                sx={{ height: 32, width: 32 }}
                            />
                        </IconButton>
                        <Button
                            variant={showSteps || (isMobileView && currentPopover === 'parts') ? 'contained' : 'outlined'}
                            sx={{ display: { xs: 'block', md: 'block', lg: 'block' }, minHeight: '32px', maxHeight: '32px', lineHeight: 1, width: '130px' }}
                            className="stepActionButton"
                            onClick={(e) => {
                                if (!showIntro) handleStepsClick();
                            }}
                        >
                            {showIntro ? (
                                <>{introStep === 1 ? t('3D_VIEWER.WELCOME') : t('3D_VIEWER.SAFETY')}</>
                            ) : (
                                <>
                                    {t('3D_VIEWER.STEP')} {props.assemblyState.currentStep} {t('3D_VIEWER.OF')} {props.assemblyState.totalSteps}
                                </>
                            )}
                        </Button>
                    </div>

                    {canvasState.workInstructions && (
                        <StyledIconButton
                            sx={{ display: { xs: 'none', md: 'flex' } }}
                            selected={isToolsOpen}
                            canFlip={true}
                            tooltip={t('3D_VIEWER.VIEW_TOOLS')}
                            onClick={(e) => handleClick(e, 'tools')}
                            disabled={canvasState.currentlyPlaying || !canvasState.workInstructions.tools}
                            style={{ marginRight: '5px' }}
                        >
                            <Tools />
                        </StyledIconButton>
                    )}
                </Paper>
            </Box>
            {canvasState.workInstructions && (
                <Popper
                    placement={window.innerWidth > 899 ? 'top' : 'top-end'}
                    disablePortal={true}
                    anchorEl={stepsAnchorEl}
                    sx={{ zIndex: '1' }}
                    modifiers={[
                        { name: 'flip', enabled: false, options: { altBoundary: true, rootBoundary: 'document', padding: 8 } },
                        { name: 'preventOverflow', enabled: true, options: { altAxis: true, altBoundary: true, tether: true, rootBoundary: 'document', padding: 8 } },
                    ]}
                    open={showSteps}
                >
                    <StepsPopOver
                        updateQuestion={(question) => props.updateQuestion(question)}
                        isTrainer={props.isTrainer}
                        playing={canvasState.currentlyPlaying}
                        selectedStep={(step) => props.selectedStep(step)}
                        assemblyState={props.assemblyState}
                        close={() => handleStepsClick()}
                        handlePartsClick={handleClick}
                        cardsData={canvasState.workInstructions.cardsData}
                        totalStep={canvasState.workInstructions.cardsData.length}
                        isViewComment={props.isViewComment}
                        confirmRequiredQuestionsHaveBeenAnswered={props.confirmRequiredQuestionsHaveBeenAnswered}
                    />
                </Popper>
            )}

            {currentPopover && canvasState.workInstructions && (
                <Popper
                    placement={window.innerWidth > 899 ? 'top' : 'top-end'}
                    disablePortal={true}
                    anchorEl={stepsAnchorEl}
                    sx={{ zIndex: '1' }}
                    modifiers={[
                        { name: 'flip', enabled: false, options: { altBoundary: true, rootBoundary: 'document', padding: 8 } },
                        { name: 'preventOverflow', enabled: true, options: { altAxis: true, altBoundary: true, tether: true, rootBoundary: 'document', padding: 8 } },
                    ]}
                    open={showParts}
                >
                    {!showIntro && currentPopover === 'parts' && (
                        <PartsPopOver
                            handlePartsClick={() => {
                                setShowParts(false);
                                setShowSteps(true);
                                canvasState.setShowStepsVisible(true);
                                handlePartsClosed();
                                setLastPopover('steps');
                            }}
                            close={() => setShowParts(false)}
                        />
                    )}
                    {currentPopover === 'tools' && canvasState.workInstructions.tools && <ToolsPopover tools={canvasState.workInstructions.tools} />}
                </Popper>
            )}
        </>
    );
};

export default StepInstructions;
