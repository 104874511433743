import { PROCEDURE_VISIBILITY_SCOPE, USER_TYPES } from "../../shared/enums";
import { Filterable, Pageable, Searchable, Sortable } from "../../shared/interfaces";

export enum VISIT_HISTORY_DIALOGUE {
    EXPORT_VISIT_HISTORY,
    EXPANDED_VIEW,
}

export enum VISIT_TYPE {
    PROCEDURE_VISIT="Procedure Visit",
    USER_VISIT = "User Visit"
}
export interface IGetVisitOverviewResponse {
    totalProcedures: number;
    visitedProcedures: number;
    totalUsers: number;
    activeUsers: number;
}

export interface IGetVisitOverviewRequest {
    startTime: number;
    endTime: number;
}

export interface IGetVisitHistoryExportRequest {
    startTime: number;
    endTime: number;
}

export interface IGetVisitHistoryExportResponse {
    procedure: string;
    workInstruction: string;
    userName: string;
    userType: string;
    numberOfVisits: number;
    email?: string
}

export interface IGetProcedureVisitHistorySearchPayload extends Pageable, Searchable<'procedureName', string>, Sortable<'numberOfVisits' | 'procedureName'>, Filterable<'procedureStatus', string[]>, Filterable<'userId', string[]> {
    procedureName?: string;
    startTime?: number;
    endTime?: number;
}

export interface IGetUserVisitHistorySearchPayload extends Pageable, Searchable<'userName', string>, Sortable<'numberOfVisits' | 'email'>, Filterable<'userPersona', string[]>, Filterable<'procedureId', string[]> {
    userName?: string;
    startTime?: number;
    endTime?: number;
}

export interface IGetProcedureVisitHistoryResponse {
    procedureId: string;
    procedureName: string;
    workInstructionName: string;
    workInstructionId: string;
    numberOfVisits: number;
    visibilityScope: PROCEDURE_VISIBILITY_SCOPE;
}


export interface IGetUserVisitHistoryResponse {
    userId: string,
    firstName: string,
    lastName: string,
    email: string,
    numberOfVisits: number,
    persona: USER_TYPES
}
