import { Box, Button, SxProps, Theme, Typography, useTheme } from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useTranslation } from 'react-i18next';
import { TRANSLATE_TRAINEE_FILTERS } from '../../shared/enums';

// () => removeFilter(item.filter, item.value)
type FilterItem = { filter: string; value: string; applied: boolean };
type AppliedFilterListProps = {
    appliedFilterList: FilterItem[];
    onRemoveFilterClick: (filterItem: FilterItem) => void;
    isTrainer: boolean;
    sx?: SxProps<Theme>;
};
const AppliedFilterList = (props: AppliedFilterListProps) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const { appliedFilterList, onRemoveFilterClick, sx = {} } = props;
    if (!appliedFilterList.length) {
        return null;
    }
    return (
        <Box
            component="div"
            sx={{ display: { xs: 'none', md: 'flex' }, flexDirection: 'row', alignItems: 'flex-start', padding: '1rem 0rem 0rem 2rem', maxWidth: '100%', ...sx }}
        >
            {appliedFilterList?.map((item, i) => {
                if (item.applied) {
                    return (
                        <Box
                            key={i}
                            component="div"
                            border={'1px solid' + theme.palette.primary.main + '1c'}
                            sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row', borderRadius: '8px', maxWidth: '100%', padding: '0px 4px', margin: '0px 10px 0px 0px' }}
                        >
                            <Typography
                                className="filter-text"
                                key={item.value}
                            >
                                {!props.isTrainer && item.filter !== 'hasExpiredProcedures' && (t(TRANSLATE_TRAINEE_FILTERS[item.value?.toUpperCase()]) as string)}
                                {!props.isTrainer && item.filter === 'hasExpiredProcedures' && 'Expired'}

                                {props.isTrainer && item.value?.toUpperCase()}
                            </Typography>
                            <Button
                                sx={{ minWidth: 0 }}
                                onClick={() => onRemoveFilterClick(item)}
                            >
                                <HighlightOffIcon fontSize="small" />
                            </Button>
                        </Box>
                    );
                }
            })}
        </Box>
    );
};

export default AppliedFilterList;
