import { useTranslation } from 'react-i18next';
import { Box, Typography, useTheme } from '@mui/material';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';

export const AssignEmptyState = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    return (
        <Box
            component={'div'}
            sx={{
                display: 'block',
                width: 'inherit',
                marginTop: '24px'
            }}
        >
            <Box
                component={'div'}
                sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '4px',
                    
                }}
            >
                <AssignmentIndIcon sx={{ height: '90px', width: '100px', opacity: '70%', color: 'grey' }} />

                <Typography
                    variant="h5"
                    sx={{
                        color: '#1C1D1F99',
                    }}
                >
                    {t('PROCEDURE.NO_TRAINEES_ARE_AVAILABLE')}
                </Typography>
            </Box>
        </Box>
    );
};
