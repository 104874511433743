import { Box, Card, IconButton } from '@mui/material';
import { BasicDialog } from '../Trainer/Dialog/BasicDialog/BasicDialog';
import { useTranslation } from 'react-i18next';
import { VisitCountWindow } from './VisitCountWindow';
import { getProcedureVisitHistory, getUserVisitHistory } from '../../services/VisitHistory/VisitHistoryService';
import { VisitHistoryCard } from './VisitsHistory';
import { ExpandIcon } from '../Icons/CdsIcons';

export const VisitHistoryDialog = ({ isOpen, onClose,dateRange }) => {
    const { t } = useTranslation();

    return (
        <BasicDialog
            // title={t('NAVBAR.VISITS_HISTORY')}
            onClose={onClose}
            onOpen={isOpen}
            fullWidth={true}
            fullScreen={true}
        >
            <Box sx={{ paddingTop: '24px', width: '100%', height: '100%', position: 'relative' }}>
                <IconButton
                    onClick={onClose}
                    sx={{ position: 'absolute', top: -22, right: -45, zIndex: 1000, padding: 0 }}
                >
                    {' '}
                    <ExpandIcon />
                </IconButton>
                <VisitHistoryCard height={'100%'} dateRange={dateRange} />
            </Box>
        </BasicDialog>
    );
};
