import { FormControl, InputAdornment, TextField } from '@mui/material';
import { Search } from '@mui/icons-material';
import React, { ChangeEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

interface SearchBarProps {
    onChangeHandler: ChangeEventHandler<HTMLInputElement>;
    value?: string;
    searchVal?: string;
    inputRef?:any;
}

const SearchBar: React.FC<SearchBarProps> = ({ onChangeHandler, value = '', searchVal,inputRef }) => {
    const { t } = useTranslation();

    return (
        <div className="search-btn">
            <FormControl fullWidth>
                <TextField
                   inputRef={inputRef} 
                    onChange={onChangeHandler}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search sx={{ color: 'primary.main', mr: 1, my: 0.5 }} />
                            </InputAdornment>
                        ),
                    }}
                    placeholder={t('COMMON.SEARCH')}
                    style={{ borderRadius: '8px !important' }}
                />
            </FormControl>
        </div>
    );
};

export default SearchBar;
