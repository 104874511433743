import React, { useEffect, useState } from 'react';
import { BasicDialog, DialogTitleProps } from '../../components/Trainer/Dialog/BasicDialog/BasicDialog';
import { Box, Button, CircularProgress, FormControl, InputBase, MenuItem, Select, Stack, styled, TextField, Typography, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Add, RemoveCircleOutline } from '@mui/icons-material';
import { SUPPORTED_LANGUAGES } from '../../shared/enums';
import { useAppSelector } from '../../hooks/redux';
import { AddSubstitutionPayload } from './Substitutions';
export const SUBSTITUTE_WORD_LIMIT = 100;

const SubstitutionToAddTypes = {
    language: 'language',
    substituteText: 'substituteText',
    sourceWord: 'sourceWord',
};
type SubstitutionToAdd = {
    language: string;
    substituteText: string;
    sourceWord: string;
};

interface AddSubstitutionModalProps {
    handleClose: () => void;
    isOpen: boolean;
    handleSaveClick: (payload: AddSubstitutionPayload[]) => void;
}

interface IDialoagHeader extends DialogTitleProps {
    handleAddLanguageClick: () => void;
    wordToSubstitute: string;
    handleWordToSubstituteChange: any;
    isAddLanguageDisabled: boolean;
    translateWord: string;
    handleTranslateWord: any;
    fromLangCode: string;
    handleFromLanguageList: (languageCode: string) => void;
}

const CustomInput = styled(InputBase)(({ theme }) => ({
    borderTop: '1px solid #D9D9D9',
    padding: '0 16px',
    '& .MuiInputBase-input': {
        paddingBottom: '14px',
        paddingTop: '14px',
        fontSize: '16px',
        fontWeight: '400',
        color: '#000000',
        opacity: 1,
    },
    '& svg': {
        visibility: 'hidden',
    },
    ':hover': {
        '& svg': {
            visibility: 'unset',
        },
    },
}));

const CustomSelect = styled(InputBase)(({ theme }) => ({
    border: '1px solid #D9D9D9',
    borderRadius: '5px',
    padding: '0 4px',
    '& .MuiInputBase-input': {
        paddingBottom: '2px',
        paddingTop: '2px',
        fontSize: '14px',
        fontWeight: '600',
        color: '#000000',
        opacity: 1,
    },
    '& svg': {
        visibility: 'visible',
    },
    ':hover': {
        '& svg': {
            visibility: 'unset',
        },
    },
}));

export const DialogueActionContainer = ({ handleClose, handleSave, isSaveDisabled }) => {
    const { t } = useTranslation();
    const [isActionButtonClicked, setIsActionButtonClicked] = useState<boolean>(false);
    const handleActionButtonClick = () => {
        setIsActionButtonClicked(true);
        handleSave();
    };
    return (
        <Box>
            <Button
                variant="outlined"
                onClick={handleClose}
                sx={{ marginRight: '12px' }}
            >
                {' '}
                {t('BUTTON.CANCEL')}
            </Button>
            <Button
                variant="contained"
                type="submit"
                onClick={handleActionButtonClick}
                disabled={isSaveDisabled || isActionButtonClicked}
                endIcon={isActionButtonClicked ? <CircularProgress size={20} /> : null}
            >
                {' '}
                {t('BUTTON.SAVE')}
            </Button>
        </Box>
    );
};

export const useEditSubstitution = () => {
    const [wordToSubstitute, setWordToSubstitute] = useState<string>('');
    const [substitutionsToAdd, setSubstitutionsToAdd] = useState<SubstitutionToAdd[]>([{ sourceWord: '', language: '', substituteText: '' }]);
    const [isSaveDisabled, setIsSaveDiabled] = useState(true);

    useEffect(() => {
        if (substitutionsToAdd.length === 0 || substitutionsToAdd.find((any) => any.language === '' || any.substituteText === '' || any.sourceWord === '')) {
            setIsSaveDiabled(true);
            return;
        }
        setIsSaveDiabled(false);
    }, [wordToSubstitute, substitutionsToAdd]);

    const handleAddLanguageClick = () => {
        setSubstitutionsToAdd([...substitutionsToAdd, { sourceWord: '', language: '', substituteText: '' }]);
    };

    return {
        wordToSubstitute,
        setWordToSubstitute,
        substitutionsToAdd,
        setSubstitutionsToAdd,
        handleAddLanguageClick,
        isSaveDisabled,
    };
};

const AddSubstitutionModal = (props: AddSubstitutionModalProps) => {
    const { t } = useTranslation();
    const { handleClose, handleSaveClick, isOpen } = props;
    const { companyTheme, currentUser } = useAppSelector((state) => state.app);
    const { wordToSubstitute, setWordToSubstitute, substitutionsToAdd, setSubstitutionsToAdd, handleAddLanguageClick, isSaveDisabled } = useEditSubstitution();
    const handleSave = () => {
        if (!companyTheme) return;

        const payload: AddSubstitutionPayload[] = [];
        substitutionsToAdd.forEach((data) => {
            payload.push({
                // byDefaultLanguage: companyTheme.language.id.toUpperCase(),
                language: data.language.toUpperCase(),
                // word: wordToSubstitute,
                word: data.sourceWord,
                substitute: data.substituteText,
                organizationId: currentUser.organizationId,
            });
        });

        handleSaveClick(payload);
    };

    const handleWordToSubstituteChange = (e) => {
        let value: string = e.target.value;
        value = value.substring(0, SUBSTITUTE_WORD_LIMIT);
        setWordToSubstitute(value);
    };
    // console.log(substitutionsToAdd.length);
    return (
        <BasicDialog
            title={t('SUBSTITUTION.HEADING_TITLE')}
            onClose={handleClose}
            onOpen={isOpen}
            id="add-substitution"
            fullWidth={true}
            maxWidth={'sm'}
            dialogWidth={'800px'}
            components={{}}
            actions={
                <Box
                    component={'div'}
                    sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}
                >
                    {
                        <Button
                            startIcon={<Add />}
                            disableRipple
                            sx={{
                                minWidth: 'fit-content',
                                padding: '0px',
                                fontSize: '16px',
                                marginTop: '0px',
                                // visibility: substitutionsToAdd.length >= 5 ? 'visible' : 'hidden',
                                '& .MuiButton-startIcon': { marginRight: 0 },
                            }}
                            onClick={handleAddLanguageClick}
                            // disabled={Object.keys(SUPPORTED_LANGUAGES).length === substitutionsToAdd.length}
                        >
                            {t('SUBSTITUTION.ADD_MODAL.ADD_LANGUAGE')}
                        </Button>
                    }
                    <DialogueActionContainer
                        handleClose={handleClose}
                        handleSave={handleSave}
                        isSaveDisabled={isSaveDisabled}
                    />
                </Box>
            }
        >
            <EditSubstitution
                substitutionsToAdd={substitutionsToAdd}
                setSubstitutionsToAdd={setSubstitutionsToAdd}
                isSideMenu={false}
            />
            {/* <Button
                startIcon={<Add sx={{ position: 'sticky' }} />}
                disableRipple
                sx={{
                    // position: 'sticky',
                    // top: '0px',
                    // bottom: '0px',
                    // zIndex: 2,
                    minWidth: 'fit-content',
                    padding: '0px',
                    fontSize: '16px',
                    marginTop: '0px',
                    '& .MuiButton-startIcon': { marginRight: 0 },
                    visibility: substitutionsToAdd.length < 5 ? 'visible' : 'hidden',
                }}
                onClick={handleAddLanguageClick}
                // disabled={Object.keys(SUPPORTED_LANGUAGES).length === substitutionsToAdd.length}
            >
                {t('SUBSTITUTION.ADD_MODAL.ADD_LANGUAGE')}
            </Button> */}
        </BasicDialog>
    );
};

export default AddSubstitutionModal;

export const EditSubstitution = ({ substitutionsToAdd, setSubstitutionsToAdd, isSideMenu }) => {
    const { t } = useTranslation();
    const handleChange = (key, value, index) => {
        let data: SubstitutionToAdd[] = JSON.parse(JSON.stringify(substitutionsToAdd));
        data[index] = {
            ...data[index],
            [key]: value.substring(0, SUBSTITUTE_WORD_LIMIT),
        };
        setSubstitutionsToAdd(data);
    };

    const handleRemoveIconClick = (index) => {
        let data: SubstitutionToAdd[] = JSON.parse(JSON.stringify(substitutionsToAdd));
        data.splice(index, 1);
        setSubstitutionsToAdd(data);
    };

    return (
        <Stack
            direction={'column'}
            spacing={'12px'}
        >
            {substitutionsToAdd.map(({ sourceWord, language, substituteText }, index) => (
                <EditSubstitutionRow
                    key={index}
                    sourceWord={sourceWord}
                    language={language}
                    substituteText={substituteText}
                    handleChange={handleChange}
                    handleRemoveIconClick={handleRemoveIconClick}
                    index={index}
                    substitutionsToAdd={substitutionsToAdd}
                    isSideMenu={isSideMenu}
                />
            ))}
        </Stack>
    );
};
const EditSubstitutionRow = ({ sourceWord, language, substituteText, handleChange, handleRemoveIconClick, index, substitutionsToAdd, isSideMenu }) => {
    const { t } = useTranslation();
    return (
        <Box
            component={'div'}
            sx={{ display: 'flex', gap: '12px' }}
        >
            <Box
                component={'div'}
                sx={{ width: '100%', border: '1px solid #e9e9ea', borderRadius: '8px', minWidth: '150px' }}
            >
                <Box
                    component={'div'}
                    sx={{ height: '50px', display: 'flex', gap: '10px', alignItems: 'center', background: '#f9f9fb', padding: '16px' }}
                >
                    <Typography
                        variant="h6"
                        sx={{ fontSize: '14px', opacity: 0.4, background: '#f9f9fb' }}
                    >
                        {t('SUBSTITUTION.FOR')}
                    </Typography>
                    <FormControl>
                        <Select
                            value={language}
                            label="Select Language"
                            displayEmpty
                            onChange={(e) => handleChange(SubstitutionToAddTypes.language, e.target.value, index)}
                            input={<CustomSelect />}
                            sx={{
                                width: isSideMenu ? 'fit-content' : '150px',
                                background: '#FFFFFF',
                                '& .MuiInputBase-input': {
                                    opacity: '1',
                                },
                            }}
                        >
                            <MenuItem
                                disabled
                                value=""
                                sx={{ display: 'none' }}
                            >
                                {' '}
                                {language ? language : t('SUBSTITUTION.SELECT_LANGUAGE')}{' '}
                            </MenuItem>
                            {Object.keys(SUPPORTED_LANGUAGES).map((key) => {
                                // if (substitutionsToAdd.find((any) => any?.language.toUpperCase() === SUPPORTED_LANGUAGES[key].toUpperCase()) && language !== SUPPORTED_LANGUAGES[key]) return null;

                                return <MenuItem value={SUPPORTED_LANGUAGES[key]}>{t('LANGUAGE_SIDEBAR.SUPPORTED_LANGUAGES.' + SUPPORTED_LANGUAGES[key].toUpperCase())}</MenuItem>;
                            })}
                        </Select>
                    </FormControl>
                </Box>
                <FormControl sx={{ width: '100%', paddingBlock: '.2rem', paddingInline: '1rem' }}>
                    <TextField
                        variant="outlined"
                        sx={{
                            width: '100%',
                            '& .MuiOutlinedInput-root': {
                                padding: 0,
                                width: '100%',
                                '& fieldset': {
                                    border: 'none',
                                },
                            },
                            '& .MuiInputBase-input': {
                                fontSize: '14px',
                                width: '100%',
                                paddingInline: 0,
                            },
                        }}
                        value={sourceWord}
                        onChange={(e) => handleChange(SubstitutionToAddTypes.sourceWord, e.target.value, index)}
                        placeholder={t('SUBSTITUTION.ADD_MODAL.BEFORE')}
                    />
                </FormControl>
            </Box>
            <Box
                component={'div'}
                sx={{ width: '100%', border: '1px solid #e9e9ea', borderRadius: '8px', minWidth: '150px' }}
            >
                <Box
                    component={'div'}
                    sx={{ height: '50px', display: 'flex', gap: '1rem', alignItems: 'center', background: '#f9f9fb', padding: '1rem' }}
                >
                    <Typography
                        variant="h6"
                        sx={{ fontSize: '14px', opacity: 0.4, background: '#f9f9fb' }}
                    >
                        {t('SUBSTITUTION.REPLACE_WITH')}
                    </Typography>
                </Box>
                <FormControl sx={{ width: '100%', paddingBlock: '.2rem', paddingInline: '1rem' }}>
                    <TextField
                        variant="outlined"
                        sx={{
                            width: '100%',
                            '& .MuiOutlinedInput-root': {
                                padding: 0,
                                width: '100%',
                                '& fieldset': {
                                    border: 'none',
                                },
                            },
                            '& .MuiInputBase-input': {
                                fontSize: '14px',
                                width: '100%',
                                paddingInline: 0,
                            },
                        }}
                        value={substituteText}
                        onChange={(e) => handleChange(SubstitutionToAddTypes.substituteText, e.target.value, index)}
                        placeholder={t('SUBSTITUTION.ADD_MODAL.AFTER')}
                    />
                </FormControl>
            </Box>
            {!isSideMenu && <IconButton
                disableRipple
                sx={{
                    padding: 0,
                    margin: 0,
                    '& .MuiSvgIcon-root': {
                        marginRight: '0px',
                    },
                }}
                onClick={() => handleRemoveIconClick(index)}
                edge="end"
            >
                <RemoveCircleOutline sx={{ opacity: 0.5 }} />
            </IconButton>}
        </Box>
    );
};
