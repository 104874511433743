import { IGetProcedureVisitHistoryResponse, IGetProcedureVisitHistorySearchPayload, IGetUserVisitHistoryResponse, IGetUserVisitHistorySearchPayload, IGetVisitHistoryExportRequest, IGetVisitOverviewRequest, IGetVisitOverviewResponse } from '../../components/VisitsHistory/constants';
import { Paginated } from '../../shared/interfaces';
import { newAxiosInstance as http } from "../http-common"
import { DataResponse } from '../service-models';


export const getVisitHistoryOverview = (payload?: IGetVisitOverviewRequest, signal?: any) => {
    let url = '/analytics/organization/getVisitCount';
    return http.get<DataResponse<IGetVisitOverviewResponse>>(url, {
        params: payload, signal
    }).then((response) => response.data).catch((error) => {
        return null;
    });

};
export const getProcedureVisitHistory = (payload?: IGetProcedureVisitHistorySearchPayload, signal?: any): Promise<Paginated<IGetProcedureVisitHistoryResponse[]>> => {
    let url = '/analytics/procedure/getListOfProcedure';

    return http.get<DataResponse<Paginated<IGetProcedureVisitHistoryResponse[]>>>(url, { params: payload, signal })
        .then((response) => response.data.data)
};

export const getUserVisitHistory = (payload?: IGetUserVisitHistorySearchPayload, signal?: any): Promise<Paginated<IGetUserVisitHistoryResponse[]>> => {
    let url = '/analytics/procedure/getListOfUsers';
    return http.get<DataResponse<Paginated<IGetUserVisitHistoryResponse[]>>>(url, {
        params: payload,
        signal: signal
    }).then((response) => response.data.data)
};

export const requestVisitHistoryDataExport = (payload?: IGetVisitHistoryExportRequest) => {
    let url = '/visitHistory/export';
    // return http.get<IGetVisitHistoryExportResponse>(url, {
    //     params: payload,
    //      signal: signal
    // });

    const dummyData = [
        {
            procedure: 'A quick brown fox jumps over the lazy dog A quick brown fox jumps over the lazy dog',
            workInstruction: 'A quick brown fox jumps over the lazy dog A quick brown fox jumps over the lazy dog',
            userName: 'John Doe',
            userType: 'Trainee',
            numberOfVisits: 3,
        },
        {
            procedure: 'Blood Test',
            workInstruction: 'Complete blood count test',
            userName: 'Jane Smith',
            userType: 'Trainee',
            numberOfVisits: 5,
        },
        {
            procedure: 'X-ray',
            workInstruction: 'Chest X-ray',
            userName: 'Alice Johnson',
            userType: 'Trainee',
            numberOfVisits: 2,
        },
        {
            procedure: 'Consultation',
            workInstruction: 'Consultation with specialist',
            userName: 'Michael Brown',
            userType: 'Trainer',
            numberOfVisits: 1,
        },
        {
            procedure: 'Surgery',
            workInstruction: 'Knee surgery',
            userName: 'Sarah Connor',
            userType: 'Trainee',
            numberOfVisits: 1,
        },
        {
            procedure: 'Check-up',
            workInstruction: 'Routine check-up for adults',
            userName: 'John Doe',
            userType: 'Trainee',
            numberOfVisits: 3,
        },
        {
            procedure: 'Blood Test',
            workInstruction: 'Complete blood count test',
            userName: 'Jane Smith',
            userType: 'Trainee',
            numberOfVisits: 5,
        },
        {
            procedure: 'X-ray',
            workInstruction: 'Chest X-ray',
            userName: 'Alice Johnson',
            userType: 'Trainee',
            numberOfVisits: 2,
        },
        {
            procedure: 'Consultation',
            workInstruction: 'Consultation with specialist',
            userName: 'Michael Brown',
            userType: 'Trainer',
            numberOfVisits: 1,
        },
        {
            procedure: 'Surgery',
            workInstruction: 'Knee surgery',
            userName: 'Sarah Connor',
            userType: 'Trainee',
            numberOfVisits: 1,
        },
        {
            procedure: 'Check-up',
            workInstruction: 'Routine check-up for adults',
            userName: 'John Doe',
            userType: 'Trainee',
            numberOfVisits: 3,
        },
        {
            procedure: 'Blood Test',
            workInstruction: 'Complete blood count test',
            userName: 'Jane Smith',
            userType: 'Trainee',
            numberOfVisits: 5,
        },
        {
            procedure: 'X-ray',
            workInstruction: 'Chest X-ray',
            userName: 'Alice Johnson',
            userType: 'Trainee',
            numberOfVisits: 2,
        },
        {
            procedure: 'Consultation',
            workInstruction: 'Consultation with specialist',
            userName: 'Michael Brown',
            userType: 'Trainer',
            numberOfVisits: 1,
        },
        {
            procedure: 'Surgery',
            workInstruction: 'Knee surgery',
            userName: 'Sarah Connor',
            userType: 'Trainee',
            numberOfVisits: 1,
        },
        {
            procedure: 'Check-up',
            workInstruction: 'Routine check-up for adults',
            userName: 'John Doe',
            userType: 'Trainee',
            numberOfVisits: 3,
        },
        {
            procedure: 'Blood Test',
            workInstruction: 'Complete blood count test',
            userName: 'Jane Smith',
            userType: 'Trainee',
            numberOfVisits: 5,
        },
        {
            procedure: 'X-ray',
            workInstruction: 'Chest X-ray',
            userName: 'Alice Johnson',
            userType: 'Trainee',
            numberOfVisits: 2,
        },
        {
            procedure: 'Consultation',
            workInstruction: 'Consultation with specialist',
            userName: 'Michael Brown',
            userType: 'Trainer',
            numberOfVisits: 1,
        },
        {
            procedure: 'Surgery',
            workInstruction: 'Knee surgery',
            userName: 'Sarah Connor',
            userType: 'Trainee',
            numberOfVisits: 1,
        },
        {
            procedure: 'Check-up',
            workInstruction: 'Routine check-up for adults',
            userName: 'John Doe',
            userType: 'Trainee',
            numberOfVisits: 3,
        },
        {
            procedure: 'Blood Test',
            workInstruction: 'Complete blood count test',
            userName: 'Jane Smith',
            userType: 'Trainee',
            numberOfVisits: 5,
        },
        {
            procedure: 'X-ray',
            workInstruction: 'Chest X-ray',
            userName: 'Alice Johnson',
            userType: 'Trainee',
            numberOfVisits: 2,
        },
        {
            procedure: 'Consultation',
            workInstruction: 'Consultation with specialist',
            userName: 'Michael Brown',
            userType: 'Trainer',
            numberOfVisits: 1,
        },
        {
            procedure: 'Surgery',
            workInstruction: 'Knee surgery',
            userName: 'Sarah Connor',
            userType: 'Trainee',
            numberOfVisits: 1,
        },
        {
            procedure: 'Check-up',
            workInstruction: 'Routine check-up for adults',
            userName: 'John Doe',
            userType: 'Trainee',
            numberOfVisits: 3,
        },
        {
            procedure: 'Blood Test',
            workInstruction: 'Complete blood count test',
            userName: 'Jane Smith',
            userType: 'Trainee',
            numberOfVisits: 5,
        },
        {
            procedure: 'X-ray',
            workInstruction: 'Chest X-ray',
            userName: 'Alice Johnson',
            userType: 'Trainee',
            numberOfVisits: 2,
        },
        {
            procedure: 'Consultation',
            workInstruction: 'Consultation with specialist',
            userName: 'Michael Brown',
            userType: 'Trainer',
            numberOfVisits: 1,
        },
        {
            procedure: 'Surgery',
            workInstruction: 'Knee surgery',
            userName: 'Sarah Connor',
            userType: 'Trainee',
            numberOfVisits: 1,
        },
        {
            procedure: 'Check-up',
            workInstruction: 'Routine check-up for adults',
            userName: 'John Doe',
            userType: 'Trainee',
            numberOfVisits: 3,
        },
        {
            procedure: 'Blood Test',
            workInstruction: 'Complete blood count test',
            userName: 'Jane Smith',
            userType: 'Trainee',
            numberOfVisits: 5,
        },
        {
            procedure: 'X-ray',
            workInstruction: 'Chest X-ray',
            userName: 'Alice Johnson',
            userType: 'Trainee',
            numberOfVisits: 2,
        },
        {
            procedure: 'Consultation',
            workInstruction: 'Consultation with specialist',
            userName: 'Michael Brown',
            userType: 'Trainer',
            numberOfVisits: 1,
        },
        {
            procedure: 'Surgery',
            workInstruction: 'Knee surgery',
            userName: 'Sarah Connor',
            userType: 'Trainee',
            numberOfVisits: 1,
        },
        {
            procedure: 'Check-up',
            workInstruction: 'Routine check-up for adults',
            userName: 'John Doe',
            userType: 'Trainee',
            numberOfVisits: 3,
        },
        {
            procedure: 'Blood Test',
            workInstruction: 'Complete blood count test',
            userName: 'Jane Smith',
            userType: 'Trainee',
            numberOfVisits: 5,
        },
        {
            procedure: 'X-ray',
            workInstruction: 'Chest X-ray',
            userName: 'Alice Johnson',
            userType: 'Trainee',
            numberOfVisits: 2,
        },
        {
            procedure: 'Consultation',
            workInstruction: 'Consultation with specialist',
            userName: 'Michael Brown',
            userType: 'Trainer',
            numberOfVisits: 1,
        },
        {
            procedure: 'Surgery',
            workInstruction: 'Knee surgery',
            userName: 'Sarah Connor',
            userType: 'Trainee',
            numberOfVisits: 1,
        },
        {
            procedure: 'Check-up',
            workInstruction: 'Routine check-up for adults',
            userName: 'John Doe',
            userType: 'Trainee',
            numberOfVisits: 3,
        },
        {
            procedure: 'Blood Test',
            workInstruction: 'Complete blood count test',
            userName: 'Jane Smith',
            userType: 'Trainee',
            numberOfVisits: 5,
        },
        {
            procedure: 'X-ray',
            workInstruction: 'Chest X-ray',
            userName: 'Alice Johnson',
            userType: 'Trainee',
            numberOfVisits: 2,
        },
        {
            procedure: 'Consultation',
            workInstruction: 'Consultation with specialist',
            userName: 'Michael Brown',
            userType: 'Trainer',
            numberOfVisits: 1,
        },
        {
            procedure: 'Surgery',
            workInstruction: 'Knee surgery',
            userName: 'Sarah Connor',
            userType: 'Trainee',
            numberOfVisits: 1,
        },
        {
            procedure: 'Check-up',
            workInstruction: 'Routine check-up for adults',
            userName: 'John Doe',
            userType: 'Trainee',
            numberOfVisits: 3,
        },
        {
            procedure: 'Blood Test',
            workInstruction: 'Complete blood count test',
            userName: 'Jane Smith',
            userType: 'Trainee',
            numberOfVisits: 5,
        },
        {
            procedure: 'X-ray',
            workInstruction: 'Chest X-ray',
            userName: 'Alice Johnson',
            userType: 'Trainee',
            numberOfVisits: 2,
        },
        {
            procedure: 'Consultation',
            workInstruction: 'Consultation with specialist',
            userName: 'Michael Brown',
            userType: 'Trainer',
            numberOfVisits: 1,
        },
        {
            procedure: 'Surgery',
            workInstruction: 'Knee surgery',
            userName: 'Sarah Connor',
            userType: 'Trainee',
            numberOfVisits: 1,
        },
    ];

    return Promise.resolve(dummyData);
}