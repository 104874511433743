import { Box, Grid, SxProps, Theme, Typography, useTheme } from '@mui/material';
import { PieChart, Pie, Label, Cell, ResponsiveContainer, Tooltip } from 'recharts';
import CircleIcon from '@mui/icons-material/Circle';
import { useTranslation } from 'react-i18next';

export interface ChartDataItem {
    name: string;
    value: number;
    color?: string;
    sx?: SxProps<Theme>;
}

interface LegendDataItem {
    circleColor?: string;
    label: string;
    value: number;
    percentage: string;
}
interface RadialBarGraphProps extends ChartProps, ChartLegend {
    total: number;
    value: number;
}

interface ChartProps {
    title?: string;
}

interface ChartLegend {
    legends?: LegendDataItem[];
}

const RadialBarGraph: React.FC<RadialBarGraphProps> = (props) => {
    const { total, value, title, legends } = props;
    const theme = useTheme();
    const { t } = useTranslation();

    const chartData: ChartDataItem[] = [
        {
            name: legends ? legends[0].label: 'Value',
            value: value,
            color: legends ? legends[0].circleColor || theme.palette.primary.main : undefined,
        },
        {
            name: 'Remaining',
            value: total - value,
            color: legends ? (legends[0].circleColor || theme.palette.primary.main) + '1b' : undefined,
        },
    ];
    
    return (
        <>
            <Grid container>
                <Grid
                    item
                    xs={6}
                >
                    <ResponsiveContainer width="100%">
                        <PieChart>
                            <Pie
                                isAnimationActive={true}
                                data={chartData}
                                dataKey="value"
                                nameKey="name"
                                innerRadius={65}
                                outerRadius={85}
                                stroke="white"
                                startAngle={90}
                                endAngle={-270}
                            >
                                {chartData?.map((entry, index) => (
                                    <Cell
                                        key={`cell-${index}`}
                                        fill={entry.color}
                                    />
                                ))}
                                <Label
                                    fontSize="20px"
                                    fill="#0A0A0A"
                                    position="center"
                                    style={{ fontWeight: 600 }}
                                    content={
                                        <text
                                            offset="5"
                                            font-size="20px"
                                            x="50%"
                                            y="100"
                                            className="recharts-text recharts-label"
                                            text-anchor="middle"
                                            fill="#0A0A0A"
                                            style={{ fontWeight: 600 }}
                                        >
                                            <tspan
                                                x="50%"
                                                dy="0em"
                                                style={{fontSize: "14px"}}
                                            >
                                               {t("COMMON.TOTAL")}
                                            </tspan>
                                            <tspan
                                                x="50%"
                                                dy="1em"
                                            >
                                                {total}
                                            </tspan>
                                        </text>
                                    }
                                />
                            </Pie>
                            <Tooltip
                                wrapperStyle={{ outline: 'none' }}
                                content={<CustomTooltip />}
                            />
                        </PieChart>
                    </ResponsiveContainer>
                </Grid>
                <Grid
                    item
                    xs={6}
                    sx={{ display: 'flex', paddingRight: '24px', minHeight: '200px' }}
                >
                    <Box
                        component="div"
                        sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, justifyContent: 'center' }}
                    >
                        {title ? <Typography sx={{ fontSize: '16px', display: 'flex', flexDirection: 'flex-start', mb: '24px' }}>{title}</Typography> : null}
                        {legends?.map((legend, index) => (
                            <Box
                                key={index}
                                component="div"
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    mb: '16px',
                                    gap: '10px',
                                }}
                            >
                                <CircleIcon sx={{ color: legend.circleColor ?? theme.palette.primary.main, fontSize: '16px', minWidth: 0 }} />
                                <Box
                                    component="span"
                                    sx={{ color: '#000', fontSize: '16px' }}
                                >
                                    {legend.label}
                                </Box>
                                <Box
                                    component="span"
                                    sx={{ color: '#000', fontSize: '16px', marginLeft: 'auto', fontWeight: 600 }}
                                >
                                    {legend.value}
                                </Box>
                                <Box
                                    component="span"
                                    sx={{ color: '#000', fontSize: '16px', opacity: 0.6 }}
                                >
                                    {legend.percentage}
                                </Box>
                            </Box>
                        ))}
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

export default RadialBarGraph;

interface CustomTooltipProps {
    active?: any;
    payload?: any;
    label?: any;
}

const CustomTooltip = ({ active, payload, label }: CustomTooltipProps) => {
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip">
                <p className="label">
                    {`${payload[0]?.payload?.name}: `}
                    {`${payload[0]?.payload?.value}`}
                </p>
            </div>
        );
    }

    return null;
};
