import React, { useRef, useState } from 'react';
import FileManagerService from '../services/FileManager/FileManagerService';
import { useTranslation } from 'react-i18next';
import TutorialService from '../services/trainee/TraineeService';

const useLoadDeleteEntities = (props: any = {}) => {
    const previousController = useRef<any>(null);
    const [totalDocumentsLength, setTotalDocumentsLength] = useState(0);
    const [currPage, setCurrPage] = useState(1);
    const [imageObject, setImageObject] = useState<Object>({});
    const [parentFolderId, setParentFolderId] = React.useState<String>('');
    const [loading, setLoading] = useState(false);
    const [sortVal, setSortVal] = useState<string>('');
    const [filterVal, setFilterVal] = useState<string>('');
    const [searchVal, setSearchVal] = useState<string>('');
    const [documents, setDocuments] = useState<any>([]);
    const [totalPage, setTotalPage] = useState<number>(0);

    const { t } = useTranslation();
    const loadDeleteData = (search = searchVal, sort = sortVal, filter: any = filterVal, page = currPage, pageLimit = 30) => {
        if (page === 1 && search === '') {
            setLoading(true);
        }
        if (totalPage !== 0 && totalPage + 1 === currPage && filter.length < 1) {
            setLoading(false);
            return;
        }
        if (filter) {
            filter?.forEach((e, index) => {
                if (t(e) === t(`ADD_WI.WORK_INSTRUCTION`)) {
                    filter[index] = `WorkInstruction`;
                }
                if (t(e) === t(`ADD_WI.PROCEDURE_LIBRARY`)) {
                    filter[index] = `LibraryProcedure`;
                }
                if (t(e) === t(`ADD_WI.FOLDER`)) {
                    filter[index] = `Folder`;
                }
                if (t(e) === t(`ADD_WI.PROCEDURE`)) {
                    filter[index] = `Procedure`;
                }
            });
        }
        TutorialService.getRecycleBinEntities(page, pageLimit, search, sort, filter)
            .then((e) => {
                setTotalPage(e.data.data.totalPages);
                setTotalDocumentsLength(e.data.data.totalDocuments);

                e.data.data.data.map((wi) => {
                    if (wi.type === 'WorkInstruction') {
                        if (wi?.is2d === false) {
                            FileManagerService.getWorkInstructionThumbnail(localStorage.getItem('organizationId') || '', wi.workInstructionId)
                                .then((res) => {
                                    imageObject[wi.workInstructionId] = (res as any).data[0].id;
                                    setImageObject({ ...imageObject });
                                })

                                .catch((e) => {
                                    imageObject[wi.workInstructionId] = '';
                                });
                        }

                        if (wi?.is2d === true) {
                            FileManagerService.getWorkInstructionThumbnail2d(localStorage.getItem('organizationId') || '', wi.workInstructionId)
                                .then((res) => {
                                    imageObject[wi.workInstructionId] = (res as any).data[0].id;
                                    setImageObject({ ...imageObject });
                                })
                                .catch((e) => {
                                    imageObject[wi.workInstructionId] = '';
                                });
                        }
                    }
                });

                if (documents && page > 1) {
                    setDocuments([...documents, ...(e.data.data.data as any)]);
                } else {
                    setDocuments(e.data.data.data as any);
                }
                setCurrPage(page + 1);
                setLoading(false);
            })
            .catch(({ response }) => {
                setLoading(false);
            });
    };

    return {
        loadDeleteData,
        documents,
        setDocuments,
        totalDocumentsLength,
        currPage,
        parentFolderId,
        loading,
        setLoading,
        setCurrPage,
        setParentFolderId,
        imageObject,
        setSearchVal,
        searchVal,
        filterVal,
        setFilterVal,
        setSortVal,
        sortVal,
    };
};

export default useLoadDeleteEntities;
