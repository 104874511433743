import { Box, Button, Divider, FormControl, MenuItem, Paper, Select, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../hooks/redux';
import { setVisitHistoryDialogue } from '../../redux/features/Analytics/Analytics.slice';
import { ExportExcelIcon } from '../Icons/CdsIcons';
import { VISIT_HISTORY_DIALOGUE } from './constants';
import DateRangeSelector from '../DateRangeSelector/DateRangeSelector';
import { SELECT_DATARANGE_OPTIONS } from '../../shared/enums';
import { requestVisitHistoryDataExport } from '../../services/VisitHistory/VisitHistoryService';
import { Toast } from '../Notifications/Notification';

const VisitHistoryPageHeader = ({ dateRange, setDateRange }) => {
    const [sort, setSort] = useState<SELECT_DATARANGE_OPTIONS>(SELECT_DATARANGE_OPTIONS.ALL);
    
    const { t } = useTranslation();
    const handleExportVisitHistoryClick = () => {
        requestVisitHistoryDataExport(dateRange).then((response) => {
            Toast.success("Exporting...", "We will send you an email once done");
        }).catch(() => {
            Toast.error("Request Submition Failed", "We were unable to process your request.");
        })
    };

    return (
        <Paper
            elevation={0}
            style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
        >
            <Box
                component="div"
            >
                <Typography
                    variant="h6"
                    component="div"
                    className="page-title"
                >
                    {t('NAVBAR.VISITS_HISTORY') as string}
                </Typography>
            </Box>

            <Box
                component="div"
                style={{display: 'flex'}}
            >
                <DateRangeSelector
                    dateRange={dateRange}
                    setDateRange={setDateRange}
                    selectedDateRangeOption={sort}
                    setSelectedDateRangeOption={setSort}
                    showAllTimeOption={true}
                    withBorder={true}
                    sx={{marginRight: "8px"}}
                />
                {/* <Divider
                    orientation="vertical"
                    variant="middle"
                    flexItem
                /> */}
                {/* <Button
                    variant="outlined"
                    sx={{ marginLeft: '8px' }}
                    onClick={handleExportVisitHistoryClick}
                >
                    <ExportExcelIcon /> {t('COMMON.EXPORT')}
                </Button> */}
            </Box>
        </Paper>
    );
};

export default VisitHistoryPageHeader;
