import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const RecycleBinActionButton = (props) => {
    const { t } = useTranslation();
    return (
        <>
            <Box
                component="div"
                sx={{  display: 'flex', marginBottom: '8px' }}
            >
                {props.showRecycleBinHome && props.isTrainer && (
                    <>
                        <Button
                            color="primary"
                            size="small"
                            variant="contained"
                            disableRipple
                            sx={{
                                margin: 0,
                                borderRadius: '8px 8px 8px 8px',
                                m: 0,
                                p: 0,
                                fontSize: '16px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                flexDirection: 'column',
                            }}
                            onClick={props.restoreDocument}
                        >
                            {t('BUTTON.RESTORE')}
                        </Button>

                        <Button
                            color="primary"
                            variant="outlined"
                            disableRipple
                            sx={{
                                margin: '0px 0px 0px 8px',
                                '&.MuiButtonBase-root:hover': { bgcolor: 'transparent' },
                                p: 0,
                                fontSize: '16px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}
                            onClick={(event) => {
                                event.stopPropagation();
                                props.deletePermanentlyHandler();
                            }}
                        >
                            {t('BUTTON.DELETE')}
                        </Button>
                    </>
                )}
            </Box>
        </>
    );
};
