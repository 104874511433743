import { Box, Card, IconButton } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { setVisitHistoryDialogue } from '../../redux/features/Analytics/Analytics.slice';
import { ExpandIcon } from '../Icons/CdsIcons';
// import { ExportVisitHistoryDialog } from './ExportVisitHistoryDialog';
import { VisitHistoryDialog } from './VisitHistoryDialog';
import VisitHistoryOverview from './VisitHistoryOverview';
import VisitHistoryPageHeader from './VisitHistoryPageHeader';
import { IGetProcedureVisitHistorySearchPayload, IGetUserVisitHistorySearchPayload, VISIT_HISTORY_DIALOGUE, VISIT_TYPE } from './constants';
import { VisitCountWindow } from './VisitCountWindow';
import { getProcedureVisitHistory, getUserVisitHistory } from '../../services/VisitHistory/VisitHistoryService';
import { PROCEDURE_STATUS, USER_TYPES, VISIT_PROCEDURE_STATUS } from '../../shared/enums';
import { IDateRange } from '../../entities/Analytics/AnalyticsProcedure';

interface Props {}
interface IChartData {
    name: string;
    value: number;
    color?: string;
}
interface LegendDataItem {
    circleColor?: string;
    label: string;
    value: number;
    percentage: string;
}
interface IVisitCountRecord {
    label: string;
    count: number;
}
export enum TRAINER_TYPES {
    TRAINER_ANALYTICS = 'Trainer',
    TRAINEE_ANALYTICS = 'Trainee',
}

const VisitsHistory: React.FC<Props> = (props) => {
    const [dateRange, setDateRange] = useState<IDateRange>({
        startDate: '',
        endDate: '',
    });

    const dispatch = useAppDispatch();
    const { dialogueToShow } = useAppSelector((state) => state.analytics.visitHistory);

    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);

    const containerRef = useRef<HTMLDivElement>(null);

    const handleScroll = () => {
        if (containerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
            if (scrollTop + clientHeight >= scrollHeight - 5 && hasMore) {
                setPage((prev) => prev + 1);
            }
        }
    };
    useEffect(() => {
        const current = containerRef.current;
        if (current) {
            current.addEventListener('scroll', handleScroll);
        }
        return () => {
            if (current) {
                current.removeEventListener('scroll', handleScroll);
            }
        };
    }, [hasMore]);

    const handleExpandClick = () => {
        dispatch(setVisitHistoryDialogue(VISIT_HISTORY_DIALOGUE.EXPANDED_VIEW));
    };
    const { t } = useTranslation();
    return (
        <Box
            component={'div'}
            sx={{ overflowX: 'hidden' }}
        >
            {/* <ExportVisitHistoryDialog
                isOpen={dialogueToShow === VISIT_HISTORY_DIALOGUE.EXPORT_VISIT_HISTORY}
                onClose={() => dispatch(setVisitHistoryDialogue(null))}
                dateRange={dateRange}
            /> */}
            <VisitHistoryDialog
                isOpen={dialogueToShow === VISIT_HISTORY_DIALOGUE.EXPANDED_VIEW}
                onClose={() => dispatch(setVisitHistoryDialogue(null))}
                dateRange={dateRange}
            />
            <Box sx={{ margin: '24px' }}>
                <VisitHistoryPageHeader
                    dateRange={dateRange}
                    setDateRange={setDateRange}
                />
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <VisitHistoryOverview dateRange={dateRange} />
                    <Box sx={{ paddingTop: '24px', width: '100%', height: '100%', position: 'relative' }}>
                        <IconButton
                            onClick={handleExpandClick}
                            sx={{ position: 'absolute', top: -30, right: -50, zIndex: 2, padding: 0 }}
                        >
                            {' '}
                            <ExpandIcon />
                        </IconButton>
                        <VisitHistoryCard dateRange={dateRange} />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export interface InfoRecord {
    id: string;
    value: string;
    selected: number;
    reset: string;
}
const VisitHistoryCard = (props) => {
    const { t } = useTranslation();
    const [procedureVisitHistorySearchPayload, setProcedureVisitHistorySearchPayload] = useState<IGetProcedureVisitHistorySearchPayload>({});
    const [userVisitHistorySearchPayload, setUserVisitHistorySearchPayload] = useState<IGetUserVisitHistorySearchPayload>({});
    const [procedureInfo, setProcedureInfo] = useState<InfoRecord>({ id: '', value: '', selected: -1, reset: "" });
    const [userInfo, setUserInfo] = useState<InfoRecord>({ id: '', value: '', selected: -1, reset: "" });
    const filterSearchPayload = (payload) => {
        Object.keys(payload).forEach((key) => {
            if ((Array.isArray(payload[key]) && payload[key].length === 0) || payload[key] === '') {
                delete payload[key];
                if (key === 'sortBy') {
                    delete payload.sortOrder;
                }
            }
        });

        return payload;
    };
    const onClickVisitList = (id: string, value: string, selected: number) => {
        const isSameProcedure = procedureInfo.id === id;
        setProcedureInfo(isSameProcedure ? { id: '', value: '', selected: -1, reset: "" } : { id, value, selected, reset: "" });
        setUserInfo({ id: '', value: '', selected: -1, reset: isSameProcedure ? "" : id });
        console.log("on click visit");

    };
    const onClickUserList = (id: string, value: string, selected: number) => {
        const isSameUser = userInfo.id === id;
        setUserInfo(isSameUser ? { id: '', value: '', selected: -1, reset: "" } : { id, value, selected, reset: "" });
        setProcedureInfo({ id: '', value: '', selected: -1, reset: isSameUser ? "" : id });
    };
    return (
        <Card
            sx={{ position: 'relative', display: 'flex', borderRadius: '12px', boxShadow: 'rgba(149, 157, 165, 0.5) 0px 8px 24px' }}
            elevation={0}
            className="work-instruction-card"
            style={{ width: '100%', height: props.height ?? '732px' }}
        >
            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', height: '100%', padding: '24px', gap: '24px' }}>
                <VisitCountWindow
                    label={t('COMMON.NO_OF_VISIT_BY_PROCEDURE')}
                    barColor="#219653"
                    fetchFunction={getProcedureVisitHistory}
                    processRecords={(data) =>
                        data.map((proc) => ({
                            label: proc.procedureName,
                            count: proc.numberOfVisits,
                            procedureName: proc.procedureName,
                            workInstructionName: proc.workInstructionName,
                            id: proc.procedureId,
                        }))
                    }
                    searchPayload={procedureVisitHistorySearchPayload}
                    setSearchPayload={(payload) => setProcedureVisitHistorySearchPayload(filterSearchPayload(payload))}
                    searchKey="search"
                    sortOptions={[
                        { value: 'numberOfVisits', label: 'Number Of Visits' },
                        { value: 'procedureName', label: 'Procedure Name' },
                    ]}
                    filterOptions={[{ filter: 'status', filterValues: Object.keys(VISIT_PROCEDURE_STATUS).map((each) => t('ANALYTICS_SECTION.' + each)) }]}
                    visitType={VISIT_TYPE.PROCEDURE_VISIT}
                    dateRange={props.dateRange}
                    // setProcedureInfo={setProcedureInfo}
                    userInfo={userInfo}
                    onClickList={onClickVisitList}
                    selected={procedureInfo.selected}
                    selectedId={procedureInfo.id}
                    reset={procedureInfo.reset}
                />

                <VisitCountWindow
                    label={t('COMMON.NO_OF_VISIT_BY_USERS')}
                    barColor="#9747FF"
                    fetchFunction={getUserVisitHistory}
                    processRecords={(data) =>
                        data.map((user) => ({
                            label: user.firstName + ' ' + user.lastName,
                            count: user.numberOfVisits,
                            email: user.email,
                            id: user.userId,
                        }))
                    }
                    searchPayload={userVisitHistorySearchPayload}
                    setSearchPayload={(payload) => setUserVisitHistorySearchPayload(filterSearchPayload(payload))}
                    searchKey="search"
                    sortOptions={[
                        { value: 'numberOfVisits', label: 'Number Of Visits' },
                        { value: 'firstName', label: 'Name' },
                    ]}
                    // filterOptions={[{ filter: 'Type', filterValues: [USER_TYPES.TRAINEE, USER_TYPES.TRAINER] }]}
                    filterOptions={[{ filter: 'Types', filterValues: Object.keys(TRAINER_TYPES).map((each) => t('ANALYTICS_SECTION.' + each)) }]}
                    visitType={VISIT_TYPE.USER_VISIT}
                    dateRange={props.dateRange}
                    // setUserInfo={setUserInfo}
                    procedureInfo={procedureInfo}
                    onClickList={onClickUserList}
                    selected={userInfo.selected}
                    selectedId={userInfo.id}
                    reset={userInfo.reset}
                />
            </Box>
        </Card>
    );
};

export { VisitHistoryCard };
export default VisitsHistory;
