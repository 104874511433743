import { Box, Button, Skeleton, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { IAssignedTraineeRecord } from '../../../../entities/Analytics/AssignedTraineeRecord';
import TraineeAssignedCard from './TraineeAssignedCard';
import { AnalyticsLabels, IDateRange, IOrganizationAssignedTrainee } from '../../../../entities/Analytics/AnalyticsProcedure';
import AnalyticsService from '../../../../services/Analytics/AnalyticsService';
import { Toast } from '../../../Notifications/Notification';
import { Navigate, useNavigate } from 'react-router-dom';
import { SelectionType, useAnalytics } from '../../../../states/analytics';
import { AnalyticsTooltip } from '../../ProcedureReports/AnalyticsTooltip';
import { useTranslation } from 'react-i18next';
import TraineeAssignedProgress from './TraineeAssignedProgress';
import { Feedback } from '../../ProcedureReports/Feedback';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';

interface ITraineesAssignedProps {
  dateRange: IDateRange;
  filterType: SelectionType;
  assignedTraineesData: any;
}

const TraineesAssigned: React.FC<ITraineesAssignedProps> = ({ dateRange, filterType, assignedTraineesData: data}) => {
  const demoData: Array<IOrganizationAssignedTrainee> = [
    {
      userId: '0bc5f35152bc4c84899ccfa4438b1a81',
      firstName: 'MI New',
      lastName: 'Trainee',
      progress: {
        notStarted: ['64268a98-a630-44ad-a304-6f0f055e8b5b', '0a9b6a2d-99c2-40ff-b0e0-f2834e553d74', 'e5a93215-2e77-48b1-98ce-56040c746b11', '14df7e75-9cc6-4ebc-b721-4ba2398ebd34'],
        inProgress: ['2d432a27-5590-442a-971d-c72a2e872626', 'd890f0e1-6a2d-411c-a5ec-47daa347c11c', 'cb024240-9eea-476b-a9d0-40af9fbcaebe', 'fef16eed-fc83-40b4-9eff-e9e1775a9677', 'd86320b3-2207-4070-8425-3660b45cb373', 'dc965207-0223-43bd-a26a-ccd9838de878', '981fe57f-058f-4a03-b60f-715f265e13dc', '5983ff7f-d440-4f86-a321-1a07d7d7da37'],
        completed: ['76f6ac7a-32fa-44a6-84d8-a5441ddb22b8'],
      },
    },
    {
      userId: '7e00459335694812a6de1d3b75db2b8d',
      firstName: 'Dev MI',
      lastName: 'Trainee 1',
      progress: {
        notStarted: ['64268a98-a630-44ad-a304-6f0f055e8b5b', 'cb024240-9eea-476b-a9d0-40af9fbcaebe', 'fef16eed-fc83-40b4-9eff-e9e1775a9677', 'dc965207-0223-43bd-a26a-ccd9838de878'],
        inProgress: ['e5a93215-2e77-48b1-98ce-56040c746b11', '14df7e75-9cc6-4ebc-b721-4ba2398ebd34', '76f6ac7a-32fa-44a6-84d8-a5441ddb22b8'],
        completed: ['d890f0e1-6a2d-411c-a5ec-47daa347c11c', '0a9b6a2d-99c2-40ff-b0e0-f2834e553d74'],
      },
    },
    {
      userId: 'e35c2aabc84f406983e66b5229c4e383',
      firstName: 'Santhosh',
      lastName: 'Ganensan',
      progress: {
        notStarted: ['d73673c3-bef9-43c9-9c27-5430cfbb8e57', 'fef16eed-fc83-40b4-9eff-e9e1775a9677', 'dc965207-0223-43bd-a26a-ccd9838de878'],
        inProgress: [],
        completed: [],
      },
    },
    {
      userId: 'a6bfb242a4f045388aa2908b8e21b7c2',
      firstName: 'Santhosh',
      lastName: 'Ganensan',
      progress: {
        notStarted: ['d73673c3-bef9-43c9-9c27-5430cfbb8e57', 'd11d0021-9156-497f-85a4-0ac094e6fceb', 'dc965207-0223-43bd-a26a-ccd9838de878', '5983ff7f-d440-4f86-a321-1a07d7d7da37'],
        inProgress: [],
        completed: [],
      },
    },
    {
      userId: '1742c8e8ae5e4e908a2d09a1239089bc',
      firstName: 'asdasd',
      lastName: 'asdasd',
      progress: {
        notStarted: ['d73673c3-bef9-43c9-9c27-5430cfbb8e57', 'dc965207-0223-43bd-a26a-ccd9838de878'],
        inProgress: [],
        completed: [],
      },
    },
    {
      userId: '683537adc4194e0fa2a4845d8e560968',
      firstName: 'asdasd',
      lastName: 'asdasd',
      progress: {
        notStarted: ['d73673c3-bef9-43c9-9c27-5430cfbb8e57', 'dc965207-0223-43bd-a26a-ccd9838de878'],
        inProgress: [],
        completed: [],
      },
    },
  ];
  
  const [loading, setLoading] = useState(false);
 
  const [filteredData, setFilteredData] = useState<any>();
  const navigate = useNavigate();


  const handleCardClick = () => {};
  const { t } = useTranslation();
  return (
    <Box component="div" sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
      <Box component="div" sx={{ display: 'flex', flexGrow: 1, flexDirection: 'row', justifyContent: 'space-between', mb: '24px' }}>
        {loading ? <Skeleton variant="rectangular" height={'50px'} width="200px" /> : <Feedback title= {t('ANALYTICS_SECTION.TRAINEES_PERFORMANCE_OVERVIEW')}  value={t('ANALYTICS_SECTION.TRAINEE_VALUE',{value:data?.completed?.length + data?.inProgress?.length + data?.notStarted?.length})} />}
        <PeopleAltOutlinedIcon sx={{ fontSize: '40px', color: '#60C3AF' }} />
      </Box>
      <Box
        component="div"
        sx={{
          display: 'flex',
          flexGrow: 1,
          
        }}
      >
        {loading ? (
          <Skeleton variant="rectangular" height={'100%'} width="100%" />
        ) : (
          <>
            <TraineeAssignedProgress
              onCardClick={handleCardClick}
              dateRange={dateRange}
              filterType={filterType}
              data={[
                {
                  name: 'Completed',
                  value: data.completed.length,
                },
                {
                  name: 'In Progress',
                  value: data.inProgress.length,
                },
                {
                  name: 'Not Started',
                  value: data.notStarted.length,
                },
              ]}
            />
          </>
        )}
      </Box>
    </Box>
  );
};

export default TraineesAssigned;
