import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import SearchBar from '../SearchBar/SearchBar';
import SelectSort, { SortOption } from '../SelectSort/SelectSort';
import FilterButton from '../FilterButton/FilterButton';
import { Box } from '@mui/material';
import { Filterable, Pageable, Searchable, Sortable } from '../../shared/interfaces';
import { FilterOption } from '../Dialog/mobileDialog/filterDialog/filterDialog';
import AppliedFilterList from '../FilterButton/AppliedFilterList';
import { VISIT_TYPE } from './constants';

interface IVisitsActionBarProps<PAYLOAD extends Pageable & Sortable<any> & Searchable<any, string> & Filterable<any, any>> {
    searchPayload: PAYLOAD;
    setSearchPayload: (payload: PAYLOAD) => void;
    searchKey: keyof PAYLOAD; // The key for the searchable field (e.g., "procedureName" or "userName")
    sortOptions: SortOption[]; // List of sort options (e.g., "count", "userName" or "procedureName")
    filterOptions: FilterOption[]; // List of filterable keys (e.g., "procedureStatus", "userPersona")
    VisitType?: string;
    reset?: string;
}
const VisitsActionBar = <PAYLOAD extends Pageable & Sortable<any> & Searchable<any, string> & Filterable<any, any>>({ searchPayload, setSearchPayload, searchKey, sortOptions, filterOptions, VisitType, ...props }: IVisitsActionBarProps<PAYLOAD>) => {
    const [appliedFilterList, setAppliedFilterList] = React.useState<any>([]);
    const inputRef = useRef<HTMLInputElement>(null); 
    const handleReset = () => {
        if (inputRef.current) {
            inputRef.current.value = ""; // Directly set the input's value
          }
        setAppliedFilterList([]);

    };
    useEffect(() => {
        handleReset();
    }, [props.reset]);
    // Handler for search input changes
    const handleSearchChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setSearchPayload({
            ...searchPayload,
            [searchKey]: event.target.value, // Dynamically updating based on searchKey
            currPage: 1,
        });
    };

    // Handler for sort changes
    const handleSortChange = (value: string): void => {
        setSearchPayload({
            ...searchPayload,
            sortBy: value as keyof PAYLOAD,
            sortOrder: searchPayload.sortOrder || 'asc',
            currPage: 1,
        });
    };

    // Handler for applying filters
    const handleFilterApply = (filters: { filter: string; value: string; applied: boolean }[]): void => {
        setAppliedFilterList(filters);
        if (filters.length < 1) {
            if (VisitType === VISIT_TYPE.PROCEDURE_VISIT) {
                const payload = { ...searchPayload };
                //@ts-ignore
                payload.status = [];
                delete payload.status;
                setSearchPayload({ ...payload, currPage: 1 });
            }
            if (VisitType === VISIT_TYPE.USER_VISIT) {
                const payload = { ...searchPayload };
                //@ts-ignore
                payload.Types = [];
                delete payload.Types;
                delete payload.userType;
                setSearchPayload({ ...payload, currPage: 1 });
            }
            return;
        }
        // Convert the string of selected filters into the payload format
        const payloadToAppend = {};

        filters.forEach((filter) => {
            const { filter: filterKey, value: filterValue, applied } = filter;
            if (filterKey && filterValue) {
                if (!payloadToAppend[filterKey]) {
                    //@ts-ignore
                    payloadToAppend[filterKey] = [];
                }
                applied && payloadToAppend[filterKey].push(filterValue);
            }
        });

        setSearchPayload({ ...searchPayload, ...payloadToAppend, currPage: 1 });
    };

    const handleRemoveFilterClick = (item) => {
        const { filter, value } = item;
        const currentFilters = searchPayload[filter];
        setSearchPayload({
            ...searchPayload,
            [filter]: currentFilters?.filter((any) => any !== value),
            currPage: 1,
        });

        setAppliedFilterList(appliedFilterList?.map((each) => (each.value === value ? { ...each, applied: false } : each)));
    };
    return (
        <>
            <Box
                component="div"
                sx={{ display: 'flex', padding: '8px 0', gap: '8px' }}
            >
                <SearchBar
                    onChangeHandler={handleSearchChange}
                    inputRef={inputRef}
                />
                <SelectSort
                    value={searchPayload.sortBy || ''} // Dynamically handling sort
                    onChange={handleSortChange}
                    sortOptions={sortOptions} // Dynamic sort options
                />
                <FilterButton
                    selectedFilters={appliedFilterList}
                    onApplyFilterClick={handleFilterApply}
                    onFilterClose={() => {}}
                    filterOptions={filterOptions} // Pass filter options
                />
            </Box>
            <AppliedFilterList
                appliedFilterList={appliedFilterList}
                onRemoveFilterClick={handleRemoveFilterClick}
                isTrainer={true}
                sx={{ padding: 0 }}
            />
        </>
    );
};

export default VisitsActionBar;
