import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Bar, BarChart, CartesianGrid, Cell, Label, LabelList, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { IOrganizationTraineeActivityAnalysis } from '../../../../entities/Analytics/AnalyticsProcedure';
import { useDeviceResponsiveSize } from '../../../../hooks/useDeviceResponsiveSize';
import { DATE_FORMAT } from '../../../../shared/utils/const-helpers';
import './TraineeAssignedProceduresAreaChart.scss';
import { Box } from '@mui/material';

interface TraineeAssignedProceduresAreaChartProps {
  data: Array<IOrganizationTraineeActivityAnalysis>;
}

export const TraineeAssignedProceduresAreaChart: React.FC<TraineeAssignedProceduresAreaChartProps> = ({ data }) => {
  const responsiveSize = useDeviceResponsiveSize();
  interface CustomTooltipProps {
    active?: any;
    payload?: any;
    label?: any;
  }

  const CustomTooltip = ({ active, payload, label }: CustomTooltipProps) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label">{`${payload[0]?.payload?.value} ${payload[0]?.payload?.value === 1 ? t('PROCEDURE.PROCEDURE') : t('PROCEDURE.PROCEDURES')} ${t('ANALYTICS_SECTION.COMPLETED')}`}</p>
          <p className="desc">{`${t('ANALYTICS_SECTION.ON')} ${moment(payload[0]?.payload?.date, DATE_FORMAT).format('MMM DD, YYYY')}`}</p>
        </div>
      );
    }

    return null;
  };

  const { t } = useTranslation();
  const barColors = [
    { start: '#59BFAB', end: '#ACE8DA' },
    { start: '#DAE187', end: '#EAEDCB' },
    { start: '#E47D62', end: '#E9B9AD' },
  ];

  return (
    <Box component="div" sx={{ display: 'flex', flexGrow: 1, flexDirection: 'column', height: '100%' }}>
      <ResponsiveContainer width={'100%'}>
        <BarChart data={data} barCategoryGap={1} barSize={36} margin={{ top: 24 }}>
          <CartesianGrid vertical={false} strokeDasharray="4 4" stroke="#0000000d" />
          <XAxis fontSize={12} stroke="#A0A3A5" axisLine={false} tickLine={false} dataKey="date" tickFormatter={(tickItem) => moment(tickItem, DATE_FORMAT).format('DD')}>
            <Label value={t('ANALYTICS_SECTION.DATE') + ' ➜'} offset={0} position="bottom" style={{ margin: '16px', fontSize: '12px', fill: '#A0A3A5' }} />
          </XAxis>
          <YAxis fontSize={12} stroke="#A0A3A5" axisLine={false} tickLine={false} dataKey="value" allowDecimals={false}>
            <Label value={t('ANALYTICS_SECTION.COMPLETED_PROCEDURES')} position="insideLeft" angle={-90}  style={{ fontSize: '12px', fill: '#A0A3A5', textAnchor: 'middle' }} />
          </YAxis>
          <defs>
            <linearGradient id="mlgColorCalls" x1="0" y1="0" x2="1" y2="0">
              <stop offset="0%" stopColor="#A9BEE4" stopOpacity={1} />
              <stop offset="50%" stopColor="#C3D5F8" stopOpacity={1} />
              <stop offset="100%" stopColor="#D6E3FD" stopOpacity={1} />
            </linearGradient>
          </defs>
          <Bar isAnimationActive={false} dataKey="value" radius={[6, 6, 0, 0]}>
            {data?.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={`url(#mlgColorCalls)`} />
            ))}
            {/* <LabelList dataKey="value" position="right" content={renderCustomizedLabel} fill="black" />
            <LabelList dataKey="name" content={renderCustomizedLabelName} fill="black" /> */}
          </Bar>
          <Tooltip cursor={{ fill: 'transparent' }} wrapperStyle={{ outline: 'none' }} content={<CustomTooltip />} />
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default TraineeAssignedProceduresAreaChart;
