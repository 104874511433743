import { Box, Card, CardActionArea, CardContent, Grid, Skeleton, Typography, useTheme } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { setEditDialogData } from '../../redux/features/Substitutions/Substitutions.slice';
import SubstitutionsEmptyState from './SubstitutionsEmptyState';
import { ISubstitution } from './Substitutions';
import { setSidebarToOpen } from '../../redux/features/SideBar/Sidebar.slice';
import { APP_SIDEBARS, SUPPORTED_LANGUAGES } from '../../shared/enums';
import { setLoading, setSubstitutions } from '../../redux/features/Substitutions/Substitutions.slice';
import { getAllSubstitutions } from '../../services/Substitution/SubstitutionService';
import { setSearchPayload } from '../../redux/features/Substitutions/Substitutions.slice';
import { CanceledError } from 'axios';
const LoadingSkeleton = (props) => {
    return (
        <>
            <Grid
                item
                xs={3}
            >
                <Skeleton sx={{ height: '300px' }} />
            </Grid>
            <Grid
                item
                xs={3}
            >
                <Skeleton sx={{ height: '300px' }} />
            </Grid>
            <Grid
                item
                xs={3}
            >
                <Skeleton sx={{ height: '300px' }} />
            </Grid>
            <Grid
                item
                xs={3}
            >
                <Skeleton sx={{ height: '300px' }} />
            </Grid>
        </>
    );
};
const SubstitutionsContainer = (props) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { substitutions, loading, searchPayload } = useAppSelector((state) => state.substitution);
    const [collatedSubstitutions, setCollatedSubstitutions] = useState<any>([]);
    const { companyTheme, currentUser } = useAppSelector((state) => state.app);
    const observerRef = useRef<IntersectionObserver | null>(null);
    const [pageLoading, setPageLoading] = useState<boolean>(false);
    const [currentPageNo, setCurrentPageNo] = useState<number>(1);
    const [hasMore, setHasMore] = useState<boolean>(false);
    const [totalPagesNo, setTotalPagesNo] = useState<number>(1);
    const [totalDocumentsNo, setTotalDocumentsNo] = useState<number>(1);
    const previousController = useRef<any>(null);
    const targetRef = useRef(null);
    const [isOutOfView, setOutOfView] = useState<boolean>(false);
    useEffect(() => {
        return () => {
            dispatch(setSidebarToOpen(null));
            let temp = { ...searchPayload, currPage: 1 };
            delete temp.search;
            delete temp.language;
            dispatch(setSearchPayload(temp));
        };
    }, []);

    useEffect(() => {
        const collateData = (data: ISubstitution[]) => {
            const newData: any = [];
            data.forEach((datum, index) => {
                newData[index] = {
                    word: datum.word,
                    substitutions: [
                        {
                            sourceWord: datum.word,
                            substitute: datum.substitute,
                            language: datum.language,
                            id: datum.id,
                        },
                    ],
                };
            });
            return newData;
        };
        // @ts-ignore
        const { data, currentPage, totalPages, totalDocuments } = substitutions;
        if (!Array.isArray(data)) return;
        setTotalPagesNo(totalPages);
        setTotalDocumentsNo(totalDocuments);
        setCurrentPageNo(currentPage);
        setHasMore(currentPage < totalPages);
        if (currentPage > 1) {
            setCollatedSubstitutions((prev) => [...prev, ...collateData(data)]);
        } else {
            setCollatedSubstitutions(collateData(data));
        }
    }, [substitutions]);

    useEffect(() => {
        if (previousController.current) {
            previousController.current.abort();
        }
        const controller = new AbortController();
        const signal = controller.signal;
        previousController.current = controller;
        let payload = {
            ...searchPayload,
            currPage: searchPayload?.currPage ?? currentPageNo,
            pageSize: 20,
        };

        const no = searchPayload?.currPage ? searchPayload?.currPage : currentPageNo;
        if (no === 1) dispatch(setLoading(true));
        getAllSubstitutions(payload, signal)
            .then((res) => {
                dispatch(setSubstitutions(res.data.data));
            })
            .catch((e) => {
                if (e instanceof CanceledError) return;
            })
            .finally(() => {
                dispatch(setLoading(false));
                setPageLoading(false);
            });
    }, [searchPayload]);

    useEffect(() => {
        if (pageLoading) return;
        const targetElement = targetRef.current;
        const observerOptions = {
            root: null,
            rootMargin: '0px',
            threshold: 0,
        };

        const observerCallback = (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    if (isOutOfView && hasMore) {
                        setPageLoading(true);
                        setOutOfView(false);
                        loadNext();
                    }
                } else {
                    setOutOfView(true);
                }
            });
        };
        const observer = new IntersectionObserver(observerCallback, observerOptions);
        if (targetElement) {
            observer.observe(targetElement);
        }
        return () => {
            if (targetElement) {
                observer.unobserve(targetElement);
            }
        };
    }, [pageLoading, isOutOfView, hasMore]);

    const loadNext = () => {
        dispatch(
            setSearchPayload({
                ...searchPayload,
                currPage: currentPageNo + 1,
            }),
        );
    };
    return (
        <>
            <Grid
                container
                spacing={2}
            >
                {loading ? (
                    <LoadingSkeleton />
                ) : collatedSubstitutions.length ? (
                    <>
                        {collatedSubstitutions.map((each: any, index: number) => (
                            <SubstituteWordCard
                                key={index}
                                index={index}
                                word={each.word}
                                substitutions={each.substitutions}
                                sourceLanguage={each.sourceLanguage}
                            />
                        ))}
                        {pageLoading && <Typography sx={{ textAlign: 'center', margin: 'auto', width: '100%', padding: '16px', fontWeight: '400' }}>{t('COMMON.LOADING')}</Typography>}
                    </>
                ) : (
                    <Grid
                        item
                        xs={12}
                    >
                        <SubstitutionsEmptyState />
                    </Grid>
                )}
            </Grid>
            <div
                ref={targetRef}
                style={{ height: '10px', width: '100%', background: 'white' }}
            >
                {''}
            </div>
        </>
    );
};

const limit = 3;
const SubstituteWordCard = (props) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { editDialogData } = useAppSelector((state) => state.substitution);
    const theme = useTheme();

    const { word, substitutions, sourceLanguage, index } = props;
    const [isSelected, setIsSelected] = useState<boolean>(false);

    useEffect(() => {
        setIsSelected(editDialogData?.word === word && editDialogData?.index === index);
    }, [editDialogData]);

    const handleSubstitutionCardClick = () => {
        dispatch(
            setEditDialogData({
                word,
                substitutions,
                sourceLanguage: sourceLanguage,
                index: index,
            }),
        );
        dispatch(setSidebarToOpen(APP_SIDEBARS.EDIT_SUBSTITUTION));
    };
    return (
        <Grid
            item
            xs={12 / 1}
            sm={12 / 2}
            md={12 / 3}
            lg={12 / 4}
            xl={12 / 5}
            sx={{ marginBottom: '16px' }}
        >
            <Card
                sx={{
                    border: '1px solid',
                    borderColor: isSelected ? '#84aacb' : '#D9D9D9',
                    background: isSelected ? '#e6f3fe' : 'transparent',
                    borderRadius: '8px',
                    height: '100%',
                    minHeight: '100px', //165px
                }}
            >
                <CardActionArea
                    sx={{ height: '100%' }}
                    onClick={handleSubstitutionCardClick}
                >
                    <CardContent
                        key={index}
                        sx={{ height: '100%', display: 'flex', flexDirection: 'column', gap: '10px' }}
                    >
                        {substitutions.map(({ substitute, language }) => (
                            <>
                                <Box sx={{ alignSelf: 'flex-end', background: '#e6f3fe', width: 'fit-content', padding: '3px 15px', borderRadius: '10px', textAlign: 'center' }}>
                                    <Typography
                                        variant="h5"
                                        sx={{ fontSize: '12px', textTransform: 'capitalize' }}
                                    >
                                        {t('LANGUAGE_SIDEBAR.SUPPORTED_LANGUAGES.' + language)}
                                    </Typography>
                                </Box>
                                <WordContainer
                                    word={word}
                                    lang={t('SUBSTITUTION.ADD_MODAL.BEFORE')}
                                />
                                <WordContainer
                                    word={substitute}
                                    lang={t('SUBSTITUTION.ADD_MODAL.AFTER')}
                                />
                            </>
                        ))}
                    </CardContent>
                </CardActionArea>
            </Card>
        </Grid>
    );
};
interface WordContainerProps {
    word: string;
    lang: string;
}
const WordContainer: React.FC<WordContainerProps> = ({ word, lang }) => {
    return (
        <Box
            component={'div'}
            sx={{ display: 'flex', flexDirection: 'column', paddingBlock: '5px', paddingInline: '10px', borderRadius: '8px', background: '#f9f9fb' }}
        >
            <Typography
                variant="h6"
                sx={{ fontSize: '12px', opacity: '0.3', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
            >
                {lang}
            </Typography>
            <Typography
                variant="h6"
                sx={{ fontSize: '14px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
            >
                {word}
            </Typography>
        </Box>
    );
};

export default SubstitutionsContainer;

const getLanguageKey = (value: string, t): string | undefined => {
    value = value.toUpperCase();
    const tmp = t('LANGUAGE_SIDEBAR.SUPPORTED_LANGUAGES.' + value);
    return Object.keys(SUPPORTED_LANGUAGES).find((key) => {
        return SUPPORTED_LANGUAGES[key as keyof typeof SUPPORTED_LANGUAGES] === value;
    });
};
