import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { DialogueActionContainer, EditSubstitution, SUBSTITUTE_WORD_LIMIT, useEditSubstitution } from './AddSubstitutionModal';
import { Box, Button, CircularProgress, ClickAwayListener, FormControl, IconButton, Paper, TextField, Typography } from '@mui/material';
import { Add, Close, Delete, DeleteOutline, Edit } from '@mui/icons-material';
import { Trans, useTranslation } from 'react-i18next';
import { SUPPORTED_LANGUAGES } from '../../shared/enums';
import { setSidebarToOpen } from '../../redux/features/SideBar/Sidebar.slice';
import { AddSubstitutionPayload } from './Substitutions';
import * as SubstitutionService from '../../services/Substitution/SubstitutionService';
import { Toast } from '../../components/Notifications/Notification';
import { fetchAllSubstitutions, setEditDialogData } from '../../redux/features/Substitutions/Substitutions.slice';
import { BasicDialog } from '../../components/Trainer/Dialog/BasicDialog/BasicDialog';

interface EditSubstitutionPayload extends AddSubstitutionPayload {
    id: string;
}
export interface ISubstitution {
    id: string;
    language: string;
    substitute: string;
    sourceWord: string;
}
export interface IEditSubstitutionData {
    word: string;
    substitutions: ISubstitution[];
    sourceLanguage: string;
    index: number;
}

const EditSubstitutionSidebar = (props) => {
    const dispatch = useAppDispatch();
    const { editDialogData } = useAppSelector((state) => state.substitution);
    const { companyTheme, currentUser } = useAppSelector((state) => state.app);
    const { t } = useTranslation();
    const editSubstitutionStates = useEditSubstitution();
    const { substitutionsToAdd, setSubstitutionsToAdd } = editSubstitutionStates;
    const { wordToSubstitute, setWordToSubstitute } = editSubstitutionStates;
    const { isSaveDisabled } = editSubstitutionStates;

    const { handleAddLanguageClick } = editSubstitutionStates;

    const [isEditWordClicked, setIsEditWordClicked] = useState<boolean>(false);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [isDeleteSubstitutionModalOpen, setIsDeleteSubstitutionModalOpen] = useState<boolean>(false);

    useEffect(() => {
        if (editDialogData) {
            setWordToSubstitute(editDialogData.word);
            setSubstitutionsToAdd(
                editDialogData.substitutions.map((each) => ({
                    id: each.id,
                    language: each.language?.toLowerCase(),
                    substituteText: each.substitute,
                    sourceWord: each.sourceWord,
                })),
            );
        }
    }, [editDialogData]);

    const handleWordToSubstituteChange = (e) => {
        let value: string = e.target.value;
        value = value.substring(0, SUBSTITUTE_WORD_LIMIT);
        setWordToSubstitute(value);
    };

    const closeSidebar = () => {
        dispatch(setSidebarToOpen(null));
        dispatch(setEditDialogData(null));
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' || e.key === 'Tab') {
            setIsEditWordClicked(false);
        }
    };

    const handleSaveClick = (e) => {
        if (!companyTheme || !editDialogData) return;
        setIsSaving(true);
        const payloadArray: any = [];
        const promises: any = [];

        // if a payload of existing subtitutions doesn't exit in current request then it needs to be deleted.
        editDialogData.substitutions.forEach((existingSubstitution) => {
            if (!(substitutionsToAdd as any).find((any) => any.id === existingSubstitution.id)) {
                promises.push(SubstitutionService.deleteSubstitution(existingSubstitution.id));
            }
        });

        (substitutionsToAdd as any).forEach((data) => {
            const payload: EditSubstitutionPayload = {
                // byDefaultLanguage: editDialogData.sourceLanguage,
                language: data.language.toUpperCase(),
                // word: wordToSubstitute,
                word: data.sourceWord,
                substitute: data.substituteText,
                organizationId: currentUser.organizationId,
                id: data.id,
            };
            payloadArray.push(payload);
        });

        payloadArray.forEach((each) => {
            // if payload has id then its update request else new substitution needs to be created.
            if (!each.id) {
                promises.push(SubstitutionService.addSubstitution(each));
            } else {
                promises.push(SubstitutionService.updateSubstitution(each.id, each));
            }
        });

        Promise.all(promises)
            .then((responses) => {})
            .catch((error) => {
                const { response } = error;
                Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
            })
            .finally(() => {
                setIsSaving(false);
                dispatch(fetchAllSubstitutions());
                closeSidebar();
            });
    };

    const handleDeleteClick = () => {
        if (!editDialogData) return;
        const promises: any = [];

        editDialogData.substitutions.forEach((existingSubstitution) => {
            promises.push(SubstitutionService.deleteSubstitution(existingSubstitution.id));
        });

        Promise.all(promises)
            .then(() => {})
            .catch((error) => {
                const { response } = error;
                Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
            })
            .finally(() => {
                setIsDeleteSubstitutionModalOpen(false);
                setIsSaving(false);
                dispatch(fetchAllSubstitutions());
                closeSidebar();
            });
    };

    return (
        // <ClickAwayListener onClickAway={handleClickAwayListner}>
        <Box
            component={'div'}
            sx={{ height: '100%', margin: '16px 0' }}
        >
            <Box
                component={'div'}
                sx={{
                    display: 'flex',
                    width: '100%',
                    marginRight: '10px',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom:'24px'
                }}
            >
                
                <Box component={'div'} sx={{background:'#F9F9FB',width:'70%',paddingBlock:'10px',paddingLeft:'16px' }} >
                    <Typography>{t('SUBSTITUTION.SUBSTITUTE')}</Typography>
                </Box>

                <Box
                    component={'div'}
                    sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                >
                    <Button
                        sx={{ marginLeft: 'auto', minWidth: '80px' }}
                        startIcon={<DeleteOutline />}
                        onClick={() => setIsDeleteSubstitutionModalOpen(true)}
                        // disabled={Object.keys(SUPPORTED_LANGUAGES).length === substitutionsToAdd.length}
                    >
                        {t('BUTTON.DELETE')}
                    </Button>
                    <IconButton
                        sx={{
                            minWidth: '30px',
                            marginLeft: 'auto',
                            color: 'black',
                            padding: 0,
                            margin: 0,
                            fontSize: '30px',
                        }}
                        onClick={closeSidebar}
                    >
                        <Close fontSize="inherit" />
                    </IconButton>
                </Box>
            </Box>
            <Box component={'div'}>
                <EditSubstitution
                    substitutionsToAdd={substitutionsToAdd}
                    setSubstitutionsToAdd={setSubstitutionsToAdd}
                    isSideMenu={true}
                />
            </Box>
            <Box
                component={'div'}
                sx={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    borderRadius: 0,
                    width: '100%',
                    padding: '8px 16px',
                    textAlign: 'right',
                    borderTop: '1px solid #D9D9D9',
                }}
            >
                <DialogueActionContainer
                    handleClose={closeSidebar}
                    handleSave={handleSaveClick}
                    isSaveDisabled={isSaveDisabled}
                />
            </Box>
            <BasicDialog
                title={t('SUBSTITUTION.DELETE_MODAL.TITLE')}
                onClose={() => setIsDeleteSubstitutionModalOpen(false)}
                onOpen={isDeleteSubstitutionModalOpen}
                actions={
                    <DeleteSubstitutionAction
                        setIsDeleteSubstitutionModalOpen={setIsDeleteSubstitutionModalOpen}
                        handleDeleteClick={handleDeleteClick}
                    />
                }
                fullWidth={true}
                maxWidth={'sm'}
            >
                <Trans
                    i18nKey={'SUBSTITUTION.DELETE_MODAL.BODY'}
                    components={{ b: <b /> }}
                    values={{ word: editDialogData?.word }}
                />
            </BasicDialog>
        </Box>
        // </ClickAwayListener>
    );
};

const DeleteSubstitutionAction = ({ setIsDeleteSubstitutionModalOpen, handleDeleteClick }) => {
    const { t } = useTranslation();
    const [isActionButtonClicked, setIsActionButtonClicked] = useState<boolean>(false);

    const handleActionButtonClick = () => {
        setIsActionButtonClicked(true);
        handleDeleteClick();
    };

    return (
        <Box>
            <Button
                variant="outlined"
                onClick={() => setIsDeleteSubstitutionModalOpen(false)}
                sx={{ marginRight: '12px' }}
            >
                {' '}
                {t('BUTTON.CANCEL')}
            </Button>
            <Button
                variant="contained"
                type="submit"
                onClick={handleActionButtonClick}
                disabled={isActionButtonClicked}
                endIcon={isActionButtonClicked ? <CircularProgress size={20} /> : null}
            >
                {' '}
                {t('COMMON.DELETE')}
            </Button>
        </Box>
    );
};

export default EditSubstitutionSidebar;
