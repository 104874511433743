import { Box, Button, Chip, Container, Paper, Skeleton, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import SearchBar from '../../../SearchBar/SearchBar';
import SelectSort from '../../../SelectSort/SelectSort';
import FilterButton from '../../../FilterButton/FilterButton';
import DateRangeSelector from '../../../DateRangeSelector/DateRangeSelector';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import AnalyticsService from '../../../../services/Analytics/AnalyticsService';
import InfiniteScroll from 'react-infinite-scroll-component';
import TableContainer, { TableColumnHeader } from '../TableContainer';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { debounce } from 'lodash';
import { IProcedureListPageSearchPayload, setProcedureListSearchPayload } from '../../../../redux/features/Analytics/Analytics.slice';
import AppliedFilterList from '../../../FilterButton/AppliedFilterList';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ANALYTICS_SUPPORTED_VIEWS, ENTITY, PROCEDURE_LOCATION, PROCEDURE_STATUS, PROCEDURE_VISIBILITY_SCOPE, SELECT_DATARANGE_OPTIONS, SUPPORTED_VIEWS } from '../../../../shared/enums';
import { setCurrentView } from '../../../../redux/features/HomePage/HomePage.slice';
import { IDateRange } from '../../../../entities/Analytics/AnalyticsProcedure';
import axios, { CanceledError } from 'axios';
import { CheckCircleOutline, HighlightOff } from '@mui/icons-material';
import ManagerApproval from '../../../Trainer/Dialog/ManagerApproval/ManagerApproval';

const columns = [
    {
        Header: <TableColumnHeader label={'ANALYTICS_SECTION.PROCEDURE'} />,
        accessor: 'procedureName',
        disableSortBy: true,
        Cell: ({ value, row }) => {
            const navigate = useNavigate();
            const dispatch = useAppDispatch();
            const handleProcedureClick = () => {
                const companyId = localStorage.getItem('company');
                const workInstructionId = row.original.workInstructionId;
                if (row.original.visibilityScope === PROCEDURE_VISIBILITY_SCOPE.GLOBAL) {
                    dispatch(setCurrentView(SUPPORTED_VIEWS.PROCEDURE_LIBRARY));
                    navigate(`/trainer/home/${companyId}`);
                } else {
                    navigate(`/trainer/procedures/${companyId}/${workInstructionId}`);
                }
            };
            return (
                <Box
                    component={'div'}
                    sx={{ marginLeft: '8px', display: 'flex', flexDirection: 'row', alignItems: 'center', color: 'primary.main', cursor: 'pointer' }}
                    onClick={handleProcedureClick}
                >
                    <Typography sx={{ marginLeft: '8px', fontSize: '16px', fontWeight: '600' }}>{value}</Typography>
                </Box>
            );
        },
    },
    {
        Header: <TableColumnHeader label={'PROCEDURE.CARD_VERSION'} />,
        accessor: 'version',
        disableSortBy: true,
        Cell: ({ value, row }) => {
            return (
                <Box
                    component={'div'}
                    sx={{ display: 'flex', alignItems: 'center', gap: '5px', fontSize: '16px' }}
                >
                    {row.original.versionId ? (
                        <>
                            v{value}{' '}
                            <Chip
                                label={Number(value) === Math.round(Number(value)) ? 'Major' : 'Minor'}
                                size="small"
                                variant={'outlined'}
                                sx={{ borderRadius: 0, fontSize: '12px' }}
                            />
                        </>
                    ) : (
                        '-'
                    )}
                </Box>
            );
        },
    },
    {
        Header: <TableColumnHeader label={'ANALYTICS_SECTION.WI_OR_PRO_LIB'} />,
        accessor: 'workInstructionName',
        disableSortBy: true,
        Cell: ({ value, row }) => {
            const { t } = useTranslation();
            return (
                <Box
                    component={'div'}
                    sx={{ fontSize: '16px' }}
                >
                    {row.original.visibilityScope === PROCEDURE_VISIBILITY_SCOPE.GLOBAL ? t('TOOLBAR.PROCEDURE_LIBRARY') : value}
                </Box>
            );
        },
    },
    {
        Header: <TableColumnHeader label={'COMMON.DATE_AND_TIME'} />,
        accessor: 'createdOn',
        disableSortBy: true,
        Cell: ({ value, row }) => {
            const [managerApprovalModalAction, setManagerApprovalModalAction] = useState<null | 0 | 1>(null);

            const { t } = useTranslation();
            const { approvalRequested, approvalStatus } = row.original;
            return (
                <Box
                    component={'div'}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        fontSize: '16px',
                    }}
                >
                    {moment(value * 1000).format('MMM DD, YYYY | hh:mm A')}

                    {approvalRequested && approvalStatus === 'pending' && (
                        <>
                            <Box component={'div'}>
                                <Button
                                    startIcon={<CheckCircleOutline />}
                                    onClick={() => setManagerApprovalModalAction(0)}
                                >
                                    {t('PROCEDURE.APPROVE')}
                                </Button>
                                <Button
                                    startIcon={<HighlightOff />}
                                    onClick={() => setManagerApprovalModalAction(1)}
                                >
                                    {t('ADD_USER.REJECT')}
                                </Button>
                            </Box>
                            <ManagerApproval
                                openDialog={managerApprovalModalAction === 0 || managerApprovalModalAction === 1}
                                closeDialog={() => setManagerApprovalModalAction(null)}
                                procedure={row.original}
                                action={managerApprovalModalAction}
                            />
                        </>
                    )}
                </Box>
            );
        },
    },
];
const ProcedureList = (props) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const location = useLocation();
    const previousController = useRef<any>(null);

    const { searchPayload } = useAppSelector((state) => state.analytics.procedureListPage);
    const { userId } = useAppSelector((state) => state.app.currentUser);
    const { header } = useAppSelector((state) => state.analytics);
    // const { dateRange } = useAppSelector((state) => state.analytics.header);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [procedureList, setProcedureList] = useState<any>([]);
    const [tableData, setTableData] = useState<any>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [totalDocuments, setTotalDocuments] = useState<number>(0);
    const [sortBy, setSortBy] = useState<string>('');

    const [selectedFilters, setSelectedFilters] = useState<any>([
        { filter: 'status', value: t('ANALYTICS_SECTION.' + location.state?.procedureListPayload.status), applied: true },
        { filter: 'view_by', value: t('ANALYTICS_SECTION.' + location.state?.procedureListPayload.selectedEntity), applied: true },
    ]);
    const [selectedDateRangeOption, setSelectedDateRangeOption] = useState<SELECT_DATARANGE_OPTIONS>(!location.state?.procedureListPayload.startDate ? SELECT_DATARANGE_OPTIONS.ALL : header.selectedDateRangeOption);
    const [dateRange, setDateRange] = useState<IDateRange>({ startDate: location.state?.procedureListPayload.startDate, endDate: location.state?.procedureListPayload.endDate });

    useEffect(() => {
        setSelectedFilters([
            { filter: 'status', value: t('ANALYTICS_SECTION.' + location.state?.procedureListPayload.status), applied: true },
            { filter: 'view_by', value: t('ANALYTICS_SECTION.' + location.state?.procedureListPayload.selectedEntity), applied: true },
        ]);
    }, [t('ANALYTICS_SECTION.' + location.state?.procedureListPayload.status)]);
    useEffect(() => {
        return () => {
            let temp = { ...searchPayload };
            // delete temp.procedureName;
            delete temp.search;
            setSearchPayload(temp);
        };
    }, []);

    useEffect(() => {
        let payload = {
            ...searchPayload,
            currPage: 1,
        };

        if (selectedDateRangeOption === SELECT_DATARANGE_OPTIONS.ALL) {
            delete payload.startDate;
            delete payload.endDate;
        } else {
            payload = {
                ...payload,
                startDate: dateRange.startDate,
                endDate: dateRange.endDate,
            };
        }

        setSearchPayload(payload);
    }, [dateRange]);

    useEffect(() => {
        if (previousController.current) {
            previousController.current.abort();
        }
        const controller = new AbortController();
        const signal = controller.signal;
        previousController.current = controller;

        if (currentPage === 1) setIsLoading(true);

        AnalyticsService.getAnalyticsProcedureList({ ...searchPayload, user: header.selectedOverview === ANALYTICS_SUPPORTED_VIEWS.PERSONAL ? userId : undefined }, signal)
            .then((response) => {
                if (response.currentPage > 1) {
                    setProcedureList([...procedureList, ...response.data]);
                } else {
                    setProcedureList(response.data);
                }
                setCurrentPage(response.currentPage);
                setTotalPages(response.totalPages);
                setTotalDocuments(response.totalDocuments);
                setIsLoading(false);
            })
            .catch((error) => {
                if (error instanceof CanceledError) return;
                console.error('error while getting data => ', error);
                setIsLoading(false);
            });
    }, [searchPayload]);

    useEffect(() => {
        setTableData(procedureList.map((each) => each));
    }, [procedureList]);

    useEffect(() => {
        const FILTER_MAPPINGS = {};
        Object.keys(PROCEDURE_STATUS).forEach((key) => (FILTER_MAPPINGS[t('ANALYTICS_SECTION.' + key)] = PROCEDURE_STATUS[key]));
        FILTER_MAPPINGS[t('ANALYTICS_SECTION.' + ENTITY.PROCEDURE)] = PROCEDURE_LOCATION.ALL;
        FILTER_MAPPINGS[t('ANALYTICS_SECTION.' + ENTITY.WORK_INSTRUCTION)] = PROCEDURE_LOCATION.WORK_INSTRUCTION;

        const filters = {
            status: selectedFilters.filter((each) => each.filter === 'status' && each.applied).map((each) => FILTER_MAPPINGS[each.value]),
            procedureLocation: selectedFilters.filter((each) => each.filter === 'view_by' && each.applied).map((each) => FILTER_MAPPINGS[each.value]),
        };

        const payloadToSet = {
            ...searchPayload,
            ...filters,
            currPage: 1,
        };

        if (!payloadToSet.procedureLocation.length) {
            delete payloadToSet.procedureLocation;
        }

        if (!payloadToSet.status.length) {
            delete payloadToSet.status;
        }

        setSearchPayload(payloadToSet);
    }, [selectedFilters]);

    const setSearchPayload = (payload: IProcedureListPageSearchPayload) => {
        dispatch(setProcedureListSearchPayload(payload));
    };

    const loadNext = () => {
        setSearchPayload({
            ...searchPayload,
            currPage: (searchPayload.currPage ?? 0) + 1,
        });
    };

    const handleSearchTextChange = debounce((event: React.ChangeEvent<HTMLInputElement>): void => {
        const searchText = event.target.value;
        const payload = {
            ...searchPayload,
        };
        if (!searchText) {
            delete payload.search;
        } else {
            payload.search = searchText;
            payload.currPage = 1;
        }
        setSearchPayload(payload);
    }, 500);

    const handleSortChange = (value: string) => {
        setSortBy(value);
        switch (value) {
            case 'Date Added':
                setSearchPayload({
                    ...searchPayload,
                    sortBy: 'createdOn',
                    sortOrder: 'asc',
                    currPage: 1,
                });
                break;
            case 'Alphabetical':
                setSearchPayload({
                    ...searchPayload,
                    sortBy: 'procedureName',
                    sortOrder: 'asc',
                    currPage: 1,
                });
                break;
            default:
                let payload = structuredClone(searchPayload);
                delete payload?.sortBy;
                delete payload?.sortOrder;
                setSearchPayload(payload);
        }
    };

    const handleRemoveFilterClick = (item) => {
        const filterToSet = selectedFilters.map((each) => {
            if (each.filter === item.filter && each.value === item.value) {
                let filter = structuredClone(each);
                filter.applied = false;
                return filter;
            }
            return each;
        });

        setSelectedFilters(filterToSet);
    };
    return (
        <Box
            component={'div'}
            sx={{ alignItems: 'left', padding: '24px' }}
        >
            <Box
                component={'div'}
                sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            >
                <Typography variant="h5">
                    {' '}
                    {t('ANALYTICS_SECTION.PROCEDURE')}{' '}
                    <Chip
                        label={totalDocuments}
                        sx={{ borderRadius: '8px', fontSize: 18 }}
                    />
                </Typography>
                <Box
                    component={'div'}
                    sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}
                >
                    <SearchBar
                        onChangeHandler={handleSearchTextChange}
                        value={searchPayload.search}
                    />
                    <SelectSort
                        value={sortBy}
                        onChange={handleSortChange}
                        sortOptions={[
                            { value: 'Date Added', label: 'WI_LIST.DATE_ADDED' },
                            { value: 'Alphabetical', label: 'WI_LIST.ALPHABETICAL' },
                        ]}
                    />
                    <FilterButton
                        selectedFilters={selectedFilters}
                        onApplyFilterClick={(data) => setSelectedFilters(data)}
                        onFilterClose={() => {}}
                        filterOptions={[
                            {
                                filter: 'status',
                                filterValues: Object.keys(PROCEDURE_STATUS).map((each) => t('ANALYTICS_SECTION.' + each)),
                            },
                            {
                                filter: 'view_by',
                                filterValues: Object.values(ENTITY).map((each) => t('ANALYTICS_SECTION.' + each)),
                            },
                        ]}
                    />
                    <DateRangeSelector
                        withBorder={true}
                        sx={{}} // override the default css
                        dateRange={dateRange}
                        setDateRange={setDateRange}
                        selectedDateRangeOption={selectedDateRangeOption}
                        setSelectedDateRangeOption={setSelectedDateRangeOption}
                        showAllTimeOption={true}
                    />
                </Box>
            </Box>
            <AppliedFilterList
                appliedFilterList={selectedFilters}
                onRemoveFilterClick={handleRemoveFilterClick}
                isTrainer={true}
                sx={{ padding: '16px 0 16px 0' }}
            />
            {/* {JSON.stringify(searchPayload)}; */}
            {isLoading ? (
                <TableEmptyState />
            ) : !procedureList.length ? (
                <ProcedureListEmptyState />
            ) : (
                <Box
                    component={'div'}
                    sx={{
                        width: '100%',
                        ' table tbody :not(tr:hover) .show-on-hover': {
                            display: 'none',
                        },
                        ' table tbody tr:hover .show-on-hover': {
                            display: 'inline-flex',
                        },
                        ' table': {
                            ' th:nth-child(1)': { width: '30%' },
                            ' th:nth-child(2)': { width: '10%' },
                            ' th:nth-child(3)': { width: '30%' },
                            ' th:nth-child(4)': { width: '30%' },
                        },
                    }}
                >
                    <InfiniteScroll
                        dataLength={procedureList.length}
                        next={loadNext}
                        hasMore={currentPage < totalPages}
                        loader={<p>{totalDocuments < 1 ? t('COMMON.NO_FOLDERS_OR_WORK_INSTRUCTIONS_TO_DISPLAY') : currentPage < totalPages ? t('COMMON.LOADING') : null}</p>}
                        scrollableTarget={props.scrollableDivId}
                    >
                        <Paper elevation={0}>
                            <Box component="div">
                                <TableContainer
                                    columns={columns}
                                    data={tableData}
                                    // rowProps={(row) => ({
                                    //     selected: checkIsRowSelected ? checkIsRowSelected(row) : null,
                                    //     disabled: checkIsRowDisabled ? checkIsRowDisabled(row) : null,
                                    // })}
                                />
                            </Box>
                        </Paper>
                    </InfiniteScroll>
                </Box>
            )}
        </Box>
    );
};

export default ProcedureList;

const ProcedureListEmptyState = () => {
    return <>No record found</>;
};

const TableEmptyState = () => {
    return (
        <Box component={'div'}>
            <Skeleton
                width={'100%'}
                height={'100px'}
            />
            <Skeleton
                width={'100%'}
                height={'100px'}
            />
            <Skeleton
                width={'100%'}
                height={'100px'}
            />
            <Skeleton
                width={'100%'}
                height={'100px'}
            />
            <Skeleton
                width={'100%'}
                height={'100px'}
            />
        </Box>
    );
};
