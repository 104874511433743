import { Box, Button, Typography, useTheme } from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

export const AppliedFilterBar = ({ appliedFilterList, applyFilters, setAppliedFilterList, isTrainer }) => {
    const removeFilter = (filter, value) => {
        let newArr = [...appliedFilterList];
        newArr.map((item) => {
            if (item.filter === filter && item.value === value) {
                item.applied = false;
            }
        });
        applyFilters(newArr);
        setAppliedFilterList(newArr);
    };

    const theme = useTheme();
    return (
        <Box
            component="div"
            sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', padding: '0rem 0rem 0rem 2rem', maxWidth: '100%' }}
        >
            {appliedFilterList.map((item, i) => {
                if (item.applied) {
                    return (
                        <Box
                            key={i}
                            component="div"
                            border={'1px solid' + theme.palette.primary.main + '1c'}
                            sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row', borderRadius: '8px', maxWidth: '100%', padding: '0px 4px', margin: '0px 10px 0px 0px' }}
                        >
                            <Typography
                                className="filter-text"
                                key={item}
                            >
                                {isTrainer && (item.value as string)}
                            </Typography>
                            <Button
                                sx={{ minWidth: 0 }}
                                onClick={() => removeFilter(item.filter, item.value)}
                            >
                                <HighlightOffIcon fontSize="small" />
                            </Button>
                        </Box>
                    );
                }
            })}
        </Box>
    );
};
