import { Box, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { VISIT_TYPE } from '../VisitsHistory/constants';
import { useTranslation } from 'react-i18next';

interface IVisitCountRecord {
    label: string;
    count: number;
    procedureName?: string;
    workInstructionName?: string;
    id?: string;
    email?: string;
    visitType?: VISIT_TYPE;
}
interface VisitCountProps {
    records: IVisitCountRecord[];
    onRecordClick?: (id: string, name: string, index: number) => void;
    barColor?: string;
    visitType?: VISIT_TYPE;
    setPageLoading?: any;
    pageLoading?: boolean;
    searchPayload?: any;
    setSearchPayload?: (payload: any) => void;
    hasMore?: boolean;
    currentPage?: number;
    selected?: number;
}

const VisitCount: React.FC<VisitCountProps> = ({ records, onRecordClick, visitType, barColor, ...props }) => {
    const { t } = useTranslation();
    const maxCount = Math.max(...records.map((record) => record.count));
    const targetRef = useRef(null);
    const [isOutOfView, setOutOfView] = useState<boolean>(false);

    useEffect(() => {
        if (props.pageLoading) return;
        const targetElement = targetRef.current;
        const observerOptions = {
            root: null,
            rootMargin: '0px',
            threshold: 0,
        };

        const observerCallback = (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    if (isOutOfView && props.hasMore) {
                        props.setPageLoading(true);
                        setOutOfView(false);
                        loadNext();
                    }
                } else {
                    setOutOfView(true);
                }
            });
        };
        const observer = new IntersectionObserver(observerCallback, observerOptions);
        if (targetElement) {
            observer.observe(targetElement);
        }
        return () => {
            if (targetElement) {
                observer.unobserve(targetElement);
            }
        };
    }, [isOutOfView, props.pageLoading]);
    const loadNext = () => {
        const payload = {
            ...props.searchPayload,
            currPage: (props.currentPage ?? 1) + 1,
        };
        props.setSearchPayload && props.setSearchPayload(payload);
    };
    return (
        <>
            <Box
                id="visit_count_record"
                component={'div'}
                sx={{ padding: '0px', overflowY: 'auto' }}
            >
                <>
                    {records.map((record, index) => (
                        <VisitCountRecord
                            key={index}
                            label={record.label}
                            count={record.count}
                            workInstructionName={record.workInstructionName}
                            email={record.email}
                            id={record.id}
                            visitType={visitType}
                            maxCount={maxCount}
                            barColor={barColor}
                            onRecordClick={onRecordClick}
                            index={index}
                            selected={index === props.selected}
                        />
                    ))}
                    {props.pageLoading && <Typography sx={{ textAlign: 'center', margin: 'auto', width: '100%', fontWeight: '400' }}>{t('COMMON.LOADING')}</Typography>}
                    <div
                        ref={targetRef}
                        style={{ height: '5px', width: '100%', background: 'white' }}
                    >
                        {''}
                    </div>
                </>
            </Box>
        </>
    );
};

interface VisitCountRecordProps extends IVisitCountRecord {
    maxCount: number;
    onRecordClick?: (id: string, name: string, index: number) => void;
    barColor?: string;
    index: number;
    selected: boolean;
}

const VisitCountRecord: React.FC<VisitCountRecordProps> = (props) => {
    const { label, count, maxCount, onRecordClick, barColor, workInstructionName, email, visitType, id} = props;
    const [width, setWidth] = useState(0);
    useEffect(() => {
        const newWidth = (count / maxCount) * 100;
        setWidth(newWidth);
    }, [count, maxCount]);

    const onclick = (id, name) => {
        onRecordClick && onRecordClick(id, name, props.index);
    };
    return (
        <>
            {/* {label && !label.includes("undefined") && label.trim().length > 0 && ( */}
            { label && !label.includes("undefined") && typeof label === "string" && label.trim().length > 0 &&  (
                <Box
                    component={'div'}
                    sx={{ display: 'flex', flexDirection: 'column', padding: '8px 16px', cursor: 'pointer', background: props.selected ? '#D4E8FF' : '#ffffff', borderRadius: '12px' }}
                    onClick={() => onclick(id, label)}
                >
                    <Box
                        component={'div'}
                        sx={{ display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'space-between' }}
                    >
                        <Box
                            component={'div'}
                            sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', maxWidth: '80%', fontWeight: 400, fontSize: '14px', opacity: 1, color: '#1C1D1F' }}
                        >
                            <Tooltip title={visitType === VISIT_TYPE.USER_VISIT ? email : label}>
                                <Box component={'span'}>{label}</Box>
                            </Tooltip>
                        </Box>
                    </Box>

                    <Box
                        component={'div'}
                        sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}
                    >
                        <Box
                            component={'div'}
                            sx={{ height: '8px', width: `${width}%`, backgroundColor: barColor, transition: 'width 2s' }}
                        ></Box>
                        <Box
                            component={'div'}
                            sx={{ fontWeight: 600, fontSize: '16px' }}
                        >
                            {count}
                        </Box>
                    </Box>
                    {visitType === VISIT_TYPE.PROCEDURE_VISIT && (
                        <Box
                            component={'span'}
                            sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', maxWidth: '80%', fontWeight: 400, fontSize: '11px', opacity: 0.6, color: '#1C1D1F' }}
                        >
                            {workInstructionName}
                        </Box>
                    )}
                </Box>
            )}
        </>
    );
};

export default VisitCount;
