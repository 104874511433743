import { Autocomplete, Backdrop, Box, Button, FormControl, FormHelperText, FormLabel, InputLabel, MenuItem, Select, Skeleton, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BasicDialog } from '../BasicDialog/BasicDialog';
import TrainerService from '../../../../services/trainer/TrainerService';
import LanguageService from '../../../../services/Language/LanguageService';
import { Formik, Form, Field, ErrorMessage, useFormik, isString } from 'formik';
import * as Yup from 'yup';
import Loading from '../../../Loading/Loading';
import languages from './Languages';
import useCurrentUser from '../../../../hooks/useCurrentUser';

export const LanguageSupport = (props) => {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<any>([]);
  const { refreshProcedure, setRefreshProcedure } = useCurrentUser();

  const { t } = useTranslation();

  const validationSchema = Yup.object({
    selectedOption: Yup.object({ code: Yup.string().required(t('PROCEDURE.PLEASE_SELECT_OPTION'))}),
    otherLanguage: Yup.string().when('selectedOption', {
      is: 'others',
      then: Yup.string().required(t('PROCEDURE.PLEASE_ENTER_LANGUAGE')),
    }),
  });

  const cloneProcedure = (values) => {
    const payload = {
      procedureId: props.procedureId,
      language: values.selectedOption,
    };
    setLoading(true);
    TrainerService.cloneProcedure(payload)
      .then((response) => {
        setRefreshProcedure(!refreshProcedure);
        setTimeout(() => setLoading(false), 2000);
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
      })
      .finally(()=>{
        props.closeDialog();
      });
  };

  const { values, errors, touched, handleChange, handleBlur, submitForm, setFieldValue } = useFormik({
    initialValues: {
      selectedOption: { code: '', name: '' },
      otherLanguage: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      cloneProcedure(values);
    },
  });

  type AutoCompleteFieldProps<T> = {
    selectValue: keyof T;
    options: T[];
    onChange: any;
  };

  const AutoCompleteField = <T extends {}>({ selectValue, options, onChange }: AutoCompleteFieldProps<T>): React.ReactElement => {
    const { t } = useTranslation();

    return (
      <Autocomplete
        id="selectedOption"
        autoHighlight
        options={languages.sort((a, b) => -b.name.localeCompare(a.name))}
        getOptionLabel={(option) => option.name || ''}
        value={values.selectedOption}
        onChange={(event, value) => {
          setFieldValue('selectedOption', value ?? '');
        }}
        renderInput={(params) => (
          <Box component="div">
            <TextField {...params} value={values.selectedOption.name} placeholder={t('PROCEDURE.ENTER_HERE')} error={Boolean(touched?.selectedOption?.name && errors?.selectedOption?.name)} />
          </Box>
        )}
      />
    );
  };

  return (
    <>
      {loading && (
        <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 100 }} open={loading}>
          <Loading />
        </Backdrop>
      )}
      <BasicDialog
        title={t('PROCEDURE.ADD_LANGUAGE_SUPPORT')}
        onClose={() => {
          props.closeDialog();
        }}
        onOpen={props.openDialog}
        actions={
          <>
            <Button
              onClick={() => {
                submitForm();
              }}
              variant="contained"
              size="small"
              type="submit"
            >
              {t('BUTTON.ADD') as string}
            </Button>
          </>
        }
      >
        <form>
          <FormControl sx={{ width: '100%' }}>
            <FormLabel sx={{ fontSize: '12px', margin: '8px 0px' }}>{t('PROCEDURE.SELECT_LANGUAGE') as string}</FormLabel>
            <AutoCompleteField<any> options={languages} selectValue="code" onChange={handleChange} />
            <FormHelperText error>{errors?.selectedOption?.code?.toString()}</FormHelperText>
          </FormControl>
        </form>
      </BasicDialog>
    </>
  );
};
