import { Box, Typography } from '@mui/material';

import { Bar, BarChart, CartesianGrid, Cell, LabelList, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import { Feedback } from '../../ProcedureReports/Feedback';
import { ProductAffected } from '../../../Icons/CdsIcons';
import { Icon } from '@mui/material';
import '../../Analytics.scss';
import { useTranslation } from 'react-i18next';

export const ProceduresAssigned = ({ data }) => {
  const {t} = useTranslation();
  
  const renderCustomizedLabel = (props) => {
    const { x, y, width, height, value, name } = props;
  

    const fireOffset = value.toString().length < 5;
    const offset = fireOffset ? -40 : 5;

    return (
      <text style={{ fontSize: '14px', color: 'black', marginLeft: '100px', paddingLeft: '100px' }} x={x + width - offset - 30} y={y + height - 12} fill={fireOffset ? 'black' : 'black'}>
        {name + ': ' + value} {t("ANALYTICS_SECTION.PROCEDURE")}
      </text>
    );
  };

  const renderCustomizedLabelName = (props) => {
    const { x, y, width, height, value } = props;

    return (
      <text x={x} y={y} dy={-10} textAnchor="start" style={{ fontSize: '12px', marginBottom: '12px' }}>
        {value}
      </text>
    );
  };

  interface CustomTooltipProps {
    active?: any;
    payload?: any;
    label?: any;
  }

  const CustomTooltip = ({ active, payload, label }: CustomTooltipProps) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label">
            {`${payload[0]?.payload?.name}: `}
            {`${payload[0]?.payload?.value} ${t("ANALYTICS_SECTION.PROCEDURE")}`}
          </p>
        </div>
      );
    }

    return null;
  };

  const barColors = [
    { start: '#59BFAB', end: '#ACE8DA' },
    { start: '#DAE187', end: '#EAEDCB' },
    { start: '#E47D62', end: '#E9B9AD' },
  ];

  return (
    <Box component="div" sx={{ display: 'flex', flexGrow: 1, flexDirection: 'column', height: '100%' }}>
      <Box component="div" className="analytics-procedure-icon" sx={{ display: 'flex', flexGrow: 1, flexDirection: 'row', justifyContent: 'space-between', mb: '30px' }}>
        {data && <Feedback title={t("ANALYTICS_SECTION.PROCEDURES_ASSIGNED")} value={data[0]?.value + data[1]?.value + data[2]?.value} />}
        <Box component="div" className="product-affected-icon">
          <ProductAffected />
        </Box>
      </Box>

      <ResponsiveContainer width={'100%'}>
        <BarChart className={'outline'} data={data} layout="vertical" barCategoryGap={1} barSize={36} margin={{ right: 190, top: 0 }}>
          <XAxis type="number" hide />
          <YAxis type="category" dataKey="name" tickLine={true} axisLine={true} tick={false} hide={true} />
          <defs>
            {barColors?.map((color, index) => (
              <linearGradient key={index} id={`colorUv${index}`} x1="0" y1="0" x2="100%" y2="0" spreadMethod="reflect">
                <stop offset="0" stopColor={color.start} />
                <stop offset="1" stopColor={color.end} />
              </linearGradient>
            ))}
          </defs>
          <Bar isAnimationActive={false} dataKey="value" radius={[0, 6, 6, 0]}>
            {data?.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={`url(#colorUv${index})`} />
            ))}
            <LabelList dataKey="value" position="right" content={renderCustomizedLabel} fill="black" />
            {/* <LabelList dataKey="name" content={renderCustomizedLabelName} fill="black" /> */}
          </Bar>
          <Tooltip cursor={{ fill: 'transparent' }} wrapperStyle={{ outline: 'none' }} content={<CustomTooltip />} />
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
};
