import * as React from 'react';
import Button from '@mui/material/Button';
import { styled, useTheme } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { useEffect } from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useMsal } from '@azure/msal-react';
import { AccountCircle } from '@mui/icons-material';
import './publishDialog.scss';
import TrainerService from '../../../../services/trainer/TrainerService';
import ApprovalDialog from '../ApprovalDialog/ApprovalDialog';
import SuccessDialog from '../SuccessDialog/SuccessDialog';
import { Toast } from '../../../../components/Notifications/Notification';
import Loading from '../../../Loading/Loading';
import { getMessage } from '../../../../shared/utils/message-helpers';
import { Autocomplete, TextField, FormControl, FormLabel, Skeleton, Select, MenuItem } from '@mui/material';
import { trackEvent } from '../../../../App';
import { AmplitudeEvents } from '../../../../shared/enums';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import VersionDialog from '../VersionDialog/versionDialog';
import { useAppSelector } from '../../../../hooks/redux';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box component="div" sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-container': {
    '& .MuiPaper-root': {
      minWidth: '400px', // Set your width here
    },
  },
  '& .MuiDialogContent-root': {
    paddingBottom: '0px',
  },
  '& .MuiDialogActions-root': {
    padding: '0px 24px 24px 24px',
  },
  '& .MuiDialogTitle-root': {
    margin: ' 12px 12px 0px 12px !important',
  },
}));

interface Props {
  openDialog: boolean;
  closeDialog: () => void;
  procedure: any;
}

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle className="remove-margin" sx={{ m: 4, p: 4 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export const isString = (item: any): item is string => {
  return typeof item === 'string';
};

type AutoCompleteFieldProps<T> = {
  selectValue: keyof T;
  options: T[];
  onChange: any;
};

const AutoCompleteField = <T extends {}>({ selectValue, options, onChange }: AutoCompleteFieldProps<T>): React.ReactElement => {
  const { t } = useTranslation();

  return (
    <Autocomplete
      id="manager-auto-complete"
      options={options}
      getOptionLabel={(option: any) => {
        const val = option[selectValue];
        return isString(val) ? val : '';
      }}
      onChange={onChange}
      renderInput={(params) => (
        <Box component="div">
          <TextField {...params} placeholder={t('PROCEDURE.ENTER_HERE')} />
        </Box>
      )}
    />
  );
};

let currentUserEmail: any = {};
export default function PublishDialog(props: any) {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [approvalManagers, setApprovalManagers] = React.useState<Array<any>>([]);
  const [escalationManager, setEscalationManager] = React.useState<string>('');
  const [escalationManagers, setEscalationManagers] = React.useState<Array<string>>([]);
  const [comments, setComments] = React.useState('');
  const [currentManager, setCurrentManager] = React.useState<any>();
  const [showApprovalDialog, setShowapprovalDialog] = React.useState<boolean>(false);
  const [showSuccessDialog, setShowSuccessDialog] = React.useState<boolean>(false);
  const [managersLoading, setManagersLoading] = React.useState<boolean>(false);
  const [escalationDurationUnit, setEscalationDurationUnit] = React.useState<string>('days');
  const [duration, setDuration] = React.useState<number>(7);
  const [showVersionDialog, setShowVersionDialog] = React.useState<boolean>(false);

  const theme = useTheme();
  
  const {currentUser} = useAppSelector((state) => state.app);
  const name = currentUser.userFullName;

  const tabs = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    setOpen(props.openDialog);
  }, [props.openDialog]);

  const handleClose = () => {
    props.closeDialog();
    setOpen(false);
  };

  const handleManagerApprove = () => {
    setLoading(true);

    const payload = {
      userId: currentManager.id,
      updateType: props.updateType,
      comments: props.releaseNotes + '\n' + comments,
      version: props.version || props.procedure.version,
      escalationManager: escalationManager !== '' ? escalationManager : undefined,
      publishToIntegration: props.integrationSystem,
      escalationDate:
        duration && escalationManager !== ''
          ? moment()
              .add(duration, escalationDurationUnit as moment.unitOfTime.DurationConstructor)
              .unix()
          : undefined,
    };

    TrainerService.sendManagerApproval(props.procedure.procedureId, payload)
      .then(async (response) => {
        trackEvent(AmplitudeEvents.PUBLISH_PROCEDURE, {
          procedure_id: props.procedure.procedureId,
          published_By: localStorage.getItem('email'),
          approval_type: 'manager',
          approving_manager: currentManager.email,
        });
        if (response.data.message === 'This version of procedure is already approved') {
          const res = await props.publishVersion();
          if (res.status === 200) {
            setShowapprovalDialog(true);
            setOpen(false);
          } else {
            Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(res.data.messageCode, { ns: 'error' }) || res.data.errorMessage);
          }
        } else {
          setOpen(false);
          setShowSuccessDialog(true);
        }
        setLoading(false);
      })
      .catch(({ response }) => {
        setLoading(false);
        Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
      });
  };

  const handleApprove = async () => {
    setLoading(true);

    const payload = {
      updateType: props.updateType,
      comments: props.releaseNotes,
      version: props.version || props.procedure.version,
    };

    TrainerService.selfApproveProcedure(props.procedure.procedureId, payload)
      .then(async (response) => {
        const res = await props.publishVersion();
        setLoading(false);
        if (res.status === 200) {
          trackEvent(AmplitudeEvents.PUBLISH_PROCEDURE, {
            procedure_id: props.procedure.procedureId,
            published_By: localStorage.getItem('email'),
            approval_type: 'self',
            approving_manager: localStorage.getItem('email'),
          });
          if(res.data.message.toLowerCase().includes('user not found')){
            Toast.error('Publish to ETQ failed', 'User is not present in ETQ system. Please create an account');
          }
          if(res.data.message.includes('Publish to integration system failed due to error :')){
            Toast.error(t('INTEGRATION_PUBLISH_FAILED', { ns: 'error' }), res.data.message.substring(res.data.message.indexOf(':') + 1));
          }
          setShowapprovalDialog(true);
          setOpen(false);
        } else {
          Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(res.data.messageCode, { ns: 'error' }) || res.data.errorMessage);
        }
      })
      .catch(({ response }) => {
        setLoading(false);
        handleClose();
        Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
      });
  };

  const closeSuccessDialog = () => {
    props.closeDialog();
    setShowSuccessDialog(false);
  };

  useEffect(() => {
    setManagersLoading(true);
    TrainerService.getManagerList(localStorage.getItem('personaId')).then((response) => {
      var currentTrainer = JSON.parse(localStorage.getItem('userStats') || '').userId;
      const filteredTrainers = response.data.data.filter((Trainer) => {
        return Trainer.email.toLowerCase() !== currentTrainer.toLowerCase();
      });
      currentUserEmail = response.data.data.find((Trainer) => Trainer.email.toLowerCase() === currentTrainer.toLowerCase());
      setApprovalManagers(filteredTrainers);
      setManagersLoading(false);
    });
  }, []);

  useEffect(() => {
    if (currentManager) {
      const escalationMgrs = approvalManagers.filter((manager) => manager.id !== currentManager.id);
      if (currentUserEmail) escalationMgrs.push(currentUserEmail);
      setEscalationManagers(escalationMgrs);
    }
  }, [currentManager]);

  const onInputChange = (e: React.ChangeEvent, value: any): void => {
    setCurrentManager(value);
  };

  const handleEscalationManagerChange = (e: React.ChangeEvent, value: any): void => {
    setEscalationManager(value ? value.id : undefined);
  };

  const handleComments = () => (event) => {
    setComments(event.target.value);
  };

  const handleDurationUnitChange = (event) => {
    const selectedValue = event.target.value;
    setEscalationDurationUnit(event.target.value);
  };

  const handleDurationChange = (event) => {
    const value = parseInt(event.target.value);
    setDuration(value);
  };
  const closeDialogHandler = () => {
    setShowVersionDialog(false);
  };

  const handleBackClick = () => {

    setOpen(false)
    setShowVersionDialog(true)
  };

  const { t } = useTranslation();

  return (
    <div>
      <VersionDialog openDialog={showVersionDialog} closeDialog={closeDialogHandler} procedure={props.procedure} style={{ padding: '24px' }} />
      <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        {loading && <Loading />}
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          {t('PROCEDURE.PUBLISH_BY_APPROVAL')}
        </BootstrapDialogTitle>
        <DialogContent>
          <Box component="div">
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab
                sx={{
                  color: theme.palette.primary.main,
                  opacity: '0.5',
                  '&.Mui-selected': {
                    color: theme.palette.primary.main,
                    opacity: '1',
                  },
                }}
                label={t('PROCEDURE.SELF_APPROVAL')}
                {...tabs(0)}
              />
              <Tab
                sx={{
                  color: theme.palette.primary.main,
                  opacity: '0.5',
                  '&.Mui-selected': {
                    color: theme.palette.primary.main,
                    opacity: '1',
                  },
                }}
                label={t('PROCEDURE.SEND_FOR_APPROVAL')}
                {...tabs(1)}
              />
            </Tabs>
            <TabPanel value={value} index={0}>
              <Box
                component="div"
                sx={{
                  fontSize: '12px',
                  color: '#1C1D1F',
                  fontWeight: '500',
                  paddingBottom: '.8rem',
                }}
              >
                {t('PROCEDURE.APPROVING_MANAGER')}
              </Box>
              <Box
                component="div"
                sx={{
                  padding: '.5rem',
                  backgroundColor: 'rgba(217, 217, 217, .3)',
                  borderRadius: '8px',
                }}
              >
                <AccountCircle className="align-text" /> <Box component="span"> {name} </Box>
              </Box>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Box
                component="div"
                sx={{
                  fontSize: '12px',
                  color: '#1C1D1F',
                  fontWeight: '500',
                  paddingBottom: '.8rem',
                }}
              >
                {t('PROCEDURE.APPROVING_MANAGER')}
              </Box>
              {!managersLoading ? <AutoCompleteField<any> options={approvalManagers} selectValue="email" onChange={onInputChange} /> : <Skeleton sx={{ height: '36px', width: '100%' }} />}
              <Box
                component="div"
                sx={{
                  fontSize: '12px',
                  color: '#1C1D1F',
                  fontWeight: '500',
                  padding: '1.5rem 0rem 0.8rem',
                  textTransform: 'uppercase',
                }}
              >
                {`${t('NAVBAR.ESCALATION_INTERVAL')} (${t('COMMON.OPTIONAL')})`}
              </Box>
              <Box component="div" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <TextField type="number" autoComplete="off" value={duration} sx={{ width: '45%', height: '44px', borderRadius: '8px' }} onChange={handleDurationChange} InputProps={{ inputProps: { min: 1, sx: { height: '40px', boxSizing: 'border-box' } } }} />
                <Select value={escalationDurationUnit} onChange={handleDurationUnitChange} displayEmpty inputProps={{ 'aria-label': 'Without label' }} sx={{ width: '45%', border: '0.5px', borderRadius: '8px', height: '40px', paddingLeft: '12px' }}>
                  <MenuItem value="years">{t('NAVBAR.YEARS')}</MenuItem>
                  <MenuItem value="months">{t('NAVBAR.MONTHS')}</MenuItem>
                  <MenuItem value="days">{t('NAVBAR.DAYS')}</MenuItem>
                </Select>
              </Box>
              <Box
                component="div"
                sx={{
                  fontSize: '12px',
                  color: '#1C1D1F',
                  fontWeight: '500',
                  padding: '1.5rem 0rem 0.8rem',
                  textTransform: 'uppercase',
                }}
              >
                {`${t('NAVBAR.ESCALATION_EMAIL')} (${t('COMMON.OPTIONAL')})`}
              </Box>
              {!managersLoading ? <AutoCompleteField<any> options={escalationManagers} selectValue="email" onChange={handleEscalationManagerChange} /> : <Skeleton sx={{ height: '36px', width: '100%' }} />}

              <FormControl sx={{ width: '100%' }}>
                <FormLabel
                  sx={{
                    fontSize: '12px',
                    color: '#1C1D1F',
                    fontWeight: '500',
                    padding: '1.5rem 0rem 0.8rem',
                    textTransform: 'uppercase',
                  }}
                >
                  {t('PROCEDURE.COMMENTS')}
                </FormLabel>
                <TextField
                  id="outlined-basic"
                  multiline
                  placeholder={t('PROCEDURE.FIRST_VERSION')}
                  FormHelperTextProps={{
                    style: {
                      display: 'flex',
                      justifyContent: 'end',
                    },
                  }}
                  helperText={`${500 - comments.length} ${t('COMMON.CHARACTERS_LEFT')}`}
                  onChange={handleComments()}
                  sx={{
                    width: '100% !important',
                    margin: '0px',
                  }}
                  inputProps={{
                    maxLength: 500,
                    style: {
                      height: '90px',
                      paddingLeft: '10px',
                    },
                  }}
                  variant="outlined"
                />
              </FormControl>
            </TabPanel>
          </Box>
        </DialogContent>
        <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button variant="outlined" onClick={handleBackClick}>
              {' '}
              {t('BUTTON.BACK')}
            </Button>
          <Button variant="contained" type="submit" onClick={value === 0 ? handleApprove : handleManagerApprove} disabled={value === 1 && !currentManager}>
            {' '}
            {value === 0 ? t('BUTTON.APPROVE') : t('BUTTON.SEND')}{' '}
          </Button>
        </DialogActions>
      </BootstrapDialog>
      {showApprovalDialog && <ApprovalDialog openApprovalDialog={showApprovalDialog} />}
      {showSuccessDialog && (
        <SuccessDialog message={t('PROCEDURE.APPROVE_SUCCESS')} closeDialog={closeSuccessDialog}>
          {currentManager?.firstName && (
            <Typography variant="caption" sx={{ width: '100%', textAlign: 'center', display: 'inline-block' }} gutterBottom>
              {t('COMMON.TO')} {currentManager?.firstName}
            </Typography>
          )}
        </SuccessDialog>
      )}
    </div>
  );
}
