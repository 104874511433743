import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { AssemblyState } from '../../../../entities/AssemblyState';
import { IUserStats, IUserStatsNext } from '../../../../entities/Trainee/MockTrainee';
import { CardQuestion } from '../../../../entities/Trainee/WorkInstructions';
import CanvasControls from '../Controlls/CanvasControls/CanvasControls';
import InstructionPlayer from '../Controlls/InstructionPlayer/InstructionPlayer';
import StepInstructions from '../Controlls/StepInstructions/StepInstructions';
import './AssemblyBar.scss';
import TutorialService from '../../../../services/trainee/TraineeService';
import { useLocation, useParams } from 'react-router-dom';
import { useCanvasState } from '../../../../state/canvasState';
import usePpeList from '../../../../hooks/ppeList';
import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { IProcedure } from '../../../../entities/Analytics/AnalyticsProcedure';

interface Props {
    zoom: (zoomLevel: number) => void;
    assemblyState: AssemblyState;
    onAssemblyStateChange: (assemblyState: AssemblyState) => void;
    currentZoomLevel: number;
    timer: number;
    isTrainer?: boolean;
    timerLoading?: any;
    isViewComment?: boolean;
    procedure?: IProcedure | any;
    stepIndex?: number;
}

const AssemblyBar: React.FC<Props> = (props) => {
    const canvasState = useCanvasState();
    const { workInstructionId } = useParams<string>();
    const [assemblyStateLocal, setAssemblyStateLocal] = useState<AssemblyState>(props.assemblyState);
    const [userStatsData, setUserStatsData] = useState<IUserStats>();
    const [currentStepHasLinkedAnimation, setCurrentStepHasLinkedAnimation] = useState<boolean>(false);
    const { showIntro, setShowIntro, introStep, setIntroStep } = usePpeList();
    const getUserStatsData = (): void => {
        if (workInstructionId && !props.isTrainer && !props.isViewComment) {
            TutorialService.getUserStats(workInstructionId, canvasState.procedureId).then((e) => {
                setStateData(e);
            });
        }
    };

    useEffect(() => {
        // quick fix for CZ-5704
        if (props.assemblyState.totalSteps !== assemblyStateLocal.totalSteps) {
            let state = JSON.parse(JSON.stringify(assemblyStateLocal));
            state.totalSteps = props.assemblyState.totalSteps;
            setAssemblyStateLocal(state);
        }
    }, [props.assemblyState.totalSteps]);

    const setStateData = (e: any) => {
        const {
            data: {
                data: [userStats],
            },
        } = e;
        if (userStats) {
            // Set current step from user stats
            setAssemblyStateLocal((state) => {
                return {
                    ...state,
                    procedureId: canvasState.procedureId,
                    currentStep: userStats.currentStep,
                    userStats: userStats,
                };
            });
            setUserStatsData(userStats);
        }
    };
    const { search: searchQuery } = useLocation();
    const query = new URLSearchParams(searchQuery);
    const stepNo = query.get('stepNo');

    // Initialize
    useEffect(() => {
        if (!props.isTrainer && !props.isViewComment) {
            getUserStatsData();
        }
        if (props.isViewComment) {
            setShowIntro(false);
            setAssemblyStateLocal((state) => {
                return {
                    ...state,
                    currentStep: Number(stepNo) + 1,
                };
            });
        }

        return () => {};
    }, []);

    useEffect(() => {
        props.onAssemblyStateChange(assemblyStateLocal);
    }, [assemblyStateLocal]);

    const updateQuestion = (question: CardQuestion) => {
        var stats = { ...assemblyStateLocal.userStats };
        if (!stats.questions) {
            stats.questions = [question];
        } else {
            stats.questions = stats.questions.filter((x) => x.questionUUID !== question.questionUUID);
            stats.questions.push(question);
        }

        setAssemblyStateLocal((state) => {
            return {
                ...state,
                userStats: { ...stats },
            };
        });

        var currentTime = props.timer;
        const data: IUserStatsNext = {
            currentStep: assemblyStateLocal.currentStep,
            completedSteps: assemblyStateLocal.currentStep - 1,
            completionTime: currentTime.toString(),
            questions: stats.questions,
        };
        if (workInstructionId && !props.isTrainer && !props.isViewComment) {
            TutorialService.nextUserStats(workInstructionId, canvasState.procedureId, data).then((e) => {});
        }
    };

    const step = (nextStep: boolean) => {
        // useCanvasState.setState({ currentlyPlaying: true })

        if (!showIntro && !confirmRequiredQuestionsHaveBeenAnswered() && nextStep) {
            let snackMessage: string = `${t('3D_VIEWER.REQUIRED_QUESTIONS_MUST_BE_ANSWERED')}`;
            enqueueSnackbar(snackMessage, { variant: 'error' });
            return;
        }

        let currentStep: number;
        let currentTime: number;
        currentStep = assemblyStateLocal.currentStep;
        if (nextStep) {
            // Go to Next Step
            if (showIntro && introStep < 2) {
                setIntroStep(introStep + 1);
            } else {
                if (showIntro) {
                    setShowIntro(false);
                } else {
                    currentStep += 1;
                }
            }
            if (canvasState.changeMode === 'explode') {
                useCanvasState.setState({ changeMode: '' });
            }
        } else {
            // Go to Previous Step
            if (currentStep === 1) {
                if (showIntro) {
                    setIntroStep(introStep - 1);
                } else {
                    setShowIntro(true);
                }
            } else {
                currentStep -= 1;
            }
            if (canvasState.changeMode === 'explode') {
                useCanvasState.setState({ changeMode: '' });
            }
        }
        currentTime = props.timer;
        setAssemblyStateLocal((state) => {
            return {
                ...state,
                currentStep: currentStep,
                currentTime: currentTime,
            };
        });
        // Save user stats
        const data: IUserStatsNext = {
            currentStep: currentStep,
            completedSteps: currentStep - 1,
            completionTime: currentTime.toString(),
        };
        saveUserStats(data);
    };

    const confirmRequiredQuestionsHaveBeenAnswered: () => boolean = () => {
        let returnValue: boolean = true;
        if (canvasState.workInstructions?.cardsData[assemblyStateLocal.currentStep - 1].questions.length === 0) return returnValue;
        canvasState.workInstructions?.cardsData[assemblyStateLocal.currentStep - 1].questions.forEach((question) => {
            if (question.required) {
                let answeredQuestion = assemblyStateLocal.userStats.questions?.find((answeredQuestion) => answeredQuestion.questionUUID === question.questionUUID);
                if (!answeredQuestion || (answeredQuestion?.answer === null && answeredQuestion?.answer === undefined)) {
                    returnValue = false;
                }
            }
        });
        return returnValue;
    };

    const saveUserStats = (data: IUserStatsNext) => {
        if (workInstructionId && !props.isTrainer && !props.isViewComment) {
            TutorialService.nextUserStats(workInstructionId, canvasState.procedureId, data).then((e) => {
                setStateData(e);
            });
        }
    };

    const stepSelected = (step: number) => {
        if (step === assemblyStateLocal.currentStep) {
            return;
        }

        if (!showIntro && !confirmRequiredQuestionsHaveBeenAnswered()) {
            let snackMessage: string = `${t('3D_VIEWER.REQUIRED_QUESTIONS_MUST_BE_ANSWERED')}`;
            enqueueSnackbar(snackMessage, { variant: 'error' });
            return;
        }
        // useCanvasState.setState({ currentlyPlaying: true })

        const completedStep = assemblyStateLocal.currentStep;
        const currentTime = props.timer;

        setAssemblyStateLocal((state) => {
            return {
                ...state,
                currentStep: step,
                currentTime: currentTime,
            };
        });

        // Save user stats
        const data: IUserStatsNext = {
            currentStep: step,
            completedSteps: completedStep,
            completionTime: currentTime.toString(),
        };
        saveUserStats(data);
    };

    //   canvasState.workInstructions?.cardsData[assemblyStateLocal.currentStep - 1].is2dStep == false ? 'canvas-control-container-center' : 'canvas-control-container-center--hidden'

    useEffect(() => {
        if (canvasState.workInstructions?.cardsData[assemblyStateLocal.currentStep - 1].animationIndex !== null && canvasState.workInstructions?.cardsData[assemblyStateLocal.currentStep - 1].animationIndex !== undefined) {
            setCurrentStepHasLinkedAnimation(true);
        } else {
            setCurrentStepHasLinkedAnimation(false);
        }
    }, [assemblyStateLocal]);

    const { t } = useTranslation();

    return (
        <Grid
            container
            spacing={2}
            justifyContent="space-between"
            className="assembly-bar-container"
        >
            <Grid
                item
                xs={12}
                md={4}
                lg={4}
                xl={3}
            >
                <StepInstructions
                    updateQuestion={(question) => updateQuestion(question)}
                    isTrainer={props.isTrainer}
                    selectedStep={(step) => stepSelected(step)}
                    prevStep={() => step(false)}
                    nextStep={() => step(true)}
                    assemblyState={assemblyStateLocal}
                    isViewComment={props.isViewComment}
                    confirmRequiredQuestionsHaveBeenAnswered={confirmRequiredQuestionsHaveBeenAnswered}
                />
            </Grid>
            {!showIntro ? (
                <>
                    <Grid
                        className="canvas-control-container-center"
                        item
                        xs={12}
                        md={4}
                        lg={4}
                        xl={4}
                    >
                        <InstructionPlayer
                            stepHasLinkedAnimation={currentStepHasLinkedAnimation}
                            isTrainer={props.isTrainer}
                            timerLoading={props.timerLoading}
                            isViewComment={props.isViewComment}
                            procedure={props.procedure}
                            stepIndex={props.stepIndex}
                            is2dStep={!canvasState.workInstructions?.cardsData[assemblyStateLocal.currentStep - 1]?.is2dStep || canvasState.workInstructions?.cardsData[assemblyStateLocal.currentStep - 1]?.is2dStep === false}
                        />
                    </Grid>
                    <Grid
                        className="canvas-control-container-end"
                        item
                        xs={12}
                        md={4}
                        lg={4}
                        xl={3}
                        sx={{ display: { xs: 'none', md: 'flex' } }}
                    >
                        <CanvasControls
                            assemblyState={assemblyStateLocal}
                            zoom={(level) => props.zoom(level)}
                            zoomChange={props.currentZoomLevel}
                            isTrainer={props.isTrainer}
                            procedure={props.procedure}
                            stepIndex={props.stepIndex}
                            is2dStep={!canvasState.workInstructions?.cardsData[assemblyStateLocal.currentStep - 1]?.is2dStep || canvasState.workInstructions?.cardsData[assemblyStateLocal.currentStep - 1]?.is2dStep === false}
                        />
                    </Grid>
                </>
            ) : (
                <></>
            )}
        </Grid>
    );
};

export default AssemblyBar;
