import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, FormControl, FormControlLabel, FormGroup, Typography, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BootstrapDialogTitle } from '../dialogTitle';
export interface FilterOption {
    filter: string;
    filterValues: string[];
}
export interface FilterDialogProps {
    open: boolean;
    onClose: any;
    setFilter: any;
    filter: any;
    filterOptions: FilterOption[];
    applyFilters: any;
    isModel?: boolean;
    activeFilters: string;
    isTrainer?: boolean;
    isRecycle?: boolean;
}

enum displayFilters {
    status = 'Status',
    completionStatus = 'Status',
}

export const FilterMenu = (props: FilterDialogProps) => {
    const [filterOptions, setFilterOptions] = useState<any>({});
    const [fullFilterList, setfullFilterList] = useState<any>([]);
    const [currentFilter, setCurrentFilter] = useState('');
    const onClose = () => {
        props.onClose();
    };

    useEffect(() => {
        let fullList: Array<any> = [];
        props.filterOptions &&
            props.filterOptions.map((item) => {
                item.filterValues &&
                    item.filterValues.map((val, index) => {
                        let isApplied = false;
                        if (typeof props.activeFilters === 'string') {
                            const filters = props.activeFilters.split(/[|.]/).map((filter) => {
                                const parts = filter.split(':');
                                return parts.length > 1 ? parts.slice(1).join(':').trim() : filter.trim();
                            });
                            isApplied = filters.includes(val);
                        }

                        if (Array.isArray(props.activeFilters)) {
                            isApplied = !!props.activeFilters.find(({ filter, value, applied }) => filter === item.filter && value === val && applied);
                        }
                        let obj = {
                            filter: item.filter,
                            value: val,
                            applied: isApplied,
                        };
                        fullList.push(obj);
                    });
            });
        setfullFilterList(fullList);
    }, [props.filterOptions, props.activeFilters]);

    const handleChange = (e) => {
        e.stopPropagation();
        const list = fullFilterList.map((item) => (item.filter === currentFilter && item.value === e.target.name ? { ...item, applied: e.target.checked } : item));
        setfullFilterList(list);
    };

    useEffect(() => {
        if (props.filter.filter) {
            setCurrentFilter(props.filter.filter);
            setFilterOptions(
                props.filterOptions.filter((item) => {
                    return item.filter === props.filter.filter;
                })[0],
            );
        } else {
            setCurrentFilter(props.filterOptions[0].filter);
            setFilterOptions(props.filterOptions[0]);
        }
    }, [props.filter]);

    const theme = useTheme();
    const { t } = useTranslation();

    const handleFilterClick = (e, item) => {
        e.stopPropagation();
        props.setFilter(item);
    };
    return (
        <>
            {props.isModel && <BootstrapDialogTitle onClose={onClose}>{t('WI_LIST.FILTERS')}</BootstrapDialogTitle>}
            <DialogContent
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    // paddingBottom: '0px !important',
                    // paddingRight: '0px !important',
                    padding: props.isModel ? '24px 0px 0px 24px' : '0px 0px 0px 24px',
                    borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
                    height: '256px',
                    width: props.isModel ? 'inherit' : '320px',
                }}
            >
                <Box
                    component="div"
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        padding: '24px 0px 0px',
                        overflowY: 'scroll',
                        width: '40%',
                    }}
                >
                    {props.filterOptions &&
                        props.filterOptions.map((item, index) => {
                            return (
                                <Button
                                    key={index}
                                    sx={{
                                        justifyContent: 'flex-start',
                                        width: 'fit-content',
                                        color: filterOptions.filter === item.filter ? theme.palette.primary.main : '#000000',
                                        paddingLeft: 0,
                                        fontSize: '0.875rem',
                                        whiteSpace: 'nowrap',
                                    }}
                                    onClick={(e) => handleFilterClick(e, item)}
                                >
                                    {t('WI_LIST.' + item.filter.toUpperCase()) as string}
                                </Button>
                            );
                        })}
                </Box>
                <Box
                    component="div"
                    sx={{
                        display: 'flex',
                        flex: 1,
                        flexDirection: 'column',
                        padding: '20px 0px 0px 20px',
                        //textTransform: 'uppercase',
                        overflowY: 'scroll',
                        background: theme.palette.primary.main + '1c',
                    }}
                >
                    <FormControl
                        component="fieldset"
                        variant="standard"
                    >
                        <FormGroup>
                            {fullFilterList &&
                                fullFilterList.map((item, index) => {
                                    if (item.filter === currentFilter)
                                        return (
                                            <FormControlLabel
                                                key={index}
                                                control={
                                                    <Checkbox
                                                        name={item.value}
                                                        onClick={handleChange}
                                                        checked={item.applied}
                                                    />
                                                }
                                                label={<Typography sx={{ fontSize: '13px', margin: '5px 0px' }}>{item.value}</Typography>}
                                            />
                                        );
                                })}
                        </FormGroup>
                    </FormControl>
                </Box>
            </DialogContent>
            <DialogActions sx={{ padding: '16px 24px 10px 16px' }}>
                <Button
                    variant="outlined"
                    sx={{
                        justifyContent: 'flex-start',
                        width: 'fit-content',
                        minWidth: 0,
                        marginRight: '8px',
                    }}
                    onClick={() => {
                        props.applyFilters([]);
                        props.onClose();
                    }}
                >
                    {t('COMMON.RESET') as string}
                </Button>

                <Button
                    variant="contained"
                    sx={{
                        justifyContent: 'flex-start',
                        width: 'fit-content',
                        minWidth: 0,
                    }}
                    onClick={() => {
                        props.applyFilters(fullFilterList);
                        props.onClose();
                    }}
                >
                    {t('BUTTON.APPLY') as string}
                </Button>
            </DialogActions>
        </>
    );
};

export const FilterDialog = (props: FilterDialogProps) => {
    const onClose = () => {
        props.onClose();
    };

    return (
        <>
            <Dialog
                keepMounted
                sx={{
                    '& .MuiDialog-paper': {
                        minWidth: '100%',
                        maxHeight: '60%',
                        margin: '0px',
                        alignSelf: 'flex-end',
                        borderBottomRightRadius: 'unset',
                        borderBottomLeftRadius: 'unset',
                    },
                }}
                maxWidth="xs"
                open={props.open}
                onClose={onClose}
            >
                <FilterMenu
                    open={props.open}
                    filter={props.filter}
                    setFilter={props.setFilter}
                    filterOptions={props.filterOptions}
                    onClose={props.onClose}
                    applyFilters={props.applyFilters}
                    isModel={true}
                    activeFilters={props.activeFilters}
                    isRecycle={props.isRecycle}
                />
            </Dialog>
        </>
    );
};

export const FilterDropdown = (props: FilterDialogProps) => {
    return (
        <Box component="div">
            <FilterMenu
                open={props.open}
                filter={props.filter}
                setFilter={props.setFilter}
                filterOptions={props.filterOptions}
                onClose={props.onClose}
                applyFilters={props.applyFilters}
                isModel={false}
                activeFilters={props.activeFilters}
                isRecycle={props.isRecycle}
            />
        </Box>
    );
};
