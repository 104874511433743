import { useTranslation } from 'react-i18next';
import { EmptyFolderIcon } from '../../components/Icons/CdsIcons';
import { Box, Typography } from '@mui/material';

export const RecycleBinEmptyState = (props) => {
    const { t } = useTranslation();

    return (
        <Box
            component={'div'}
            sx={{
                display: 'block',
                width: 'inherit',
            }}
        >
            <Box
                component={'div'}
                sx={{
                    height: '500px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '20px',
                }}
            >
                <EmptyFolderIcon />

                <Typography
                    variant="h5"
                    sx={{
                        color: '#1C1D1F99',
                    }}
                >
                    {props.filterVal === '' || props.searchVal === '' ? t('HOMEPAGE.RECYCLE_BIN_EMPTY_STATE.THIS_FOLDER_IS_EMPTY') : t('HOMEPAGE.RECYCLE_BIN_EMPTY_STATE.NO_ITEMS_MATCH_YOUR_SEARCH')}
                </Typography>
            </Box>
        </Box>
    );
};
