import React, { useEffect, useMemo, useState } from 'react';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import './ProcedureAnalytics.scss';
import ProcedureInfo from './ProcedureInfo';
import { IProcedures, IWorkInstructions } from '../../../entities/Trainee/MockTrainee';
import TutorialService from '../../../services/trainee/TraineeService';
import Loading from '../../Loading/Loading';
import AnalyticsService from '../../../services/Analytics/AnalyticsService';
import { IProcedureTrainees } from '../../../entities/Analytics/AnalyticsProcedure';
import { Toast } from '../../Notifications/Notification';
import { AnalyticsIcon } from '../../Icons/CdsIcons';
import { TraineeProcedureProgressStatus } from '../../../shared/enums';
import { useTranslation } from 'react-i18next';
import { Feedback } from './Feedback';
import { TraineesAssigned } from './TraineesAssigned';
import { TraineeTime } from './TraineeTime';
import TableContainer, { columnFilter } from './TableContainer';
import moment from 'moment';
import { BasicDialog } from '../../Trainer/Dialog/BasicDialog/BasicDialog';
import { Comments } from '../Modals/Comments';
import { QuestionResponsesPerUser } from '../Modals/QuestionResponses';
import { CommentSideBar } from './CommentsSideBar/CommentSideBar';
import { CommentSliderWindow } from './CommentsSideBar/CommentSliderWindow';
import { useNavigate } from 'react-router-dom';
import { WorkInstructionCompletionStatus } from '../TraineeReports/TraineesAssigned/TraineeAssignedDetails';

interface ProcedureAnalyticsProps {
  procedureId: string;
  workInstructionId: string;
}

const ProcedureAnalyticsContainer: React.FC<ProcedureAnalyticsProps> = ({ procedureId, workInstructionId }) => {
  const demoTraineesData = {
    traineeCount: 0,
    traineesData: null,
  };
  const [workInstruction, setWorkInstruction] = useState<IWorkInstructions | null>();
  const [procedure, setProcedure] = useState<IProcedures | null>();
  const [loading, setLoading] = useState(true);
  const [traineesLoading, setTraineesLoading] = useState(true);
  const [traineesData, setTraineesData] = useState<IProcedureTrainees>(demoTraineesData);
  const [inProgressCount, setInProgressCount] = useState<number>(0);
  const [completedCount, setCompletedCount] = useState<number>(0);
  const [notStartedCount, setNotStartedCount] = useState<number>(0);
  const [traineeAverageTime, setTraineeAverageTime] = useState<any>();
  const [feedbackData, setFeedbackData] = useState<any>();
  const { t } = useTranslation();
  const [traineeFinishTime, setTraineeFinishTime] = useState<any>();
  const [showComments, setShowComments] = useState(false);
  const [showChats, setShowChats] = useState(false);
  const [showResponses, setShowResponses] = useState(false);
  const [trainee, setTrainee] = useState('');
  const [showRightPaneComment, setShowRightPaneComment] = useState<boolean>(false);
  const [activeThreadId, setActiveThreadId] = useState<string>('');
  const [showCommentCount, setShowCommentCount] = useState<boolean>(false);
  const [unreadCommentCount, setUnreadCommentCount] = useState<number>(0);
  const [totalCommentCount, setTotalCommentCount] = useState<number>(0);
  useEffect(() => {
    if (workInstructionId) {
      getWorkInstruction(workInstructionId);
    }
    if (procedureId) {
      getProcedure(procedureId);
      getTrainees(procedureId);
      getProcedureAverageTime(procedureId);
      getFeedback(procedureId);
    }

    return () => {};
  }, []);

  useEffect(() => {
    getAnalyticsProcedureTraineesData(traineesData);
  }, [traineesData]);

  const getCommentThreadData = (procedureId: string) => {
    setShowCommentCount(true);
    TutorialService.listStepCommentThreads(procedureId)
      .then((e) => {
        let commentCount: number = 0;
        let totalComment: number = 0;

        e?.data?.data?.map((threadData) => {
          commentCount += threadData?.unreadCount;
          totalComment += threadData?.commentCount;
        });
        setUnreadCommentCount(commentCount);
        setTotalCommentCount(totalComment);
        setShowCommentCount(false);
      })
      .catch(({ response }) => {
        setShowCommentCount(false);
      });
  };

  useEffect(() => {
    getCommentThreadData(procedureId);
  }, []);

  const getFeedback = (id: string) => {
    AnalyticsService.getAnalyticsProcedureFeedback(id)
      .then((e) => {
        const {
          data: { data: feedbackData },
        } = e;
        if (feedbackData) {
          setFeedbackData(feedbackData);
        }
        setLoading(false);
      })
      .catch(({ response }) => {
        Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
        setLoading(false);
      });
  };

  const getProcedureAverageTime = (id) => {
    AnalyticsService.getAnalyticsProcedureAverageTimeTaken(id)
      .then((res) => {
        setTraineeAverageTime([
          {
            name: t('ANALYTICS_SECTION.TIME_ALLOTTED'),
            value: res.data.data.estimatedTime || 0,
          },

          {
            name: t('ANALYTICS_SECTION.TIME_TAKEN'),
            value: res.data.data.avgTimeTaken || 0,
          },
        ]);
      })
      .catch(({ response }) => {
        Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
      });
  };

  const columns = useMemo(
    () => [
      {
        Header: t('ANALYTICS_SECTION.TRAINEE_NAME'),
        accessor: 'trainee_name',
      },
      {
        Header: t('COMMON.STATUS'),
        accessor: 'status',
        disableSortBy: true,
        Filter: columnFilter,
        Cell: ({ value }) => <WorkInstructionCompletionStatus value={value} />,
      },
      {
        Header: t('ANALYTICS_SECTION.TABLE_ASSIGNED'),
        accessor: 'assigned',
        Cell: ({ value }) => <span>{moment.unix(value).format('Do MMM,YYYY')}</span>,
      },
      {
        Header: t('ANALYTICS_SECTION.STARTED'),
        accessor: 'started',
        Cell: ({ value }) => <span>{moment.unix(value).format('Do MMM,YYYY')}</span>,
      },
      {
        Header: t('ANALYTICS_SECTION.COMPLETED'),
        accessor: 'completed',
        Cell: ({ value }) => <span>{moment.unix(value).format('Do MMM,YYYY')}</span>,
      },
      {
        Header: t('ANALYTICS_SECTION.RATINGS')+'(5)',
        accessor: 'ratings',
      },
      {
        Header: t('ANALYTICS_SECTION.RECOMMENDS')+'(10)',
        accessor: 'recommends',
      },

      {
        Header: t('ANALYTICS_SECTION.RESPONSES'),
        accessor: 'responses',
        disableSortBy: true,
        Cell: ({ value }) => {
          return (
            <Box component="div" className="left">
              {value?.answers?.length > 0 ? (
                <Button
                  onClick={() => {
                    setShowResponses(true);
                    setTrainee(value.trainee);
                  }}
                  sx={{
                    margin: 0,
                    padding: 0,
                    minWidth: 0,
                  }}
                >
                  {t('BUTTON.VIEW',{"counts":`(${value?.answers?.length})`})} 
                  {/* View ({value?.answers?.length}){' '} */}
                </Button>
              ) : (
                '-'
              )}
            </Box>
          );
        },
      },
    ],
    [],
  );

  const getAnalyticsProcedureTraineesData = (data: IProcedureTrainees) => {
    if (data && data.traineesData && data.traineesData.length > 0) {
      let inProgress = 0;
      let completed = 0;
      let notStarted = 0;
      data.traineesData.map((item) => {
        if (item.status === TraineeProcedureProgressStatus.IN_PROGRESS) {
          inProgress++;
        } else if (item.status === TraineeProcedureProgressStatus.COMPLETED) {
          completed++;
        } else if (item.status === TraineeProcedureProgressStatus.NOT_STARTED) {
          notStarted++;
        }
      });

      setInProgressCount(inProgress);
      setCompletedCount(completed);
      setNotStartedCount(notStarted);
    }
  };

  const getWorkInstruction = (id: string) => {
    TutorialService.getWorkInstructionsById(id)
      .then((e) => {
        setWorkInstruction(e.data.data);
      })
      .catch((e) => {
        setWorkInstruction(null);
      });
  };

  const getProcedure = (id: string) => {
    TutorialService.getProcedure(id)
      .then((e) => {
        setProcedure(e.data.data);
      })
      .catch((e) => {
        setProcedure(null);
      });
  };

  const getTrainees = (id: string) => {
    AnalyticsService.getAnalyticsProcedureTrainees(id)
      .then((e) => {
        if (e && e.data && e.data.data) {
          let arr: any = [];
          let fastestTrainee = {
            name: '',
            duration: Infinity,
          };
          let slowestTrainee = {
            name: '',
            duration: -Infinity,
          };

          e?.data?.data.traineesData?.map((trainee) => {
            arr.push({
              trainee_name: trainee.firstName + ' ' + trainee.lastName,
              status: trainee.status,
              assigned: trainee.assignedOn,
              started: trainee.startedOn,
              completed: trainee.completedOn,
              time_taken: trainee.completionTime,
              ratings: trainee.ratings,
              recommends: trainee.recommendation,
              comments: trainee.comments,
              responses: { trainee: trainee.firstName + ' ' + trainee.lastName, answers: trainee.answers },
              view_questions: trainee.comments,
            });

            // Timestamps (assignedOn completedOn and startedOn) are in seconds
            if (trainee.status.toLowerCase() === 'completed') {
              // const duration = moment.duration(moment.unix(trainee.completedOn).diff(moment.unix(trainee.startedOn))).asSeconds();

              const duration = parseInt(trainee.completionTime);

              if (duration < fastestTrainee.duration) {
                fastestTrainee.name = trainee.firstName + ' ' + trainee.lastName;
                fastestTrainee.duration = duration;
              }
              if (duration > slowestTrainee.duration) {
                slowestTrainee.name = trainee.firstName + ' ' + trainee.lastName;
                slowestTrainee.duration = duration;
              }
            }
          });
          setTraineesData({
            traineeCount: e.data.data.traineesData?.length || 0,
            traineesData: arr,
          });
          setTraineeFinishTime({
            fastestTrainee: fastestTrainee,
            slowestTrainee: slowestTrainee,
          });
        }
        setLoading(false);
        setTraineesLoading(false);
      })
      .catch(({ response }) => {
        Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response?.data?.messageCode, { ns: 'error' }) || response?.data?.errorMessage);
        setTraineesData(demoTraineesData);
        setLoading(false);
        setTraineesLoading(false);
      });
  };

  const navigate = useNavigate();

  return (
    <>
      {loading && <Loading />}
      <CommentSideBar showRightPaneComment={showRightPaneComment} setShowRightPaneComment={setShowRightPaneComment} activeThreadId={activeThreadId} setActiveThreadId={setActiveThreadId} workInstruction={workInstruction} procedure={procedure} isTrainer={true} />
      <CommentSliderWindow showRightPaneComment={showRightPaneComment} setShowRightPaneComment={setShowRightPaneComment} activeThreadId={activeThreadId} setActiveThreadId={setActiveThreadId} showChats={showChats} setShowChats={setShowChats} workInstruction={workInstruction} procedure={procedure} isTrainer={true} />

      <BasicDialog
        title={t('ANALYTICS_SECTION.RESPONSES')}
        onClose={() => {
          setShowResponses(false);
        }}
        onOpen={showResponses}
      >
        {traineesData?.traineesData?.map((data, index) => {
          return (
            <Box component={'div'} key={index}>
              {data.trainee_name === trainee && Array.isArray(data.responses.answers) && data.responses?.answers?.length > 0 && <QuestionResponsesPerUser questionResponses={data?.responses?.answers} />}
            </Box>
          );
        })}
      </BasicDialog>
      <BasicDialog
        title={`${t('ANALYTICS_SECTION.COMMENTS')} (${feedbackData?.comments.length})`}
        onClose={() => {
          setShowComments(false);
        }}
        onOpen={showComments}
      >
        <Comments comments={feedbackData?.comments} />
      </BasicDialog>
      <Box component="div" className="procedure-analytics" sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box component="div">
          <ProcedureInfo name={procedure?.procedureName ?? ''} workInstruction={workInstruction} tasks_count={procedure?.tasks_count} version={procedure?.version} notStartedCount={notStartedCount} completedCount={completedCount} inProgressCount={inProgressCount} />
        </Box>

        {(!traineesData?.traineesData || traineesData?.traineesData?.length === 0) && (
          <Box
            component="div"
            className="analytics-box"
            sx={{
              display: 'flex',
              flexGrow: 1,
              alignItems: 'center !important',
              justifyContent: 'center !important',
              minHeight: 'calc(100vh - 16em)',
            }}
          >
            <Stack direction="column" alignItems="center" gap="2">
              <AnalyticsIcon sx={{ width: 100, height: 71.25, color: '#303030' }} />
              {!loading && traineesData?.traineesData?.length === 0 && (
                <Typography color="#303030" sx={{ mt: '2em' }}>
                  {t('ANALYTICS_SECTION.JUST_ASSIGNED_MESSAGE')}
                  <br /> {t('ANALYTICS_SECTION.COME_BACK_LATER')}
                </Typography>
              )}
              {loading && (
                <Typography color="#303030" sx={{ mt: '2em', fontSize: '1em' }}>
                  {t('COMMON.LOADING_DATA')}...
                </Typography>
              )}
            </Stack>
          </Box>
        )}

        {traineesData?.traineesData && traineesData?.traineesData?.length > 0 && (
          <>
            <Grid container spacing={2} sx={{ minHeight: '450px', mt: '10px', display: 'flex' }}>
              <Grid item xs={6} sx={{ display: 'flex' }}>
                <Box component="div" className="analytics-box" sx={{ display: 'flex', flexGrow: 1 }}>
                  <TraineesAssigned notStartedCount={notStartedCount} completedCount={completedCount} inProgressCount={inProgressCount} />
                </Box>
              </Grid>

              <Grid item xs={6} sx={{ display: 'flex' }}>
                <Grid container sx={{ display: 'flex' }} spacing={2}>
                  <Grid item xs={3} sx={{ display: 'flex', height: '25%' }}>
                    <Box component="div" className="analytics-box" sx={{ flexGrow: 1 }}>
                      <Feedback title={t('ANALYTICS_SECTION.RATINGS')}  value={feedbackData?.ratings} total={5} entries={completedCount} />
                    </Box>
                  </Grid>
                  <Grid item xs={3} sx={{ display: 'flex', height: '25%' }}>
                    <Box component="div" className="analytics-box" sx={{ flexGrow: 1 }}>
                      <Feedback title={t('ANALYTICS_SECTION.RECOMMENDED')} value={feedbackData?.recommendation} total={10} entries={completedCount} />
                    </Box>
                  </Grid>
                  <Grid item xs={3} sx={{ display: 'flex', height: '25%' }}>
                    <Box component="div" className="analytics-box" sx={{ flexGrow: 1 }}>
                      <Button
                        sx={{ padding: 0 }}
                        onClick={(e) => {
                          setShowComments(true);
                        }}
                      >
                        <Feedback title= {t('PROCEDURE.FEEDBACK')} value={feedbackData?.comments.length} />
                      </Button>
                    </Box>
                  </Grid>

                  <Grid item xs={3} sx={{ display: 'flex', height: '25%' }}>
                    <Box component="div" className="analytics-box" sx={{ flexGrow: 1,  overflow: 'hidden', whiteSpace: 'nowrap'}}>
                      {showCommentCount && <Loading />}
                      {!showCommentCount && (
                        <Button
                          sx={{ padding: 0, }}
                          onClick={(e) => {
                            navigate(`/notifications?procedureId=${procedure?.procedureId}`);
                          }}
                        >
                          <Feedback title={t('ANALYTICS_SECTION.UNREAD_COMMENTS_ALL')} value={`${unreadCommentCount}(${totalCommentCount})`} />
                        </Button>
                      )}
                    </Box>
                  </Grid>

                  <Grid item xs={12} sx={{ display: 'flex', height: '66%' }}>
                    <Box component="div" className="analytics-box" sx={{ flexGrow: 1 }}>
                      <TraineeTime traineeAverageTime={traineeAverageTime} traineeFinishTime={traineeFinishTime} />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container sx={{ mt: '35px' }}>
              <TableContainer columns={columns} data={traineesData?.traineesData} />
            </Grid>
          </>
        )}
      </Box>
    </>
  );
};

export default ProcedureAnalyticsContainer;
