import { Box, IconButton, Typography, useTheme } from '@mui/material';
import React from 'react';
import MuiTooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import HelpIcon from '@mui/icons-material/Help';
interface AnalyticsTooltipProps {
  label: string;
  children?: any;
}

export const AnalyticsTooltip = ({ label, children }: AnalyticsTooltipProps) => {
  const CustomMuiTooltip = styled(({ className, ...props }: TooltipProps) => <MuiTooltip {...props} classes={{ popper: className }} />)({
    [`& .${tooltipClasses.tooltip}`]: {
      border: 'none',
      background: '#FFFFFF',
      boxShadow: '0px 2px 10px rgba(2, 71, 137, 0.2)',
      borderRadius: '12px',
      padding: '16px',
      margin: '.5em',
      opacity: '.9',
      color: '#000000',
      fontSize: '14px',
    },
  });
  const theme = useTheme();
  return (
    <CustomMuiTooltip
      title={label}
      arrow
      sx={{
        '& .MuiTooltip-arrow': {
          '&::before': {
            color: '#FFFFFF',
            border: '1px solid rgba(2, 71, 137, 0.2)',
          },
        },
      }}
    >
      <Box component="div">
        {children}
        {!children && (
          <IconButton sx={{ padding: 0 }} disableRipple={true}>
            <HelpIcon
              sx={{
                color: theme.palette.primary.main,
                margin: '0px 8px',
                opacity: 0.4,
                fontSize: '14px',
                '&:hover': { opacity: 1 },
                marginRight: 0,
              }}
            />
          </IconButton>
        )}
      </Box>
    </CustomMuiTooltip>
  );
};
