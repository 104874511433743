import * as React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import './BasicDialog.scss';
import CloseIcon from '@mui/icons-material/Close';
import { Breakpoint, DialogActions, DialogTitle, IconButton } from '@mui/material';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: '24px',
        minWidth: '358px',
        overflowX:'hidden'
    },
    '& .MuiDialogActions-root': {
        padding: '0px 24px 24px 24px',
    },
}));

interface Props {
    openApprovalDialog: boolean;
}

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
    paddingX?: string;
    paddingY?: string;
}
interface defaultProps {
    title?: string;
    onClose?: any;
    onOpen?: any;
    actions?: any;
    children?: any;
    id?: string;
    fullWidth?: boolean;
    fullScreen?: boolean;
    maxWidth?: false | Breakpoint | undefined;
    additionalProp?: any;
    components?: {
        DialogTitle?: any;
    };
    dialogWidth?:any;
}

export const BootstrapDialogTitle = (props: DialogTitleProps) => {
    const { children, onClose, paddingX, paddingY, ...other } = props;

    return (
        <>
            <DialogTitle
                className="remove-margin"
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    paddingInline: paddingX ? `${paddingX} !important` : '24px !important',
                    paddingBlock: paddingY ? `${paddingY} !important` : '24px !important',
                    minWidth: '400px',
                }}
                {...other}
            >
                {children}

                <IconButton
                    aria-label="close"
                    onClick={() => onClose()}
                    sx={{
                        color: (theme) => theme.palette.grey[900],
                        padding: 0,
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
        </>
    );
};

export const BasicDialog = (props: defaultProps) => {
    const { title, onClose, onOpen, actions, children, additionalProp } = props;

    return (
        <BootstrapDialog
            fullWidth={props.fullWidth}
            fullScreen={props.fullScreen}
            maxWidth={props.maxWidth}
            onClose={onClose}
            aria-labelledby="customized-dialog-title"
            open={onOpen}
            disableEnforceFocus
            id={props.id ? `dialogue-${props.id}` : ''}
            PaperProps={{
                sx: {
                    maxWidth: props.dialogWidth && props.dialogWidth,
                },
            }}
        >
            {title && (
                <BootstrapDialogTitle
                    id="customized-dialog-title"
                    onClose={onClose}
                    paddingX="24px"
                    paddingY="12px"
                >
                    <Typography sx={{ fontSize: '20px' }}>{title}</Typography>
                </BootstrapDialogTitle>
            )}
            {props.components?.DialogTitle && React.cloneElement(props.components.DialogTitle)}

            <DialogContent id={props.id ? `dialogue-content-${props.id}` : ''}>{children}</DialogContent>
            {actions && <DialogActions>{actions}</DialogActions>}
        </BootstrapDialog>
    );
};
