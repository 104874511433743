import { FormControl, InputAdornment, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SortDesc } from '../Icons/CdsIcons';

export interface SortOption {
  value: string; label: string 
}
interface SelectSortProps {
  value: string;
  onChange: (value: string) => void;
  sortOptions: SortOption[];
}

const SelectSort: React.FC<SelectSortProps> = ({ value, onChange, sortOptions }) => {
  const { t } = useTranslation();

  const handleSortChange = (e: SelectChangeEvent<string>) => {
    const selectedValue = e.target.value as string;
    onChange(selectedValue);
  };

  return (
    <FormControl sx={{ minWidth: 120, display: { xs: 'none', md: 'flex' } }} className='sort'>
      <Select
        inputProps={{ 'aria-label': 'Without label' }}
        startAdornment={
          <InputAdornment position="start" sx={{ color: 'primary.main' }}>
            <SortDesc />
          </InputAdornment>
        }
        sx={{ color: 'primary.main' }}
        value={value}
        onChange={handleSortChange}
        size="small"
        displayEmpty
      >
        <MenuItem value="" sx={{ color: 'primary.main' }}>
          <em>{t('COMMON.SORT')}</em>
        </MenuItem>
        {sortOptions.map((option) => (
          <MenuItem key={option.value} value={option.value} sx={{ color: 'primary.main' }}>
            {t(option.label)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectSort;
