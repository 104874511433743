import { Box, Button, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BasicDialog } from '../BasicDialog/BasicDialog';
import Loading from '../../../Loading/Loading';
import { WarningIcon } from '../../../Icons/CdsIcons';
import TrainerService from '../../../../services/trainer/TrainerService';
import { Toast } from '../../../Notifications/Notification';
import './DeleteDocumentPermanentlyDialog.scss';

export const DeleteDocumentPermanentlyDialog = (props) => {
    const [loading, setLoading] = useState(true);

    const handleDeleteDocument = async () => {
        switch (props.selectedDocument.type) {
            case 'Folder':
                setLoading(false);
                await TrainerService.recyclebinFolder(props.selectedDocument.id)
                    .then((response) => {
                        props.handleReloadData();
                        Toast.success(t('WI_LIST.FOLDER'), t('COMMON.ITEM_DELETED_SUCCESSFULLY'));
                        props.closeDialog();
                    })
                    .catch(({ response }) => {
                        Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
                    })
                    .finally(() => setLoading(true));
                break;
            case 'WorkInstruction':
                setLoading(false);
                await TrainerService.deleteWorkInstruction(props.selectedDocument.id)
                    .then((response) => {
                        props.handleReloadData();
                        Toast.success(t('WI_LIST.WORK_INSTRUCTION'), t('COMMON.ITEM_DELETED_SUCCESSFULLY'));
                        props.closeDialog();
                    })
                    .catch(({ response }) => {
                        Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
                    })
                    .finally(() => setLoading(true));
                break;
            case 'Procedure':
                setLoading(false);
                await TrainerService.deleteProcedure(props.selectedDocument.id)
                    .then((response) => {
                        props.handleReloadData();
                        Toast.success(t('WI_LIST.PROCEDURE'), t('COMMON.ITEM_DELETED_SUCCESSFULLY'));
                        props.closeDialog();
                    })
                    .catch(({ response }) => {
                        Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
                    })
                    .finally(() => setLoading(true));
                break;
            case 'LibraryProcedure':
                setLoading(false);
                await TrainerService.deleteProcedure(props.selectedDocument.id)
                    .then((response) => {
                        props.handleReloadData();
                        Toast.success(t('ADD_WI.PROCEDURE_LIBRARY'), t('COMMON.ITEM_DELETED_SUCCESSFULLY'));
                        props.closeDialog();
                    })
                    .catch(({ response }) => {
                        Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
                    })
                    .finally(() => setLoading(true));
                break;
            default:
                break;
        }
    };

    const { t } = useTranslation();

    return (
        <>
            <BasicDialog
                title={props?.selectedFolderWiCard?.size > 1 ? t('COMMON.DELETE_ITEMS') : t('COMMON.DELETE_ITEM')}
                onClose={() => {
                    props.closeDialog();
                }}
                onOpen={props.openDialog}
                actions={
                    <>
                        <Button
                            onClick={() => {
                                props.closeDialog();
                            }}
                            variant="outlined"
                            size="small"
                            type="submit"
                        >
                            {t('BUTTON.CANCEL') as string}
                        </Button>
                        <Button
                            onClick={() => {
                                handleDeleteDocument();
                            }}
                            variant="contained"
                            size="small"
                            type="submit"
                        >
                            {t('BUTTON.CONFIRM') as string}
                        </Button>
                    </>
                }
            >
                {!loading ? (
                    <Box
                        component="div"
                        className="align-loader"
                    >
                        <Loading />
                    </Box>
                ) : (
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography sx={{ marginLeft: '16px', fontSize: '16px', display: 'flex', flexDirection: 'row' }}>
                            <WarningIcon />

                            <Typography sx={{ paddingLeft: '8px', fontWeight: '400' }}>{t('COMMON.ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_ITEM_PERMANENTLY')}</Typography>
                        </Typography>
                    </Box>
                )}
            </BasicDialog>
        </>
    );
};
