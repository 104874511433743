import { Box, Button, Typography, FormControl, InputAdornment, MenuItem, Select, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SearchBar from '../../components/SearchBar/SearchBar';
// import FilterButton from '../../components/FilterButton/FilterButton';
import { debounce } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { SUPPORTED_LANGUAGES } from '../../shared/enums';
import { ActionFilter } from '../../components/Icons/CdsIcons';
import LanguageDialog from './LanguageDialog,';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { getAllSubstitutions } from '../../services/Substitution/SubstitutionService';
import { Filterable, Pageable, Searchable } from '../../shared/interfaces';
import { setLoading, setSubstitutions } from '../../redux/features/Substitutions/Substitutions.slice';
import { setSearchPayload } from '../../redux/features/Substitutions/Substitutions.slice';
// interface Payload {
//     search?: string;
//     language?: [];
//     currPage?: 1;
//     pageSize?: 30;
// }
export interface ISubstitutionsSearchPayload extends Pageable, Searchable<'search', string>, Filterable<'language', string[]> {
    search?: string;
}
export interface ISelectedFilters {
    matchValue: string;
    value: string;
}

const SubstitutionsHeader = ({ setIsModalOpen }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const {searchPayload } = useAppSelector((state) => state.substitution);
    const [selectedFilters, setSelectedFilters] = useState<ISelectedFilters[]>();
    const handleSearchTextChange = debounce((event: React.ChangeEvent<HTMLInputElement>): void => {
        updatePayload({ ...searchPayload, search: event.target.value });
    }, 500);
    const handleFilterChange = (filters: ISelectedFilters[]) => {
        setSelectedFilters(filters);
        updatePayload({ ...searchPayload, language: filters.map((item) => item.matchValue) });
    };
    const updatePayload = (payload: ISubstitutionsSearchPayload) => {
        const cleanedPayload = { ...payload };
        Object.keys(cleanedPayload).forEach((key) => {
            const value = cleanedPayload[key];
            if (value === '' || (Array.isArray(value) && value.length === 0)) {
                delete cleanedPayload[key];
            }
        });
        dispatch(
            setSearchPayload({
                ...cleanedPayload,
                currPage: 1,
            })
        );
    };
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h5">{t('SUBSTITUTION.HEADING')}</Typography>
            <Box sx={{ display: 'flex', gap: '10px', marginLeft: 'auto' }}>
                <SearchBar
                    onChangeHandler={handleSearchTextChange}
                    value={searchPayload?.search}
                />
                {/* <SelectSort
            sortOptions={[]}
            onChange={handleSortChange}
            value={''}
          />*/}
                <SubstitutionFilterButton
                    handleFilterChange={handleFilterChange}
                    filters={{ label: t('PROCEDURE.LANGUAGE'), filterValues: Object.keys(SUPPORTED_LANGUAGES).map((each) => t('LANGUAGE_SIDEBAR.SUPPORTED_LANGUAGES.' + SUPPORTED_LANGUAGES[each].toUpperCase())), matchValues: Object.keys(SUPPORTED_LANGUAGES).map((each) => SUPPORTED_LANGUAGES[each].toUpperCase()) }}
                    applyList={selectedFilters || []}
                />
                <Button
                    variant={'contained'}
                    onClick={() => setIsModalOpen(true)}
                >
                    {t('SUBSTITUTION.ADD_NEW')}
                </Button>
            </Box>
        </Box>
    );
};

export default SubstitutionsHeader;

interface SubstitutionFilterButtonProps {
    handleFilterChange: (filterOptions: ISelectedFilters[]) => void;
    filters: any;
    applyList: ISelectedFilters[];
}
const SubstitutionFilterButton: React.FC<SubstitutionFilterButtonProps> = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const { handleFilterChange, filters, applyList } = props;
    const [filterDropdownOpen, setFilterDropdownOpen] = React.useState<boolean>(false);
    const handleApplyFilter = (filterOptions) => {
        handleFilterChange(filterOptions);
    };
    const handleFilterOpen = () => {
        setFilterDropdownOpen(!filterDropdownOpen);
    };
    return (
        <FormControl sx={{ minWidth: 120, display: { xs: 'none', md: 'flex' } }}>
            <Button
                sx={{
                    '&:hover': {
                        backgroundColor: 'transparent',
                    },
                    padding: 0,
                }}
                disableRipple
            >
                <Select
                    open={filterDropdownOpen}
                    onOpen={handleFilterOpen}
                    onClose={handleFilterOpen}
                    startAdornment={
                        <InputAdornment
                            position="start"
                            sx={{ color: theme.palette.primary.main }}
                        >
                            {' '}
                            <ActionFilter />
                        </InputAdornment>
                    }
                    sx={{ color: theme.palette.primary.main }}
                    value={''}
                    size="small"
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                >
                    <MenuItem
                        value=""
                        sx={{ display: 'none', color: theme.palette.primary.main }}
                    >
                        {' '}
                        <em>{t('COMMON.FILTER')}</em>{' '}
                    </MenuItem>
                    <LanguageDialog
                        filterList={filters}
                        onClose={handleFilterOpen}
                        onApply={handleApplyFilter}
                        applyList={applyList}
                    />
                </Select>
            </Button>
        </FormControl>
    );
};
