import { Card, Box, CardContent, Typography, IconButton } from '@mui/material';
import React, { useEffect } from 'react';
import './FolderCards.scss';
import { getFormattedDateFromUnixTime } from '../../../shared/utils/formateDate';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { DeleteEntityIcon, LibraryProcedureIcon, RestoreEntityIcon } from '../../Icons/CdsIcons';
import { DeleteFolderDialog } from '../../Trainer/Dialog/DeleteFolderDialog/DeleteFolderDialog';
import { RecycleBinActionButton } from '../RecycleBinActionButton';
import { TaskCount } from '../ProcedureCards/ProcedureCards';
interface Props {
    companyId?: string;
    isTrainer?: boolean;
    procedure?: any;
    isCreateFolder?: boolean;
    setIsCreateFolder?: any;
    folders?: any;
    index?: any;
    setFolders?: any;
    onClick?: any;
    selected?: any;
    isRenameNavBar?: boolean;
    setIsRenameNavBar?: any;
    overview?: any;
    parentFolderId?: any;
    handleReloadData?: any;
    selectedFolderWiCard?: any;
    checkedMoveToFolderId?: any;
    setCheckedMoveToFolderId?: any;
    clearCardsSelection?: any;
    handleDuplicateFolder?: any;
    handleClickFolderWi?: any;
    onFolderIconClick?: any;
    newFolderTitle?: string;
    setParentFolderId?: any;
    setBreadCrumbRoute?: any;
    showRecycleBinHome?: boolean;
    deletePermanentlyHandler?: any;
    restoreDocument?: any;
}

const LibraryProcedureCards: React.FC<Props> = (props) => {
    const [isHover, setIsHover] = React.useState<boolean>(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [isRenaming, setIsRenaming] = React.useState<boolean>(false);
    const { t } = useTranslation();
    const [showDeleteFolderDialog, setShowDeleteFolderDialog] = React.useState<boolean>(false);
    const [showDuplicateFolderDialog, setShowDuplicateFolderDialog] = React.useState<boolean>(false);

    const handleMouseEnter = (e) => {
        setIsHover(true);
    };

    const handleMouseLeave = (e) => {
        setIsHover(false);
    };

    const theme = useTheme();

    const handleRename = () => {
        setIsRenaming(true);
        setAnchorEl(null);
    };

    useEffect(() => {
        if (props.isRenameNavBar) {
            handleRename();
        }
    }, [props.isRenameNavBar]);

    const closeDuplicateFolderDialogHandler = () => {
        setShowDuplicateFolderDialog(false);
    };

    const closeDeleteFolderDialogHandler = () => {
        setShowDeleteFolderDialog(false);
    };

    return (
        <>
            <div>
                <DeleteFolderDialog
                    openDialog={showDeleteFolderDialog}
                    closeDialog={closeDeleteFolderDialogHandler}
                    selectedFolderWiCard={props.selectedFolderWiCard}
                    clearCardsSelection={props.clearCardsSelection}
                    handleReloadData={props.handleReloadData}
                />

                <Card
                    onMouseEnter={(e) => {
                        handleMouseEnter(e);
                    }}
                    onMouseLeave={(e) => {
                        handleMouseLeave(e);
                    }}
                    sx={{ position: 'relative', display: { xs: 'none', md: 'flex' }, backgroundColor: props.selected ? theme.palette.primary.main + '45' : isHover ? theme.palette.primary.main + '20' : 'white', border: isHover ? `2px solid ${theme.palette.primary.main + '1c'}` : null, borderRadius: '12px', boxShadow: 'rgba(149, 157, 165, 0.5) 0px 8px 24px', overflow: 'unset' }}
                    elevation={0}
                    className="work-instruction-card"
                    onClick={(e: any) => {
                        e.stopPropagation();
                        props.onClick && props.onClick();
                    }}
                    style={{ height: props.overview === 'extended_view' && props.showRecycleBinHome ? '164px' : props.overview === 'extended_view' ? '144px' : '280px', width: '100%', minWidth: props.overview === 'extended_view' ? '245px' : '400px', cursor: isHover && !props.restoreDocument ? 'pointer' : 'default' }}
                >
                    <Box
                        component="div"
                        sx={{ margin: props.overview === 'extended_view' ? '24px 6px 0px 6px' : '54px 10px 0px 10px' }}
                    >
                        <LibraryProcedureIcon overview={props.overview} />
                    </Box>
                    <Box
                        component="div"
                        sx={{ display: 'flex', flexDirection: 'column' }}
                        className={props.overview === 'extended_view' ? 'work-ins-card-right-extended-view' : 'work-ins-card-right'}
                    >
                        {!props.isCreateFolder && !isRenaming && (
                            <>
                                <CardContent sx={{ flex: '1 0 auto', textAlign: 'left', padding: 0, marginTop: '20px' }}>
                                    <Typography
                                        component="div"
                                        sx={{ color: '#312D32', opacity: '70%', maxHeight: '2rem', lineHeight: '24px', fontSize: props.overview === 'extended_view' ? '10px' : '12px', fontWeight: '600' }}
                                        className="card-work-ins-text one-line-ellipsis"
                                        title={props.procedure.type}
                                    >
                                        {t('COMMON.LIBRARY_PROCEDURE')}
                                    </Typography>
                                    <Typography
                                        component="div"
                                        sx={{ maxHeight: '4rem', fontSize: props.overview === 'extended_view' ? '14px' : '20px', fontWeight: '600' }}
                                        className="card-work-ins-text one-line-ellipsis"
                                        title={props.procedure.title}
                                    >
                                        {props.procedure.procedureName}
                                    </Typography>
                                    <Box sx={{ flexDirection: 'row', display: 'flex' }}>
                                        {props.procedure?.tasks_count > 0 && (
                                            <TaskCount
                                                type="task"
                                                tasks_count={props.procedure?.tasks_count}
                                            />
                                        )}
                                        {props.procedure?.versionName && (
                                            <TaskCount
                                                type="version"
                                                tasks_count={props.procedure?.versionName}
                                            />
                                        )}
                                    </Box>
                                    <Typography
                                        sx={{ marginBottom: '3px !important', fontSize: props.overview === 'extended_view' ? '12px' : '14px', opacity: '70%' }}
                                        component="div"
                                        className="card-work-ins-text  multi-line-ellipsis"
                                        title={props.procedure.workInstructionName}
                                    >
                                        {props.procedure.workInstructionName}
                                    </Typography>
                                    {props.procedure && (
                                        <Typography
                                            sx={{ fontSize: props.overview === 'extended_view' ? '12px' : '14px', fontWeight: '500', opacity: '70%' }}
                                            component="div"
                                            className="card-work-ins-text  multi-line-ellipsis"
                                        >
                                            {getFormattedDateFromUnixTime(props.procedure.deletedOn)}
                                        </Typography>
                                    )}
                                </CardContent>
                                {props.overview === 'normal_view' && (
                                    <RecycleBinActionButton
                                        showRecycleBinHome={props.showRecycleBinHome}
                                        isTrainer={props.isTrainer}
                                        overview={props.overview}
                                        restoreDocument={props.restoreDocument}
                                        deletePermanentlyHandler={props.deletePermanentlyHandler}
                                    />
                                )}
                            </>
                        )}
                    </Box>

                    {props.overview === 'extended_view' && props.showRecycleBinHome && props.isTrainer && (
                        <Box sx={{ position: 'absolute', right: '8px', bottom: '8px' }}>
                            <IconButton
                                onClick={props.restoreDocument}
                                sx={{
                                    borderRadius: '8px',
                                }}
                            >
                                <RestoreEntityIcon />
                            </IconButton>
                            <IconButton
                                onClick={(event) => {
                                    event.stopPropagation();
                                    props.deletePermanentlyHandler();
                                }}
                                sx={{
                                    borderRadius: '8px',
                                }}
                            >
                                <DeleteEntityIcon sx={{ color: theme.palette.primary.main }} />
                            </IconButton>
                        </Box>
                    )}
                </Card>
            </div>
        </>
    );
};

export default LibraryProcedureCards;
