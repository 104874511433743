import { Add } from '@mui/icons-material';
import { Box, IconButton, Paper, Typography, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import TableContainer, { TableColumnHeader } from '../../../components/Analytics/ProcedureReports/TableContainer';
import ProcedureCardOptions from '../../../components/Cards/Procedures/TrainerProcedureCard/ProcedureCardOptions';
import { ProcedureCardLabels, TaskCount } from '../../../components/Cards/Procedures/TrainerProcedureCard/TrainerProcedureCard';
import { NormalProcedureIcon, TemplateProcedureIcon } from '../../../components/Icons/CdsIcons';
import Loading from '../../../components/Loading/Loading';
import TagManager, { Tag } from '../../../components/Tags/TagsManager';
import DeleteModularProcedure from '../../../components/Trainer/Dialog/DeleteModularProcedure/DeleteModularProcedure';
import { DuplicateProcedure } from '../../../components/Trainer/Dialog/DuplicateProcedureDialog/DuplicateProcedureDialog';
import { LanguageSupport } from '../../../components/Trainer/Dialog/LanguageSupportDialog/LanguageSupportDialog';
import ShareQR from '../../../components/Trainer/Dialog/ShareQR/ShareQR';
import VersionDialog from '../../../components/Trainer/Dialog/VersionDialog/versionDialog';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { fetchTemplateProcedures, setAvailableTagAction, setDialogueToOpen, setProcedureToView, setSearchPayload } from '../../../redux/features/ModularProcedures/ModularProcedures.slice';
import { addTagsToTemplateProcedure, getTemplateProcedureTags, createNewTag, deleteTag } from '../../../services/Tag/TagService';
import { PROCEDURE_VISIBILITY_SCOPE } from '../../../shared/enums';
import { PROCEDURE_LIBRARY_DIALOG } from './constants';
import LinkedWorkInstructions from './LinkedWorkInstructions';
import ProcedureLibraryEmptyState from './ProcedureLibraryEmptyState';
import SelectWorkInstruction from './SelectWorkInstruction';
import ProcedureStepsViewer from './StepViewer';
import PeriodicReviewDialog from '../../../components/Trainer/Dialog/PeriodicReviewDialog/PeriodicReviewDialog';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useTranslation } from 'react-i18next';
import ManagerApproval from '../../../components/Trainer/Dialog/ManagerApproval/ManagerApproval';

interface ProcedureLibraryProps {}
const TAGS_LENGTH = 3;

const columns = [
  {
    Header: <TableColumnHeader label={"COMMON.NAME"} />,
    accessor: 'name',
    disableSortBy: true,
    Cell: ({ value, row }) => {
      const visibilityScope = row.original.visibilityScope;
      return (
        <Box
          component={'div'}
          sx={{ marginLeft: '8px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}
        >
          <Box component={'div'}>
            {visibilityScope === PROCEDURE_VISIBILITY_SCOPE.NORMAL ? <NormalProcedureIcon /> : null}
            {visibilityScope === PROCEDURE_VISIBILITY_SCOPE.GLOBAL ? <TemplateProcedureIcon /> : null}
          </Box>
          <Typography sx={{ marginLeft: '8px', fontSize: '16px', fontWeight: '600' }}>{value}</Typography>
        </Box>
      );
    },
  },
  {
    Header: <TableColumnHeader label={"WI_LIST.TAGS"} />,
    accessor: 'tags',
    disableSortBy: true,
    Cell: ({ value, row }) => {
      const theme = useTheme();
      const dispatch = useDispatch();
      const { showProcedureOptions } = row.original;
      const handleAddTagClick = (e) => {
        e.stopPropagation();
        const procedureId = row.original.procedureId;
        dispatch(setProcedureToView(procedureId));
        dispatch(setDialogueToOpen(PROCEDURE_LIBRARY_DIALOG.TAG_MANAGER));
      };

      const {
        procedureLibrary: { availableTags },
      } = useAppSelector((state) => state.modularProcedure);

      const [tags, setTags] = useState([]);

      useEffect(() => {
        let procedureTags = value || [];
        procedureTags = availableTags.filter((tag) => procedureTags.includes(tag.tagId)).map((each) => each.name);
        setTags(procedureTags);
      }, [value, availableTags]);

      return (
        <Box
          component={'div'}
          sx={{ display: 'flex', alignItems: 'center', gap: '5px', flexWrap: 'wrap', width: "370px" }}
        >
          {tags.slice(0, TAGS_LENGTH).map((tag) => (
            <Tag
              key={tag}
              tag={tag}
              handleClick={(e) => e.stopPropagation()}
              sx={{
                fontWeight: '700',
                border: '2px solid #1C1D1F',
                margin: '5px 0',
                span: { color: '#1C1D1F' },
                opacity: 0.5,
              }}
            />
          ))}
          {tags.length - TAGS_LENGTH > 0 && (
            <Box
              component={'span'}
              sx={{ color: theme.palette.primary.main }}
              onClick={handleAddTagClick}
            >
              {`${tags.length - TAGS_LENGTH} More`}
            </Box>
          )}
          {showProcedureOptions && (
            <IconButton
              className="show-on-hover"
              size="small"
              onClick={handleAddTagClick}
            >
              <Add fontSize="inherit" color='primary' />
            </IconButton>
          )}
        </Box>
      );
    },
  },
  {
    Header: <TableColumnHeader label={"PROCEDURE.CARD_STEPS"} />,
    accessor: 'steps',
    Cell: ({ value, row }) => (
      <Box component={'div'}>
        <TaskCount tasks_count={value} />
      </Box>
    ),
  },
  {
    Header: <TableColumnHeader label={"COMMON.STATUS"} />,
    accessor: 'procedureId',
    Cell: ({ value, row }) => {
      const [showRefreshButton, setShowRefreshButton] = useState<boolean>(false);
      const dispatch = useAppDispatch();

      const { showProcedureOptions } = row.original;
      const {
        templateProcedures: {
          data: { data: templateProcedures },
        },
        procedureLibrary : {
          searchPayload
        },
        workInstruction: { data: workInstruction }, // TODO: update when introduced 3d modular procedures
      } = useAppSelector((state) => state.modularProcedure);
      const procedure = templateProcedures.find((procedure) => procedure.procedureId === value);

      const handleRefreshClick = () => {
        setShowRefreshButton(false);
        dispatch(fetchTemplateProcedures(searchPayload))
      };

      return (
        <Box
          component={'div'}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <span style={{ fontSize: '14px', fontWeight: 400, color: '#312D32', display: 'flex', flexWrap: 'wrap' }}>
            <ProcedureCardLabels procedureDetails={procedure} />
          </span>
          {showProcedureOptions && procedure && (
            <ProcedureCardOptions
              procedureDetails={procedure}
              showRefreshButton={showRefreshButton}
              setShowRefreshButton={setShowRefreshButton}
              onRefreshClick={handleRefreshClick}
              workInstructionDetails={workInstruction}
            />
          )}
        </Box>
      );
    },
  },
];

const ProcedureLibrary = (props: ProcedureLibraryProps) => {
  const {
    templateProcedures: {
      data: { data: templateProcedures },
      loading,
    },
    workInstruction: { data: workInstruction },
    procedureLibrary,
  } = useAppSelector((state) => state.modularProcedure);

  const { selectedProcedure, dialogueToOpen, searchPayload } = procedureLibrary;

  const [isStepViewerOpen, setIsStepViewerOpen] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  useEffect(() => {
    getTemplateProcedureTags().then((response) => {
      dispatch(setAvailableTagAction(response.data.data));
    });
  }, []);

  const handleRowClick = (item) => {
    const {
      values: { procedureId },
    } = item;
    if (isStepViewerOpen && procedureId === selectedProcedure) {
      handleClose();
    } else {
      dispatch(setProcedureToView(procedureId));
      setIsStepViewerOpen(true);
    }
  };

  const handleClose = () => {
    dispatch(setProcedureToView(''));
    setIsStepViewerOpen(false);
  };

  const closeDialogHandler = () => {
    dispatch(setDialogueToOpen(''));
  };
  const closeLanguageDialogHandler = ()=>{
    dispatch(setDialogueToOpen(''));
    dispatch(setProcedureToView(''));
    dispatch(fetchTemplateProcedures(searchPayload));
  }
  const closeDeleteDialogHandler = () => {
    closeDialogHandler();
    dispatch(setProcedureToView(''));
  };
 
  const closeDuplicateDialogHandler = () => {
    closeDialogHandler();
    dispatch(setProcedureToView(''));
    dispatch(fetchTemplateProcedures(searchPayload));
  };

  return (
      <>
          <Box
              component="div"
              sx={{ width: '100%', padding: '10px 30px', minHeight: '500px' }}
          >
              {loading ? (
                  <Box
                      component={'div'}
                      sx={{ height: '500px', position: 'relative' }}
                  >
                      <Loading />
                  </Box>
              ) : (
                  <Box
                      component={'div'}
                      sx={{ display: 'flex' }}
                  >
                      <ProcedureLibraryTable handleRowClick={handleRowClick} />
                      <ProcedureStepsViewer
                          procedureId={selectedProcedure}
                          onClose={handleClose}
                          isStepViewerOpen={isStepViewerOpen}
                      />
                  </Box>
              )}
          </Box>

          {/* // ------------- dialogue screens --------------  */}
          <SelectWorkInstruction
              isOpen={dialogueToOpen === PROCEDURE_LIBRARY_DIALOG.SELECT_WORK_INSTRUCTION}
              onClose={closeDialogHandler}
          />
          {selectedProcedure && (
              <>
                  <VersionDialog
                      openDialog={dialogueToOpen === PROCEDURE_LIBRARY_DIALOG.VERSION_DIALOG}
                      closeDialog={closeDialogHandler}
                      procedure={templateProcedures.find((procedure) => procedure.procedureId === selectedProcedure)}
                      style={{ padding: '24px' }}
                  />
                  <ShareQR
                      openDialog={dialogueToOpen === PROCEDURE_LIBRARY_DIALOG.SHARE_QR}
                      closeDialog={closeDialogHandler}
                      procedure={templateProcedures.find((procedure) => procedure.procedureId === selectedProcedure)}
                      workInstruction={workInstruction}
                  />
                  <DuplicateProcedure
                      openDialog={dialogueToOpen === PROCEDURE_LIBRARY_DIALOG.DUPLICATE}
                      closeDialog={closeDuplicateDialogHandler}
                      procedureId={selectedProcedure}
                  />
                  <LanguageSupport
                      openDialog={dialogueToOpen === PROCEDURE_LIBRARY_DIALOG.LANGUAGE_SUPPORT}
                      closeDialog={closeLanguageDialogHandler}
                      procedureId={selectedProcedure}
                  />
                  <DeleteModularProcedure
                      openDialog={dialogueToOpen === PROCEDURE_LIBRARY_DIALOG.DELETE}
                      isRecycle={true}
                      isDeletePermanently={false}
                      closeDialog={closeDeleteDialogHandler}
                      procedureId={selectedProcedure}
                  />
                  <TagManagerDialog
                      isOpen={dialogueToOpen === PROCEDURE_LIBRARY_DIALOG.TAG_MANAGER}
                      onClose={closeDialogHandler}
                      procedureId={selectedProcedure}
                  />
                  <LinkedWorkInstructions
                      isOpen={dialogueToOpen === PROCEDURE_LIBRARY_DIALOG.LINKED_WI}
                      onClose={closeDialogHandler}
                      procedureId={selectedProcedure}
                  />
                  <PeriodicReviewDialog
                      openDialog={dialogueToOpen === PROCEDURE_LIBRARY_DIALOG.PERIODIC_REVIEW}
                      closeDialog={closeDialogHandler}
                      workInstruction={workInstruction}
                      procedure={templateProcedures.find((procedure) => procedure.procedureId === selectedProcedure)}
                  />
                  <ManagerApproval
                      openDialog={dialogueToOpen === PROCEDURE_LIBRARY_DIALOG.MANAGER_APPROVE || dialogueToOpen === PROCEDURE_LIBRARY_DIALOG.MANAGER_REJECT}
                      closeDialog={closeDialogHandler}
                      procedure={templateProcedures.find((procedure) => procedure.procedureId === selectedProcedure)}
                      action={dialogueToOpen === PROCEDURE_LIBRARY_DIALOG.MANAGER_APPROVE ? 0 : 1}
                  />
              </>
          )}
      </>
  );
};

export default ProcedureLibrary;

export const ProcedureLibraryTable = (props) => {
  const { handleRowClick, showProcedureOptions = true, checkIsRowSelected, checkIsRowDisabled } = props;
  const {
    templateProcedures: {
      data: { data: templateProcedures, totalDocuments, totalPages, currentPage, pageSize },
    },
  } = useAppSelector((state) => state.modularProcedure);
  const dispatch = useAppDispatch();
  const { searchPayload } = useAppSelector((state) => state.modularProcedure.procedureLibrary);

  const [tableData, setTableData] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    return () => {
      // reset search payload if library is unmounted
      dispatch(setSearchPayload({}));
    }
  }, []);
  
  useEffect(() => {
    let dataToSet = templateProcedures.map((each, index) => {
      return {
        name: each.procedureName,
        steps: each.tasks_count,
        procedureId: each.procedureId,
        tags: each.tags ?? [],
        index: index,
        visibilityScope: each.visibilityScope || PROCEDURE_VISIBILITY_SCOPE.NORMAL,
        showProcedureOptions: showProcedureOptions,
        isPublished: !!each.versionId,
      };
    });
    setTableData(dataToSet as []);
  }, [templateProcedures]);

  const loadNext = () => {
    let newSearchPayload = structuredClone(searchPayload);
    if (!newSearchPayload) {
      newSearchPayload = { currPage: 1 };
    }
    if (!newSearchPayload.currPage) {
      newSearchPayload.currPage = currentPage;
    }
    
    newSearchPayload.currPage += 1;
    dispatch(fetchTemplateProcedures(newSearchPayload));
  };

  return (
    <Box
      component={'div'}
      sx={{
        width: '100%',
        ' table tbody :not(tr:hover) .show-on-hover': {
          display: 'none',
        },
        ' table tbody tr:hover .show-on-hover': {
          display: 'inline-flex',
        },
        ' table': {
          ' th:nth-child(1)': { width: '30%' },
          ' th:nth-child(2)': { width: '30%' },
          ' th:nth-child(3)': { width: '10%' },
          ' th:nth-child(4)': { width: '30%' },
        },
      }}
    >
      <InfiniteScroll 
        dataLength={templateProcedures.length} 
        next={loadNext} 
        hasMore={currentPage < totalPages} 
        loader={<p>{totalDocuments < 1 ? t('COMMON.NO_FOLDERS_OR_WORK_INSTRUCTIONS_TO_DISPLAY') : currentPage < totalPages ? t('COMMON.LOADING') : null}</p>}
        scrollableTarget={props.scrollableDivId}
      >
        <Paper elevation={0}>
          <Box component="div">
            <TableContainer
              columns={columns}
              data={tableData}
              onRowClick={handleRowClick}
              rowProps={(row) => ({
                selected: checkIsRowSelected ? checkIsRowSelected(row) : null,
                disabled: checkIsRowDisabled ? checkIsRowDisabled(row) : null,
              })}
            />
          </Box>
        </Paper>
      </InfiniteScroll>
      {!templateProcedures.length ? <ProcedureLibraryEmptyState /> : null}
    </Box>
  );
};

const TagManagerDialog = (props) => {
  const {
    templateProcedures: {
      data: { data: templateProcedures },
    },
    procedureLibrary: { searchPayload, availableTags },
  } = useAppSelector((state) => state.modularProcedure);
  const { isOpen, onClose, procedureId } = props;
  const [appliedTags, setAppliedTags] = useState<string[]>([]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    const procedure = templateProcedures.find((procedure) => procedure.procedureId === procedureId);
    let procedureTags = procedure?.tags || [];
    procedureTags = availableTags.filter(tag => procedureTags.includes(tag.tagId)).map(each => each.name);
    setAppliedTags(procedureTags);
  }, [templateProcedures, procedureId]);

  const fetchGlobalProceduresAndTags = () => {
    dispatch(fetchTemplateProcedures(searchPayload));
    getTemplateProcedureTags().then((response) => {
      dispatch(setAvailableTagAction(response.data.data));
    });
  };

  const handleSaveClick = (tags: string[]) => {
    let tagMap = {};
    availableTags.forEach((tag) => {
      tagMap[tag.name] = tag.tagId;
    });

    let tagsToAdd: string[] = [];
    let tagsToCreate: any[] = [];
    
    tags.forEach((tag) => {
      if(tagMap[tag]){
        tagsToAdd.push(tagMap[tag]);
      } else {
        tagsToCreate.push(tag)
      }
    })

    if(tagsToCreate.length){
      tagsToCreate = tagsToCreate.map(each => createNewTag(each));
    }

    Promise.all(tagsToCreate).then(createTagResponses => {
      createTagResponses.forEach(createTagResponse => {
        let createdTag = createTagResponse.data;
        tagsToAdd.push(createdTag.tagId)
      })

      return tagsToAdd;
    }).then((tags) => {
      return addTagsToTemplateProcedure(procedureId, tags)
      .then((response) => response.data.data)
      .then(() => {
        fetchGlobalProceduresAndTags();
      });
    })
  };

  const handleDeleteClick = (tagName) => {
    let tagToDelete = availableTags.filter(tag => tag.name === tagName);
    if(!tagToDelete.length) return;
    deleteTag(tagToDelete[0].tagId).then((response) => {
      fetchGlobalProceduresAndTags();
    });
  }

  return (
    <TagManager
      availableTags={availableTags.map(each => each.name)}
      isOpen={isOpen}
      onClose={onClose}
      appliedTags={appliedTags}
      handleSaveClick={handleSaveClick}
      handleDeleteClick={handleDeleteClick}
    />
  );
};
