import { ISubstitutionsSearchPayload } from '../../pages/Substitutions/SubstitutionHeader';
import { AddSubstitutionPayload, ISubstitution } from '../../pages/Substitutions/Substitutions';
import { DataResponse } from '../../shared/interfaces';
import http from '../http-common';
export const getAllSubstitutions = (payload?: ISubstitutionsSearchPayload, signal?: any) => {
  let instance = http;
  let url = '/substitute';
  return instance.get<DataResponse<ISubstitution[]>>(url, { params: payload, signal });
}
export const addSubstitution = (payload: AddSubstitutionPayload) => {
  let instance = http;
  let url = '/substitute';
  return instance.post<DataResponse<boolean>>(url, payload);
};

export const updateSubstitution = (id, payload) => {
  let instance = http;
  let url = '/substitute/' + id;
  return instance.put<DataResponse<boolean>>(url, payload);
};

export const deleteSubstitution = (id) => {
  let instance = http;
  let url = '/substitute/' + id;
  return instance.delete<DataResponse<boolean>>(url);
};
