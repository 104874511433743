import { Box, Grid, Icon, Paper, Skeleton, Stack, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import VisitCount from '../Analytics/VisitCount';
import { SetStateAction, useEffect, useRef, useState, Dispatch } from 'react';
import { Filterable, Pageable, Paginated, Searchable, Sortable } from '../../shared/interfaces';
import VisitsActionBar from './VisitsActionBar';
import { SortOption } from '../SelectSort/SelectSort';
import { FilterOption } from '../Dialog/mobileDialog/filterDialog/filterDialog';
import { debounce } from 'lodash';
import { VISIT_TYPE } from './constants';
import { PROCEDURE_STATUS, VISIT_PROCEDURE_STATUS } from '../../shared/enums';
import { InfoRecord, TRAINER_TYPES } from './VisitsHistory';
import { CanceledError } from 'axios';
import { TableListSearchIcon } from '../Icons/CdsIcons';
interface IVisitCountWindowProps<PAYLOAD extends Pageable & Sortable<any> & Searchable<any, string> & Filterable<any, any>, RESPONSE> {
    barColor: string;
    label: string;
    fetchFunction: (payload: PAYLOAD, signal: any) => Promise<Paginated<RESPONSE[]>>;
    processRecords: (data: RESPONSE[]) => any[];
    searchPayload: PAYLOAD;
    setSearchPayload: (payload: PAYLOAD) => void;
    searchKey: keyof PAYLOAD;
    sortOptions: SortOption[]; // Updated to SortOption[]
    filterOptions: FilterOption[]; // Updated to FilterOption[]
    visitType: VISIT_TYPE;
    dateRange: any;
    procedureInfo?: InfoRecord;
    userInfo?: InfoRecord;
    onClickList?: any;
    selected?: number;
    selectedId?: string;
    reset?: string;
}

export const VisitCountWindow = <PAYLOAD extends Pageable & Sortable<any> & Searchable<any, string> & Filterable<any, any>, RESPONSE>({ barColor, label, fetchFunction, processRecords, searchPayload, setSearchPayload, searchKey, sortOptions, filterOptions, visitType, dateRange, ...props }: IVisitCountWindowProps<PAYLOAD, RESPONSE>) => {
    const [data, setData] = useState<Paginated<RESPONSE[]>>();
    const [records, setRecords] = useState<any[]>([]);
    const [visitLoading, setVisitLoading] = useState<boolean>(false);
    const [selected, setSelected] = useState<any>(props.selected);
    const previousController = useRef<any>();
    const { t } = useTranslation();
    const theme = useTheme();
    const [currentPageNo, setCurrentPageNo] = useState<number>(1);
    const [hasMore, setHasMore] = useState<boolean>(false);
    const [pageLoading, setPageLoading] = useState<boolean>(false);

    useEffect(() => {
        setSelected(-1);

        const no = searchPayload?.currPage ? searchPayload?.currPage : currentPageNo;
        setVisitLoading(no === 1);
        if (previousController.current) {
            previousController.current.abort();
        }
        const controller = new AbortController();
        const signal = controller.signal;
        previousController.current = controller;
        if (visitType === VISIT_TYPE.USER_VISIT) {
            const tmp = { ...searchPayload };
            if (tmp.Types) {
                //@ts-ignore
                tmp.userType = [...tmp.Types];
                delete tmp.Types;
                Object.keys(TRAINER_TYPES).forEach((each) => {
                    tmp.userType.find((value, index) => {
                        if (value.toLowerCase() === t('ANALYTICS_SECTION.' + each).toLowerCase()) {
                            return (tmp.userType[index] = 'MENTOR_' + TRAINER_TYPES[each].toUpperCase());
                        }
                    });
                });
            }
            searchPayload = tmp;
        } else {
            const tmp = { ...searchPayload };
            if (tmp.status) {
                //@ts-ignore
                const tmpStatus = [...tmp.status];
                Object.keys(VISIT_PROCEDURE_STATUS).forEach((each) => {
                    tmpStatus.find((value, index) => {
                        if (value.toLowerCase() === t('ANALYTICS_SECTION.' + each).toLowerCase()) {
                            return (tmpStatus[index] = VISIT_PROCEDURE_STATUS[each]);
                        }
                    });
                });
                //@ts-ignore
                tmp.status = tmpStatus;
            }
            searchPayload = tmp;
        }
        let payload = {
            ...searchPayload,
            pageSize: 15,
            sortOrder: 'desc',
            currPage: searchPayload?.currPage ?? currentPageNo,
            ...(dateRange.startDate && { startDate: dateRange.startDate }),
            ...(dateRange.endDate && { endDate: dateRange.endDate }),
        };
        fetchFunction(payload, signal)
            .then((response) => {
                setData(response);
                if (no === 1) setRecords(processRecords(response.data));
                else {
                    setRecords((prev) => [...prev, ...processRecords(response.data)]);
                }
                setVisitLoading(false);
                setPageLoading(false);
                setHasMore(response.currentPage < response.totalPages);
                setCurrentPageNo(response.currentPage);
            })
            .catch((error) => {
                // Handle errors here
                if (error instanceof CanceledError) return;
                setVisitLoading(false);
            });
    }, [searchPayload]);

    const setSelectionOnChangePayload = () => {
        const index = records.findIndex((user) => user?.id?.trim() === props.selectedId?.trim());
        setSelected(index);
    };
    useEffect(() => {
        setSelectionOnChangePayload();
    }, [records]);
    useEffect(() => {
        if ((!dateRange.startDate && !dateRange.endDate) || (dateRange.startDate && dateRange.endDate)) {
            setSearchPayload({ ...searchPayload, currPage: 1 });
        }
    }, [dateRange]);

    useEffect(() => {
        const key = visitType === VISIT_TYPE.PROCEDURE_VISIT ? 'user' : 'procedureId';
        const value = visitType === VISIT_TYPE.PROCEDURE_VISIT ? props.userInfo?.id : props.procedureInfo?.id;

        let payload = {
            ...searchPayload,
            sortOrder: 'desc',
            currPage: 1,
            pageSize: 15,
            ...(dateRange.startDate && { startDate: dateRange.startDate }),
            ...(dateRange.endDate && { endDate: dateRange.endDate }),
        };
        payload[key] = value;
        if (!props.userInfo?.id) delete payload.user;
        if (!props.procedureInfo?.id) delete payload.procedureId;

        if (payload[searchKey]) delete payload[searchKey];
        if (visitType === VISIT_TYPE.PROCEDURE_VISIT) {
            if (payload.status) {
                delete payload.status;
            }
        } else {
            if (payload.Types) {
                delete payload.Types;
            }
        }
        setSearchPayload({ ...payload, currPage: 1 });
    }, [props.procedureInfo?.id, props.userInfo?.id]);

    useEffect(() => {
        setSelected(props.selected);
    }, [props.selected]);
    const onRecordClick = (id, name, index) => {
        const isProcedureVisitInvalid = visitType === VISIT_TYPE.PROCEDURE_VISIT && props.userInfo?.id;
        const isUserVisitInvalid = visitType === VISIT_TYPE.USER_VISIT && props.procedureInfo?.id;
        if (isProcedureVisitInvalid || isUserVisitInvalid) {
            return;
        }
        props.onClickList(id, name, index);
    };
    return (
        <Paper
            sx={{
                position: 'relative',
                display: 'flex',
                borderRadius: '12px',
                border: `2px solid ${theme.palette.primary.main + '1c'}`,
                overflowY: 'auto',
                height: '100%',
                width: '50%',
            }}
            elevation={0}
            className="work-instruction-card"
        >
            <Box sx={{ padding: '24px', display: 'flex', flexDirection: 'column', width: '100%', textAlign: 'left', position: 'relative' }}>
                {/* <Box sx={{ position: 'sticky', top: 0, background: 'white', zIndex: 1  }}> */}
                <Box
                    component={'div'}
                    sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}
                >
                    <Typography sx={{ fontWeight: '500', fontSize: '16px', color: 'black' }}>{label}</Typography>
                    {visitType === VISIT_TYPE.USER_VISIT && (
                        <Box
                            component={'div'}
                            sx={{ paddingInline: '8px', borderRadius: '16px', background: '#F3F7FC' }}
                        >
                            <Typography sx={{ fontWeight: '400', fontSize: '14px', opacity: '.6', color: '#1C1D1F' }}>{props.procedureInfo?.value}</Typography>
                        </Box>
                    )}
                    {visitType === VISIT_TYPE.PROCEDURE_VISIT && (
                        <Box
                            component={'div'}
                            sx={{ paddingInline: '8px', borderRadius: '16px', background: '#F3F7FC' }}
                        >
                            <Typography sx={{ fontWeight: '400', fontSize: '14px', opacity: '.6', color: '#1C1D1F' }}>{props.userInfo?.value}</Typography>
                        </Box>
                    )}
                </Box>
                {/* Dynamic VisitsActionBar */}
                <VisitsActionBar
                    searchPayload={searchPayload}
                    setSearchPayload={setSearchPayload}
                    searchKey={searchKey}
                    sortOptions={sortOptions} // Passing SortOption[]
                    filterOptions={filterOptions} // Passing FilterOption[]
                    VisitType={visitType}
                    reset={props.reset}
                />
                {/* </Box> */}
                {visitLoading ? (
                    <VisitCountLoading />
                ) : (
                    <>
                        {!data || !records?.length ? (
                            <Stack
                                direction={'column'}
                                marginInline={'auto'}
                                marginTop={'100px'}
                                justifyContent={'center'}
                                gap={'10px'}
                            >
                                <Stack
                                    direction={'row'}
                                    gap={'10px'}
                                    sx={{ justifyContent: 'center' }}
                                >
                                    <TableListSearchIcon />
                                    <Typography
                                        variant="h4"
                                        sx={{ textAlign: 'center', fontWeight: 600, fontSize: '18px', color: '#666666' }}
                                    >
                                        {' '}
                                        {t('ANALYTICS_SECTION.NO_RECORDS')}
                                    </Typography>
                                </Stack>
                                <Typography sx={{ width: '72%', textAlign: 'center', fontWeight: 400, fontSize: '14px', color: '#666666', alignSelf: 'center' }}>{t('ANALYTICS_SECTION.NO_RECORDS_CONTENT')}</Typography>
                            </Stack>
                        ) : (
                            <VisitCount
                                records={records}
                                barColor={barColor}
                                visitType={visitType}
                                onRecordClick={onRecordClick}
                                pageLoading={pageLoading}
                                setPageLoading={setPageLoading}
                                searchPayload={searchPayload}
                                setSearchPayload={setSearchPayload}
                                hasMore={hasMore}
                                currentPage={currentPageNo}
                                selected={selected}
                            />
                        )}
                    </>
                )}
            </Box>
        </Paper>
    );
};
export const VisitCountLoading = () => {
    return (
        <Grid
            container
            sx={{ height: '100%' }}
        >
            {Array.from({ length: 6 }).map((_, index) => (
                <Grid
                    item
                    xs={15}
                >
                    <Skeleton
                        width={'100%'}
                        sx={{ height: '100%', minHeight: '100%' }}
                    />
                </Grid>
            ))}
        </Grid>
    );
};
