import { Box, Typography } from '@mui/material';
import { Feedback } from '../Feedback';
import { Bar, BarChart, CartesianGrid, Cell, LabelList, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import { useTranslation } from 'react-i18next';

export const TraineesAssigned = ({ completedCount, notStartedCount, inProgressCount }) => {
    const { t } = useTranslation();
    const data = [
        {
            name: 'Completed',
            value: completedCount,
        },
        {
            name: 'In Progress',
            value: inProgressCount,
        },
        {
            name: 'Not Started',
            value: notStartedCount,
        },
    ];

    const renderCustomizedLabel = (props) => {
        const { x, y, width, height, value, name } = props;

        const fireOffset = value.toString().length < 5;
        const offset = fireOffset ? -40 : 5;
        let newData = { Completed: t('ANALYTICS_SECTION.COMPLETED_TRAINING', { value, trainee: value > 1 ? t('ANALYTICS_SECTION.TRAINEES') : t('ANALYTICS_SECTION.TRAINEE') }), 'In Progress': t('ANALYTICS_SECTION.IN_PROGRESS_TRAINING', { value, trainee: value > 1 ? t('ANALYTICS_SECTION.TRAINEES') + ' \u2b62' : t('ANALYTICS_SECTION.TRAINEE') + ' \u2b62' }), 'Not Started': t('ANALYTICS_SECTION.NOT_STARTED_TRAINING', { value, trainee: value > 1 ? t('ANALYTICS_SECTION.TRAINEES') : t('ANALYTICS_SECTION.TRAINEE') }) };
        return (
            <text
                style={{ fontSize: '14px', color: 'black', marginLeft: '100px', paddingLeft: '100px' }}
                x={x + width - offset - 30}
                y={y + height - 12}
                fill={fireOffset ? 'black' : 'black'}
            >
                {/* {name + ': ' + value} {value !== 1 ? 'Trainees' : 'Trainee'} */}
                {newData[name]}
            </text>
        );
    };

    const renderCustomizedLabelName = (props) => {
        const { x, y, width, height, value } = props;

        return (
            <text
                x={x}
                y={y}
                dy={-10}
                textAnchor="start"
                style={{ fontSize: '12px', marginBottom: '12px' }}
            >
                {value}
            </text>
        );
    };

    interface CustomTooltipProps {
        active?: any;
        payload?: any;
        label?: any;
    }

    const CustomTooltip = ({ active, payload, label }: CustomTooltipProps) => {
        const { t } = useTranslation();
        const value = payload[0]?.payload?.value;
        let newData = { Completed: t('ANALYTICS_SECTION.COMPLETED_TRAINING', { value, trainee: value > 1 ? t('ANALYTICS_SECTION.TRAINEES') : t('ANALYTICS_SECTION.TRAINEE') }), 'In Progress': t('ANALYTICS_SECTION.IN_PROGRESS_TRAINING', { value, trainee: value > 1 ? t('ANALYTICS_SECTION.TRAINEES') : t('ANALYTICS_SECTION.TRAINEE') }), 'Not Started': t('ANALYTICS_SECTION.NOT_STARTED_TRAINING', { value, trainee: value > 1 ? t('ANALYTICS_SECTION.TRAINEES') : t('ANALYTICS_SECTION.TRAINEE') }) };
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip">
                    <p className="label">
                        {newData[payload[0]?.payload?.name]}
                        {/* {`${payload[0]?.payload?.name}: `}
                        <strong>{`${payload[0]?.payload?.value} Trainees`}</strong> */}
                    </p>
                </div>
            );
        }

        return null;
    };

    const barColors = [
        { start: '#59BFAB', end: '#ACE8DA' },
        { start: '#DAE187', end: '#EAEDCB' },
        { start: '#E47D62', end: '#E9B9AD' },
    ];

    return (
        <Box
            component="div"
            sx={{ display: 'flex', flexGrow: 1, flexDirection: 'column', height: '100%' }}
        >
            <Box
                component="div"
                sx={{ display: 'flex', flexGrow: 1, flexDirection: 'row', justifyContent: 'space-between', mb: '30px' }}
            >
                <Feedback
                    title={t('ANALYTICS_SECTION.TRAINEES_ASSIGNED')}
                    value={inProgressCount + notStartedCount + completedCount}
                />
                <PeopleAltOutlinedIcon sx={{ fontSize: '40px', color: '#60C3AF' }} />
            </Box>
            <ResponsiveContainer width={'100%'}>
                <BarChart
                    className="outline"
                    data={data}
                    layout="vertical"
                    barCategoryGap={1}
                    barSize={36}
                    margin={{ right: 190, top: 0 }}
                >
                    <XAxis
                        type="number"
                        hide
                    />
                    <YAxis
                        type="category"
                        dataKey="name"
                        tickLine={false}
                        hide
                        tick={{ fontSize: '12px' }}
                    />
                    <defs>
                        {barColors.map((color, index) => (
                            <linearGradient
                                key={index}
                                id={`colorUv${index}`}
                                x1="0"
                                y1="0"
                                x2="100%"
                                y2="0"
                                spreadMethod="reflect"
                            >
                                <stop
                                    offset="0"
                                    stopColor={color.start}
                                />
                                <stop
                                    offset="1"
                                    stopColor={color.end}
                                />
                            </linearGradient>
                        ))}
                    </defs>
                    <Bar
                        isAnimationActive={false}
                        dataKey="value"
                        radius={[0, 6, 6, 0]}
                    >
                        {data.map((entry, index) => (
                            <Cell
                                key={`cell-${index}`}
                                fill={`url(#colorUv${index})`}
                            />
                        ))}
                        <LabelList
                            dataKey="value"
                            position="right"
                            content={renderCustomizedLabel}
                            fill="black"
                        />
                        {/* <LabelList dataKey="name" content={renderCustomizedLabelName} fill="black" /> */}
                    </Bar>
                    <Tooltip
                        cursor={{ fill: 'transparent' }}
                        wrapperStyle={{ outline: 'none' }}
                        content={<CustomTooltip />}
                    />
                </BarChart>
            </ResponsiveContainer>
        </Box>
    );
};
