import { Box, Button, debounce, Paper, Tab, Tabs, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SearchBar from '../../components/SearchBar/SearchBar';
import SelectSort from '../../components/SelectSort/SelectSort';
import FilterButton from '../../components/FilterButton/FilterButton';
import { SortDialog } from '../../components/Dialog/mobileDialog/sortDialog/sortDialog';
import { useCallback, useEffect, useState } from 'react';
import { ENTITY_TYPE } from '../../shared/enums';

export const RecycleBinTopBar = ({ setAppliedFilterList, sort, setSort, isTrainer, overview, setOverview, loadDeleteData, totalDocumentsLength, setCurrPage, setSearchVal, searchVal, filterVal, setFilterVal, setSortVal, sortVal, applyFilters }) => {
    const { t } = useTranslation();
    const [selectedFilters, setSelectedFilters] = useState<string>('');

    const [open, setOpen] = useState(false);
    const [filterOpen, setFilterOpen] = useState(false);
    const [sortOptions, setSortOptions] = useState(['Sort', 'Date Added', 'Alphabetical']);
    const [filter, setFilter] = useState<string>('');
    const debouncedSearch = useCallback(
        debounce((value) => {
            loadDeleteData(value, sortVal, filterVal, 1);
            setCurrPage(1);
        }, 500),
        [],
    );

    const search = (e) => {
        const { value } = e.target;
        setSearchVal(value);
        debouncedSearch(value);
    };
    const [filterOptionsTrainer, setFilterOptionsTrainer] = useState([
        {
            filter: 'Type',
            filterValues: [t(`ADD_WI.FOLDER`), t(`ADD_WI.WORK_INSTRUCTION`), t(`ADD_WI.PROCEDURE`), t(`ADD_WI.PROCEDURE_LIBRARY`)],
        },
    ]);
    const sortFilter = (sort: string) => {
        setCurrPage(1);
        if (sort === 'type') {
            setSortVal('type');
            loadDeleteData(searchVal, 'type', filterVal, 1);
        } else {
            setSortVal('deletedOn');
            loadDeleteData(searchVal, 'deletedOn', filterVal, 1);
        }
    };

    const onFilterClose = () => {
        setFilterOpen(false);
    };
    const onSortClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (filterVal) {
            const newSelectedFilters: any = {};

            filterVal.forEach((value) => {
                const normalizedValue = value.trim().toLowerCase();
                const matchedKey = Object.entries(ENTITY_TYPE).find(([key, entityValue]) => entityValue.trim().toLowerCase() === normalizedValue);
                if (matchedKey) {
                    const [key] = matchedKey;
                    if (!newSelectedFilters['type']) {
                        newSelectedFilters['type'] = [];
                    }
                    newSelectedFilters['type'].push(t('ADD_WI.' + key));
                }
            });
            setSelectedFilters(newSelectedFilters);
        }
    }, [filterVal]);
    const convertSelectedFiltersToString = (selectedFilters) => {
        return Object.keys(selectedFilters)
            .filter((key) => selectedFilters[key] && selectedFilters[key].length > 0)
            .map((key) => `${key}:${selectedFilters[key].join('|')}`)
            .join(',');
    };

    const filterString = convertSelectedFiltersToString(selectedFilters);

    return (
        <>
            <SortDialog
                open={open}
                sort={sort}
                setSort={setSort}
                sortFilter={sortFilter}
                sortOptions={sortOptions}
                onClose={onSortClose}
            ></SortDialog>
            <Paper
                elevation={0}
                className="instructions-title-container"
                sx={{ display: 'flex' }}
            >
                <Box
                    component="div"
                    className="left"
                >
                    <Typography
                        sx={{ fontSize: '24px', fontWeight: 600 }}
                        component="div"
                        className="page-title"
                    >
                        {' '}
                        {t('NAVBAR.RECYCLE_BIN')}{' '}
                    </Typography>
                    {totalDocumentsLength > 0 && (
                        <Typography
                            sx={{
                                bgcolor: 'grey.300',
                                color: '#000',
                                padding: '4px 4px',
                                fontSize: '20px',
                                fontWeight: 500,
                                display: 'flex',
                                flexDirection: 'row',
                                borderRadius: '8px',
                            }}
                        >
                            <Typography>{totalDocumentsLength}</Typography>
                        </Typography>
                    )}
                </Box>

                <Box
                    component="div"
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        alignItems: 'center',
                        gap: '5px',
                    }}
                >
                    <SearchBar onChangeHandler={search} />
                    <SelectSort
                        sortOptions={[
                            { value: 'type', label: 'WI_LIST.TYPE' },
                            { value: 'deletedOn', label: 'WI_LIST.DELETED_ON' },
                        ]}
                        onChange={(value) => {
                            setSort(value);
                            sortFilter(value);
                        }}
                        value={sort}
                    />
                    <FilterButton
                        selectedFilters={filterString}
                        onApplyFilterClick={applyFilters}
                        onFilterClose={onFilterClose}
                        filterOptions={[{ filter: 'type', filterValues: Object.keys(ENTITY_TYPE).map((each) => t('ADD_WI.' + each)) }]}
                    />
                    <Box component="div">
                        <Tabs
                            value={overview === 'normal_view' ? 0 : overview === 'extended_view' ? 1 : 2}
                            onChange={(e, newTabIndex) => {
                                setOverview(newTabIndex === 0 ? 'normal_view' : newTabIndex === 1 ? 'extended_view' : 'table_view');
                            }}
                            sx={{ '& .MuiTabs-indicator': { display: 'none' }, marginRight: '8px' }}
                        >
                            <Tab
                                sx={{ padding: 0, minWidth: 'unset' }}
                                label={
                                    <Button
                                        size="small"
                                        variant={overview === 'normal_view' ? 'contained' : 'outlined'}
                                        sx={{ margin: 0, minWidth: '32px', height: '40px', borderRadius: '8px 0px 0px 8px' }}
                                    >
                                        <link
                                            rel="stylesheet"
                                            href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200"
                                        />
                                        <span className="material-symbols-outlined">grid_view</span>
                                    </Button>
                                }
                            />
                            <Tab
                                sx={{ padding: 0, minWidth: 'unset' }}
                                label={
                                    <Button
                                        size="small"
                                        variant={overview === 'extended_view' ? 'contained' : 'outlined'}
                                        sx={{ margin: 0, minWidth: '32px', height: '40px', borderRadius: '0px 0px 0px 0px' }}
                                    >
                                        <span className="material-symbols-outlined">apps</span>
                                    </Button>
                                }
                            />
                            <Tab
                                sx={{ padding: 0, minWidth: 'unset' }}
                                label={
                                    <Button
                                        size="small"
                                        variant={overview === 'table_view' ? 'contained' : 'outlined'}
                                        sx={{ margin: 0, minWidth: '32px', height: '40px', borderRadius: '0px 8px 8px 0px' }}
                                    >
                                        <span className="material-symbols-outlined">list</span>
                                    </Button>
                                }
                            />
                        </Tabs>
                    </Box>
                </Box>
            </Paper>
        </>
    );
};
