import { Box, Container } from '@mui/material';
import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../hooks/redux';
import { fetchAllSubstitutions } from '../../redux/features/Substitutions/Substitutions.slice';
import { addSubstitution } from '../../services/Substitution/SubstitutionService';
import { MongoDBBaseDocument } from '../../shared/interfaces';
import AddSubstitutionModal from './AddSubstitutionModal';
import SubstitutionsHeader from './SubstitutionHeader';
import SubstitutionsContainer from './SubstitutionsContainer';
import { Toast } from '../../components/Notifications/Notification';
import { useTranslation } from 'react-i18next';
export interface ISubstitution extends MongoDBBaseDocument {
    // byDefaultLanguage: string;
    substitute: string;
    language: string;
    word: string;
}
export type AddSubstitutionPayload = {
    // byDefaultLanguage: string;
    language: string;
    word: string;
    substitute: string;
    organizationId?: string;
};
const Substitutions = (props) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    useEffect(() => {
        // dispatch(fetchAllSubstitutions());
    }, []);

    const handleSaveClick = (payload: AddSubstitutionPayload[]) => {
        const promises = payload.map((eachPayload) => addSubstitution(eachPayload));
        Promise.all(promises)
            .then((res) => {
                dispatch(fetchAllSubstitutions());
                Toast.success(t('BUTTON.OPERATION_SUCCESS'),`${t("SUBSTITUTION.NEW_WORD",{word:payload[0].word})}`);
            })
            .catch((error) => {
                Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(error.response.data.messageCode, { ns: 'error' }) || error.response.data.errorMessage);
            })
            .finally(() => {
                setIsModalOpen(false);
            });
    };

    return (
        <Container
            maxWidth={false}
            sx={{
                marginTop: '16px',
                // paddingBottom:"32px",
                textAlign: 'left',
                position: 'relative',
            }}
        >
            <Box sx={{ display: 'flex', gap: '16px', flexDirection: 'column' }}>
                <SubstitutionsHeader setIsModalOpen={setIsModalOpen} />
                <SubstitutionsContainer />
            </Box>
            {isModalOpen && (
                <AddSubstitutionModal
                    isOpen={isModalOpen}
                    handleClose={() => setIsModalOpen(false)}
                    handleSaveClick={handleSaveClick}
                />
            )}
        </Container>
    );
};

export default Substitutions;
