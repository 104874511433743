import { createSlice } from '@reduxjs/toolkit';
import { ANALYTICS_SUPPORTED_VIEWS, ANALYTICS_TYPES, ENTITY, PROCEDURE_LOCATION, SELECT_DATARANGE_OPTIONS } from '../../../shared/enums';
import moment from 'moment';
import { IDateRange } from '../../../entities/Analytics/AnalyticsProcedure';
import { Filterable, Pageable, Searchable, Sortable } from '../../../shared/interfaces';
import { VISIT_HISTORY_DIALOGUE } from '../../../components/VisitsHistory/constants';
export interface IProcedureListPageSearchPayload extends Pageable, Searchable<'search', string>, Sortable<'createdOn' | 'procedureName'>, Filterable<'status', string[]>, Filterable<'procedureLocation', PROCEDURE_LOCATION[]> {
    startDate?: string;
    endDate?: string;
    user?: string;
}

type InitialState = {
    header: {
        selectedOverview: ANALYTICS_SUPPORTED_VIEWS;
        selectedDateRangeOption: SELECT_DATARANGE_OPTIONS;
        selectedAnalytics: ANALYTICS_TYPES;
        selectedEntity: ENTITY;
        dateRange: IDateRange;
    };
    procedureListPage: {
        searchPayload: IProcedureListPageSearchPayload;
    };
    visitHistory: {
        dialogueToShow: VISIT_HISTORY_DIALOGUE | null;
    };
};

const initialState: InitialState = {
    header: {
        selectedOverview: ANALYTICS_SUPPORTED_VIEWS.PERSONAL,
        selectedDateRangeOption: SELECT_DATARANGE_OPTIONS.CURRENT_MONTH,
        selectedAnalytics: ANALYTICS_TYPES.TRAINER_ANALYTICS,
        selectedEntity: ENTITY.WORK_INSTRUCTION,
        dateRange: {
            startDate: moment().startOf('month').format('DD-MM-YYYY'),
            endDate: moment().format('DD-MM-YYYY'), // today
        },
    },
    procedureListPage: {
        searchPayload: {},
    },
    visitHistory: {
        dialogueToShow: null,
    },
};

const analyticsSlice = createSlice({
    name: 'analytics',
    initialState: initialState,
    reducers: {
        setSelectedOverview: (state, action) => {
            state.header.selectedOverview = action.payload;
        },
        setSelectedDateRangeOption: (state, action) => {
            state.header.selectedDateRangeOption = action.payload;
        },
        setSelectedAnalytics: (state, action) => {
            state.header.selectedAnalytics = action.payload;
        },
        setSelectedEntity: (state, action) => {
            state.header.selectedEntity = action.payload;
        },
        setDateRange: (state, action) => {
            state.header.dateRange = action.payload;
        },
        setProcedureListSearchPayload: (state, action) => {
            state.procedureListPage.searchPayload = action.payload;
        },
        setVisitHistoryDialogue: (state, action) => {
            state.visitHistory.dialogueToShow = action.payload;
        },
    },
});

export default analyticsSlice.reducer;
export const { setSelectedAnalytics, setSelectedDateRangeOption, setSelectedEntity, setSelectedOverview, setDateRange, setProcedureListSearchPayload, setVisitHistoryDialogue } = analyticsSlice.actions;
