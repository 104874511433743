import { Box, Button, FormControl, MenuItem, Select, Skeleton, Typography, lighten, useTheme, Divider, TextField, FormHelperText, FormControlLabel, CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useMemo, useState } from 'react';
import { useLocation} from 'react-router-dom';
import { IDateRange } from '../../../entities/Analytics/AnalyticsProcedure';
import { t } from 'i18next';
import AnalyticsService from '../../../services/Analytics/AnalyticsService';
import { Toast } from '../../Notifications/Notification';
import TableContainer, { SignOffColumnFilter} from '../ProcedureReports/TableContainer';
import './TrainingSignOff.scss';
import Drawer from '@mui/material/Drawer';
import TraineeService from '../../../services/trainee/TraineeService';
import { useAppSelector } from '../../../hooks/redux';
import { getFormattedTimeFromUnixTime } from '../../../shared/utils/formateDate';
import { useTranslation } from 'react-i18next';

const SignOffHeader = ({ title, toggleDrawer }) => {
  const theme = useTheme();
  return (
    <>
      <Box component={'div'} sx={{ display: 'flex', justifyContent: 'space-between', backgroundColor: theme.palette.primary.main + '1c !important', padding: '32px 32px 0px 32px ', alignItems: 'center' }}>
        <Typography>{title}</Typography>
        <Button
          sx={{ minWidth: 0 }}
          onClick={() => {
            toggleDrawer(false);
          }}
        >
          <CloseIcon />
        </Button>
      </Box>
    </>
  );
};

export const TrainingSignOff = () => {
  const {dateRange: currentDateRange} = useAppSelector(state => state.analytics.header)
  const [allRecords, setAllRecords] = useState<Array<any>>([]);
  const [loading, setLoading] = useState(false);
  const [showRightPane, setShowRightPane] = useState<boolean>(false);
  const [activeRow, setActiveRow] = useState();
  const [activeStatus, setActiveStatus] = useState(activeRow ? allRecords[activeRow]?.signOffStatus : '');
  const [additionalComments, setAdditionalComments] = useState('');
  const [error, setError] = useState('');
  const [signOffLoading, setSignOffLoading] = useState(false);
  const { search: searchQuery } = useLocation();
  // const currentDateRange = useAnalytics((state) => state.selectedDateRange);
  const query = new URLSearchParams(searchQuery);
  const startDate = query.get('startDate');
  const endDate = query.get('endDate');
  const filterType = query.get('filter');

  const theme = useTheme();
  const {t} = useTranslation();
  const signOffStatus = {
    Approved: 'Approved',
    Rejected: 'Rejected',
    Pending: 'Pending',
  };

  const columns = useMemo(
    () => [
      {
        Header: t('ANALYTICS_SECTION.PROCEDURE_NAME'),
        accessor: 'procedure',
        Cell: ({ value }) => (
          <Box component="div" sx={{ display: 'flex', flexDirection: 'column', padding: '20px', alignItems: 'flex-start' }}>
            <Typography sx={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: '12px' }}>{value.wiName}</Typography>
            <Typography sx={{ fontSize: '14px', margin: '8px 0px 8px', minWidth: 0, padding: 0, color: '#004b8d' }}>{value.procedureName}</Typography>
            <Typography className="feedback" sx={{ background: lighten(theme.palette.primary.main, 0.95), color: 'rgba(0, 0, 0, 0.6)', marginLeft: '0 !important' }}>
               {t('ANALYTICS_SECTION.VERSION',{versionName:value.versionName})}
            </Typography>
          </Box>
        ),
      },
      {
        Header:t('ANALYTICS_SECTION.TRAINEE'),
        accessor: 'trainee',
        Cell: ({ value }) => value.id ? (`${value.firstName} ${value.lastName}`) : "-",
      },
      {
        Header: t('ANALYTICS_SECTION.COMPLETION_DATE'),
        accessor: 'completedOn',
        Cell: ({ value }) => <span>{value ? getFormattedTimeFromUnixTime(value*1000) : '-'}</span>,
      },
      {
        Header: t('ANALYTICS_SECTION.SIGNED_OFF_DATE'),
        accessor: 'signOffOn',
        Cell: ({ value }) => <span>{value ? getFormattedTimeFromUnixTime(value*1000) : '-'}</span>,
      },
     
      {
        Header: t('ANALYTICS_SECTION.SIGNED_OFF_STATUS'),
        accessor: 'signOffStatus',
        disableSortBy: true,
        Filter: SignOffColumnFilter,
        Cell: ({ value }) => <span className={value === 'Approved' ? 'status-success-color' : value === 'Pending' ? 'status-warning-color' : 'status-error-color'}>{value}</span>,
      },
      {
        Header: t('ANALYTICS_SECTION.APPROVER'),
        accessor: 'signOffUsers',
        Cell: ({ value }) => {
          const names: string[] = [];
          value.map((val) => {
            if(val.id)
              names.push(val.firstName + ' ' + val.lastName);
          });
          return <span>{names.join(', ') || "-"}</span>;
        },
      },
      {
        Header: t('ANALYTICS_SECTION.DOWNLOAD'),
        accessor: 'downloadPDF',
        disableSortBy: true,
        Cell: ({ value }) => {
          const [signOffPdfLoading, setSignOffPdfLoading] = useState(false);
          return (
            <Box component="div" className="left">
              {signOffPdfLoading? (
                <CircularProgress sx={{height: '25px !important', width: '25px !important' }}/>
              ) : (
                <Button
                  sx={{
                    margin: 0,
                    padding: 0,
                    minWidth: 0,
                  }}
                  onClick={(e) => handlePdfClick(e, value, setSignOffPdfLoading)}
                >
                  {t('ANALYTICS_SECTION.PDF')}
                </Button>
              )}
            </Box>
          );
        },
      },
    ],
    [],
  );

  useEffect(() => {
    if (currentDateRange && currentDateRange.startDate && currentDateRange.endDate) {
      getTrainingAnalytics(currentDateRange);
    } else {
      getTrainingAnalytics({
        startDate: startDate,
        endDate: endDate,
      } as IDateRange);
    }
  }, [currentDateRange]);

  const getTrainingAnalytics = (dateRange: IDateRange) => {
    setLoading(true);

    AnalyticsService.getTrainingAnalytics(dateRange.startDate, dateRange.endDate)
      .then((e) => {
        const {
          data: { data: records },
        } = e;

        const arr: any = [];
        records?.map((procedure) => {
          arr.push({
            procedure: { procedureId: procedure.procedureId, workInstructionId: procedure.workInstructionId, procedureName: procedure.procedureName, wiName: procedure.workInstructionName, versionId: procedure.versionId, versionName: procedure.versionName },
            trainee: {
              email: procedure.trainee.email,
              firstName: procedure.trainee.firstName,
              id: procedure.trainee.id,
              lastName: procedure.trainee.lastName,
            },
            signOffUsers: procedure.signOffUsers,
            signOffUser: procedure.signOffUser,
            signOffComments: procedure.signOffComments,
            signOffStatus: procedure.signOffStatus,
            completedOn: procedure.endTimeStamp,
            signOffOn: procedure.signOffOn,
            questions: procedure.questions,
            historyIndex: procedure.historyIndex,
            downloadPDF: {
              versionId: procedure.versionId,
              id: procedure.trainee.id,
              procedureName: procedure.procedureName,
              firstName: procedure.trainee.firstName,
              lastName: procedure.trainee.lastName,
              historyIndex: procedure.historyIndex,
            },
          });
        });
        setAllRecords(arr);
        setLoading(false);
      })
      .catch(({ response }) => {
        Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
        setLoading(false);
      });
  };

  const toggleDrawer = (status) => {
    setShowRightPane(status);
    if (status === false) {
      setActiveRow(undefined);
      setError('');
      setActiveStatus('');
      setAdditionalComments('');
    }
  };

  const onRowClick = (row) => {
    setActiveRow(row.id);
    setActiveStatus(allRecords[row.id]?.signOffStatus || '');
    toggleDrawer(true);
  };

  const submitSignOff = () => {
    if (activeStatus === 'Rejected' && !additionalComments) {
      setError('Please enter reason for rejection');
    } else {
      setSignOffLoading(true);
      const signOffBody = {
        status: activeStatus,
        signOffComments: additionalComments,
      };
      if (activeRow)
        TraineeService.signOff(allRecords[activeRow].procedure.versionId, allRecords[activeRow]?.trainee?.id, allRecords[activeRow]?.historyIndex, signOffBody)
          .then((e) => {
            setSignOffLoading(false);
            let modifiedRecords = [...allRecords];
            modifiedRecords[activeRow].signOffOn = e.data.data.signOffOn;
            modifiedRecords[activeRow].signOffStatus = e.data.data.signOffStatus;
            setAllRecords(modifiedRecords);
            Toast.success(t('OPERATION_SUCCESS', { ns: 'success' }), 'Sign Off Submitted Succesfully');
          })
          .catch(({ response }) => {
            setSignOffLoading(false);
            Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
            setLoading(false);
          });
    }
  };

  const handlePdfClick = (event, downloadValues, setSignOffPdfLoading) => {
    event?.stopPropagation();
    setSignOffPdfLoading(true);
    TraineeService.signOffPdf(downloadValues.versionId, downloadValues.id,downloadValues.historyIndex)
      .then((e) => {
        var file = new Blob([e.data], { type: 'application/pdf' });
        var fileURL = URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = fileURL;
        link.setAttribute('download', `${downloadValues.procedureName}_${downloadValues.firstName}_${downloadValues.lastName}_report.pdf`);
        document.body.appendChild(link);
        link.click();
        if (link && link.parentNode) link.parentNode.removeChild(link);
        window.open(URL.createObjectURL(file), '_blank');
        Toast.success(t('OPERATION_SUCCESS', { ns: 'success' }), 'Reports fetched Succesfully');
        setSignOffPdfLoading(false);
      })
      .catch(({ response }) => {
        setSignOffPdfLoading(false);
        Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
        setLoading(false);
      });
  };

  return (
      <>
          <Drawer
              anchor={'right'}
              open={showRightPane}
              onClose={() => toggleDrawer(false)}
              PaperProps={{
                  sx: { width: '35%' },
              }}
          >
              <SignOffHeader
                  title={'Procedure answers'}
                  toggleDrawer={toggleDrawer}
              />
              <Box
                  component="div"
                  sx={{ display: 'flex', flexDirection: 'column', backgroundColor: theme.palette.primary.main + '1c', padding: '32px 0px 0px 32px', flexGrow: 1 }}
              >
                  <Box
                      component="div"
                      sx={{ display: 'flex', alignItems: 'center' }}
                  >
                      <Typography sx={{ marginRight: '100px' }}>Sign-Off Status</Typography>
                      <FormControl style={{ minWidth: 200 }}>
                          <Select
                              value={activeStatus}
                              sx={{ backgroundColor: 'white' }}
                              disabled={
                                  activeRow &&
                                  (allRecords[activeRow]?.signOffStatus !== 'Pending' ||
                                      !allRecords[activeRow]?.signOffUsers.find((item) => {
                                          return item.id === localStorage.getItem('currentUserId');
                                      }))
                              }
                              onChange={(e) => {
                                  setError('');
                                  setActiveStatus(e.target.value);
                              }}
                          >
                              {Object.keys(signOffStatus).map((key) => (
                                  <MenuItem
                                      key={key}
                                      sx={{ display: key === 'Pending' ? 'none' : '' }}
                                      value={signOffStatus[key]}
                                  >
                                      {signOffStatus[key]}
                                  </MenuItem>
                              ))}
                          </Select>
                      </FormControl>
                  </Box>
                  {allRecords && activeRow && allRecords[activeRow].questions && (
                      <>
                          <Typography sx={{ m: '32px 0px', fontSize: '14px', opacity: 0.6 }}>Questions answered</Typography>
                          <Box
                              component="div"
                              sx={{ overflowY: 'scroll', mb: activeRow && allRecords[activeRow].signOffStatus === 'Pending' && activeStatus !== 'Pending' ? '280px' : '' }}
                          >
                              {allRecords &&
                                  activeRow &&
                                  allRecords[activeRow].questions.map((item, index) => {
                                      return (
                                          <Box
                                              component="div"
                                              sx={{ borderBottom: '1px dotted gray', mb: '32px', pb: '32px' }}
                                              key={item}
                                          >
                                              <Typography sx={{ fontSize: '12px', opacity: '0.5', mb: '8px' }}>Question {index + 1}</Typography>
                                              <Typography sx={{ fontSize: '16px', color: theme.palette.primary.main, mb: '12px' }}>{item.questionText}</Typography>
                                              <Typography sx={{ fontSize: '14px' }}>{item.questionType === 'multipleChoice' ? item.multipleSelectAnswers[item.answer] : item.answer}</Typography>
                                          </Box>
                                      );
                                  })}
                          </Box>
                      </>
                  )}
                  {showRightPane && allRecords && activeRow && (
                      <Box
                          component="div"
                          sx={{
                              position: 'fixed',
                              bottom: 0,
                              right: 0,
                              backgroundColor: 'white',
                              flexDirection: 'column',
                              width: '35%',
                              height: '290px',
                              display: allRecords[activeRow].signOffStatus === 'Pending' && activeStatus !== 'Pending' ? 'flex' : 'none',
                              alignItems: 'center',
                          }}
                      >
                          <Divider />

                          <FormControl sx={{ textAlign: 'center', width: '70%' }}>
                              <FormControlLabel
                                  control={<></>}
                                  label={
                                      <Typography
                                          paddingX={2}
                                          paddingY={1}
                                          sx={{ float: 'left', mt: '24px', fontSize: '12px', opacity: '0.8' }}
                                      >
                                          Additional Comments {activeStatus === 'Rejected' ? '*' : ''}
                                      </Typography>
                                  }
                              />
                              <TextField
                                  id="outlined-basic"
                                  multiline
                                  variant="outlined"
                                  minRows={4}
                                  inputProps={{ maxLength: 500 }}
                                  value={additionalComments}
                                  onChange={(e) => {
                                      setError('');
                                      setAdditionalComments(e.target.value);
                                  }}
                              />
                              <FormHelperText className="helperText">{500 - additionalComments.length} Characters Remaining</FormHelperText>
                              <FormHelperText
                                  error
                                  sx={{ ml: 0 }}
                              >
                                  {error?.toString()}
                              </FormHelperText>
                          </FormControl>
                          <Button
                              variant="contained"
                              sx={{ m: '24px 0px 24px 0px', width: '140px' }}
                              disabled={allRecords[activeRow].signOffStatus !== 'Pending'}
                              onClick={() => {
                                  submitSignOff();
                              }}
                          >
                              Submit
                              {signOffLoading && <CircularProgress sx={{ color: 'white', ml: '8px !important', height: '25px !important', width: '25px !important' }} />}
                          </Button>
                      </Box>
                  )}
              </Box>
          </Drawer>
          <Box
              component={'div'}
              sx={{ display: 'flex', flexDirection: 'column' }}
          >
              <Box
                  component="div"
                  sx={{ textAlign: 'left' }}
              >
                  <Typography sx={{ fontSize: '14px', mb: '32px', textTransform: 'uppercase' }}>{t('ANALYTICS_SECTION.SIGN_OFF_ANALYTICS')}</Typography>
              </Box>
              {loading && (
                  <Box
                      component="div"
                      sx={{ display: 'flex', flexGrow: 1 }}
                  >
                      <Skeleton
                          variant="rectangular"
                          height={'500px'}
                          width="100%"
                      />
                  </Box>
              )}

              {!loading && allRecords && (
                  <TableContainer
                      columns={columns}
                      data={allRecords}
                      onRowClick={onRowClick}
                  />
              )}
          </Box>
      </>
  );
};
