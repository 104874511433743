import styled from '@emotion/styled';
import { CancelOutlined, EditNote, FindInPageOutlined, PendingOutlined, PeopleAltOutlined, PublishOutlined } from '@mui/icons-material';
import { Box, Grid, IconButton, Paper, Skeleton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { setDateRange as setDateRangeAction, setSelectedDateRangeOption as setSelectedDateRangeOptionAction } from '../../redux/features/Analytics/Analytics.slice';
import AnalyticsService from '../../services/Analytics/AnalyticsService';
import { ANALYTICS_SUPPORTED_VIEWS, ENTITY, PROCEDURE_LOCATION} from '../../shared/enums';
import './Analytics.scss';
import DateRangeSelector from '../DateRangeSelector/DateRangeSelector';
import { useNavigate } from 'react-router-dom';

export const PROCEDURE_STATUS_CARDS = {
    DRAFT: {
        key: 'draft',
        icon: <EditNote />,
        label: 'ANALYTICS_SECTION.DRAFT',
        color: '#3B82F6',
    },
    APPROVAL_PENDING: {
        key: 'approvalPending',
        icon: <PendingOutlined />,
        label: 'ANALYTICS_SECTION.APPROVAL_PENDING',
        color: '#FFC107',
    },
    PUBLISHED: {
        key: 'published',
        icon: <PublishOutlined />,
        label: 'ANALYTICS_SECTION.PUBLISHED',
        color: '#35D0D0',
    },
    ASSIGNED: {
        key: 'assigned',
        icon: <PeopleAltOutlined />,
        label: 'ANALYTICS_SECTION.ASSIGNED',
        color: '#035749',
    },
    PERIODIC_REVIEW: {
        key: 'periodicReview',
        icon: <FindInPageOutlined />,
        label: 'ANALYTICS_SECTION.PERIODIC_REVIEW',
        color: '#BB41EB',
    },
    REJECTED: {
        key: 'rejected',
        icon: <CancelOutlined />,
        label: 'ANALYTICS_SECTION.REJECTED',
        color: '#F74949',
    },
};
const TrainerOverview = ({}) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const { selectedOverview, selectedEntity, dateRange, selectedDateRangeOption } = useAppSelector((state) => state.analytics.header);
    const { userId } = useAppSelector((state) => state.app.currentUser); //@ts-ignore

    const [allTimeOverview, setAllTimeOverview] = useState({});
    const [isAllTimeOverviewLoading, setIsAllTimeOverviewLoading] = useState<boolean>(false);

    const [timedOverview, setTimedOverview] = useState({});
    const [isTimedOverviewLoading, setIsTimedOverviewLoading] = useState<boolean>(false);

    useEffect(() => {
        setIsAllTimeOverviewLoading(true);
        AnalyticsService.getTrainerAnalyticsOverview({
            procedureLocation: selectedEntity === ENTITY.WORK_INSTRUCTION ? PROCEDURE_LOCATION.WORK_INSTRUCTION : PROCEDURE_LOCATION.ALL,
            user: selectedOverview === ANALYTICS_SUPPORTED_VIEWS.PERSONAL ? userId : undefined,
        })
            .then((data) => {
                setAllTimeOverview(data);
            })
            .finally(() => {
                setIsAllTimeOverviewLoading(false);
            });
    }, [selectedOverview, selectedEntity]);

    useEffect(() => {
        setIsTimedOverviewLoading(true);
        AnalyticsService.getTrainerAnalyticsOverview({
            procedureLocation: selectedEntity === ENTITY.WORK_INSTRUCTION ? PROCEDURE_LOCATION.WORK_INSTRUCTION : PROCEDURE_LOCATION.ALL,
            user: selectedOverview === ANALYTICS_SUPPORTED_VIEWS.PERSONAL ? userId : undefined,
            start: dateRange.startDate,
            end: dateRange.endDate,
        })
            .then((data) => {
                setTimedOverview(data);
            })
            .finally(() => {
                setIsTimedOverviewLoading(false);
            });
    }, [selectedOverview, selectedEntity, dateRange]);

    const setDateRange = (dateRange) => {
        dispatch(setDateRangeAction(dateRange));
    }

    const setSelectedDateRangeOption = (dateRangeOption) => {
        dispatch(setSelectedDateRangeOptionAction(dateRangeOption));
    }

    return (
        <Box
            component={'div'}
            sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}
        >
            <OverviewComponent
                overviewLabel={
                    <Typography
                        variant="h6"
                        mb={'10px'}
                    >
                        {t("COMMON.TOTAL")}
                    </Typography>
                }
                data={allTimeOverview}
                isLoading={isAllTimeOverviewLoading}
            />
            <OverviewComponent
                overviewLabel={
                    <DateRangeSelector
                        dateRange={dateRange}
                        setDateRange={setDateRange}
                        selectedDateRangeOption={selectedDateRangeOption}
                        setSelectedDateRangeOption={setSelectedDateRangeOption} 
                    />
                }
                data={timedOverview}
                isLoading={isTimedOverviewLoading}
                includeDateRangeInSearchPayload={true}
            />
        </Box>
    );
};

export default TrainerOverview;

const OverviewComponent = (props) => {
    const navigate = useNavigate();
    const {t} = useTranslation();

    const { selectedEntity, dateRange } = useAppSelector((state) => state.analytics.header);
    const { overviewLabel, data, isLoading } = props;

    const handleCardClick = (cardKey) => {
        let procedureListPayload: any = {
            status: cardKey,
            selectedEntity: selectedEntity,
        };
        if(props.includeDateRangeInSearchPayload){
            procedureListPayload.endDate = dateRange.endDate;
            procedureListPayload.startDate = dateRange.startDate;
        }
        
        navigate('/analytics/procedures', {state: {procedureListPayload: procedureListPayload}})
    };
    return (
        <Box
            component={'div'}
            sx={{ textAlign: 'left' }}
        >
            {overviewLabel}

            {isLoading ? (
                <OverviewComponentLoadingState />
            ) : (
                <Grid
                    container
                    columnGap={'20px'}
                >
                    {selectedEntity === ENTITY.WORK_INSTRUCTION ? (
                        <Grid
                            item
                            xs={1.5}
                        >
                            <Paper
                                sx={{
                                    padding: '20px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    gap: '10px',
                                    height: '100%',
                                    textAlign: 'center'
                                    // ':hover': {
                                    //     cursor: 'pointer',
                                    // },
                                }}
                                elevation={3}
                                // onClick={() => handleCardClick('workInstructionCard')}
                            >
                                <Typography variant="h6">{data.workInstructionCount}</Typography>
                                {t("ADD_WI.WORK_INSTRUCTION")}
                            </Paper>
                        </Grid>
                    ) : null}
                    <Grid item>
                        <Paper
                            sx={{
                                padding: '20px',
                                display: 'flex',
                                alignItems: 'center',
                                gap: '10px',
                                // ':hover': {
                                //     cursor: 'pointer',
                                // },
                            }}
                            elevation={3}
                        >
                            <Box component={'div'}>
                                <Paper
                                    sx={{ padding: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px', width: '150px' }}
                                    elevation={0}
                                    // onClick={() => handleCardClick('procedureCard')}
                                >
                                    <Typography variant="h6">{data.procedureCount}</Typography>
                                   {t("ANALYTICS_SECTION.PROCEDURE")}
                                </Paper>
                            </Box>

                            {Object.keys(PROCEDURE_STATUS_CARDS).map((each) => {
                                return (
                                    <Card
                                        count={data[PROCEDURE_STATUS_CARDS[each].key]}
                                        label={PROCEDURE_STATUS_CARDS[each].label}
                                        button={PROCEDURE_STATUS_CARDS[each].icon}
                                        color={PROCEDURE_STATUS_CARDS[each].color}
                                        handleClick={() => handleCardClick(each)}
                                    />
                                );
                            })}
                        </Paper>
                    </Grid>
                </Grid>
            )}
        </Box>
    );
};

export const OverviewComponentLoadingState = () => {
    return (
        <Grid
            container
            columnGap={'20px'}
        >
            <Grid
                item
                xs={1.5}
            >
                <Skeleton
                    width={'100%'}
                    height={'250px'}
                />
            </Grid>
            <Grid
                item
                xs={10}
            >
                <Skeleton
                    width={'100%'}
                    height={'250px'}
                />
            </Grid>
        </Grid>
    );
};

const Card = ({ count, label, button, color, handleClick }) => {
    const { t } = useTranslation();
    if (!count) return null;
    return (
        <Paper
            sx={{
                width: '150px',
                height: '150px',
                padding: '20px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '10px',
                background: color + '1a',
                textAlign: 'center',
                ':hover': {
                    cursor: 'pointer',
                },
            }}
            elevation={0}
            onClick={handleClick}
        >
            <Typography variant={'subtitle1'}>{count}</Typography>
            <CardButton
                size={'medium'}
                color={color}
            >
                {React.cloneElement(button, { fontSize: 'inherit' })}
            </CardButton>
            <Typography sx={{ fontSize: '14px', opacity: 0.6, fontWeight: 400}}>{t(label)}</Typography>
        </Paper>
    );
};

const CardButton = styled(IconButton)((props) => ({
    borderRadius: '8px',
    backgroundColor: props.color,
    color: 'white',
    fontSize: '20px',
    ':hover': {
        backgroundColor: props.color + '9c',
    },
}));
