import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Filterable, Pageable, Searchable, Sortable } from "../../../shared/interfaces";
import { ISubstitution } from "../../../pages/Substitutions/Substitutions";
import * as SubstitutionService from '../../../services/Substitution/SubstitutionService';
import { IEditSubstitutionData } from "../../../pages/Substitutions/EditSubstitutionSidebar";

interface SubstitutionSearchPayload extends Pageable, Searchable<'search', string>,
  // Sortable<'createdOn' | 'word'>,
  Filterable<'language', string[]> {
}

type InitialState = {
  loading: boolean;
  error: string;
  substitutions: ISubstitution[];
  searchPayload: SubstitutionSearchPayload | null;
  editDialogData: IEditSubstitutionData | null
};

const initialState: InitialState = {
  searchPayload: null,
  loading: false,
  error: '',
  substitutions: [],
  editDialogData: null
}

export const fetchAllSubstitutions = createAsyncThunk('substitution/fetchAllSubstitutions', () => {
  return SubstitutionService.getAllSubstitutions().then((response) => response.data.data);
});



const substitutionSlice = createSlice({
  name: 'substitution',
  initialState: initialState,
  reducers: {
    setSearchPayload: (state, action) => {
      state.searchPayload = action.payload;
    },
    setEditDialogData: (state, action: PayloadAction<IEditSubstitutionData | null>) => {
      state.editDialogData = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setSubstitutions: (state, action: PayloadAction<ISubstitution[]>) => {
      state.loading = false;
      state.substitutions = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllSubstitutions.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAllSubstitutions.fulfilled, (state, action: PayloadAction<ISubstitution[]>) => {
      state.loading = false;
      state.substitutions = action.payload;
      state.error = '';
    });
    builder.addCase(fetchAllSubstitutions.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || 'Something Went Wrong';
    });
  },
});

export default substitutionSlice.reducer;
export const { setSearchPayload, setEditDialogData, setLoading, setSubstitutions } = substitutionSlice.actions;