import { FolderOutlined, SearchOutlined } from '@mui/icons-material';
import CreateNewFolderOutlinedIcon from '@mui/icons-material/CreateNewFolderOutlined';
import { Box, CardContent, debounce, FormControl, FormHelperText, Paper, TextField, Tooltip } from '@mui/material';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import useLoadFolders from '../../../../hooks/useLoadFolders';
import { getFormattedDateFromUnixTime } from '../../../../shared/utils/formateDate';
import TableContainer, { TableColumnHeader } from '../../../Analytics/ProcedureReports/TableContainer';
import CdsBreadCrumb, { getBreadCrumbRoute } from '../../../BreadCrumbs/CdsBreadCrumb';
import { FolderIconNoFolderExist, SmallFolderIcon } from '../../../Icons/CdsIcons';
import Loading from '../../../Loading/Loading';
import { BasicDialog } from '../BasicDialog/BasicDialog';
import './MoveFolderDialog.scss';
import TrainerService from '../../../../services/trainer/TrainerService';
import { Toast } from '../../../Notifications/Notification';

const DialogueActionContainer = (props) => {
    const { t } = useTranslation();
    const { handleClose, handleMoveFolder, targetFolder } = props;

    const moveFolderHandler = () => {
        const targetFolderId = targetFolder;
        handleMoveFolder(targetFolderId);
        handleClose();
    };

    return (
        <Box>
            <Button
                variant="outlined"
                onClick={handleClose}
                sx={{ marginRight: '12px' }}
            >
                {' '}
                {t('BUTTON.CANCEL')}
            </Button>
            <Button
                variant="contained"
                type="submit"
                onClick={moveFolderHandler}
            >
                {' '}
                {t('COMMON.MOVE')}
            </Button>
        </Box>
    );
};

export default function MoveFolderDialog(props) {
    const [targetFolder, setTargetFolder] = React.useState<any>('');
    const [searchText, setSearchText] = React.useState('');
    const [foldersData, setFoldersData] = React.useState<any>([]);
    const [breadCrumbRoute, setBreadCrumbRoute] = React.useState<any>([]);
    const [isLoading, setIsLoading] = React.useState<any>(false);
    const [selectedFolders, setSelectedFolders] = React.useState<any>([]);
    const [isCreateNewFolderDialogOpen, setIsCreateNewFolderDialogOpen] = React.useState<boolean>(false);

    const { loadFolderData, moveFolderData, moveParentFolderId, moveCurrentPage, totalFoldersLength, moveHasNext, setMoveCurrentPage, setMoveParentFolderId, setLoading, loading } = useLoadFolders();

    const { t } = useTranslation();

    useEffect(() => {
        loadBreadcrumbRoute();
    }, [moveParentFolderId, props.openDialog]);

    useEffect(() => {
        if (props.selectedFolders) {
            let arr = Array.from(props.selectedFolders);
            arr = arr.map((each) => JSON.parse(each as string).id);
            setSelectedFolders(arr);
        }
    }, [props.selectedFolders]);

    useEffect(() => {
        setLoading(true);
        const arr: any = [];
        moveFolderData?.forEach((folder) => {
            if (selectedFolders.indexOf(folder.folderId) !== -1) return;
            arr.push({
                folderId: folder.folderId,
                name: folder.title,
                description: folder.description,
                createdOn: folder.createdOn,
            });
        });
        const filteredFoldersData = arr?.filter((folder) => !props.selectedFolders?.has(folder?.id));
        setFoldersData(filteredFoldersData);
        setLoading(false);
    }, [moveFolderData, selectedFolders, searchText]);

    useEffect(() => {
        setMoveParentFolderId(props.parentFolderId);
    }, [props.parentFolderId]);

    useEffect(() => {
        filterSearchFolderData(searchText);
    }, [searchText]);

    const loadBreadcrumbRoute = () => {
        setIsLoading(true);
        try {
            const folderId = moveParentFolderId ? moveParentFolderId : '';
            if (folderId !== '') {
                getBreadCrumbRoute(folderId, 'Folder').then((arr) => {
                    setBreadCrumbRoute(arr);
                });
            }
            loadFolderData(folderId);
            setTargetFolder(folderId);
        } catch (error) {
            console.error('Error loading breadcrumb data:', error);
        } finally {
            setSearchText('');
            setIsLoading(false);
        }
    };

    const handleClose = () => {
        loadFolderData('');
        setTargetFolder('');
        setBreadCrumbRoute([]);
        setFoldersData([]);
        setSearchText('');
        props.closeDialog();
    };

    const clickTimeout = React.useRef<any>(null);
    const handleRowClick = (row) => {
        const type = 'Folder';

        if (clickTimeout.current) {
            clearTimeout(clickTimeout.current);
            clickTimeout.current = null;

            const clickedFolderId = row.original.folderId ? row.original.folderId : '';
            if (clickedFolderId !== '') {
                getBreadCrumbRoute(clickedFolderId, type).then((arr) => {
                    setBreadCrumbRoute(arr);
                });
            }
            loadFolderData(clickedFolderId);
        } else {
            clickTimeout.current = setTimeout(() => {
                setTargetFolder(row.original.folderId);
                clickTimeout.current = null;
            }, 500);
        }
    };

    const columns = useMemo(
        () =>
            [
                {
                    Header: <TableColumnHeader label={'COMMON.NAME'} />,
                    accessor: 'name',
                    Cell: ({ value, row }) => (
                        <Typography sx={{ marginLeft: '8px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <Typography sx={{ marginRight: '8px' }}>
                                <SmallFolderIcon />
                            </Typography>
                            <Tooltip title={value}>
                                <Typography sx={{ marginLeft: '8px', fontSize: '16px', fontWeight: '600' }}>{value}</Typography>
                            </Tooltip>
                        </Typography>
                    ),
                },
                {
                    Header: <TableColumnHeader label={`COMMON.DESCRIPTION_WORK_INSTRUCTION`} />,
                    accessor: 'description',
                    disableSortBy: true,
                    Cell: ({ value, row }) => (
                        <Tooltip title={value}>
                            <Typography sx={{ fontSize: '14px', fontWeight: 400, opacity: '70%', color: '#312D32' }}>{value ? value : '-'}</Typography>
                        </Tooltip>
                    ),
                },
                {
                    Header: 'Date',
                    accessor: 'createdOn',
                    Cell: ({ value, row }) => <span style={{ fontSize: '14px', fontWeight: 400, opacity: '70%', color: '#312D32' }}>{getFormattedDateFromUnixTime(value)}</span>,
                },
            ].filter(Boolean),
        [],
    );

    const getFoldersData = () => {
        setLoading(true);
        const arr: any = [];

        moveFolderData?.map((folder) => {
            arr.push({
                folderId: folder.folderId,
                name: folder.title,
                description: folder.description,
                createdOn: folder.createdOn,
            });
        });

        const filteredFoldersData = arr?.filter((folder) => !props.selectedFolders?.has(folder?.id));
        setFoldersData(filteredFoldersData);
        setLoading(false);
    };

    const debouncedSearch = React.useCallback(
        debounce((value) => {
            loadFolderData(moveParentFolderId ? moveParentFolderId : '', value, '', '', moveCurrentPage, totalFoldersLength);
            getFoldersData();
            setLoading(false);
        }, 500),
        [],
    );

    const filterSearchFolderData = (value) => {
        setLoading(true);
        setMoveCurrentPage(1);
        setSearchText(value);
        debouncedSearch(value);
    };

    const handleCreateFolder = (folderTitle, folderDescription) => {
        const newFolder = {
            parentFolderId: targetFolder, // targetFolder will be the folder which is currently open
            title: folderTitle || 'New Folder',
            description: folderDescription,
        };

        TrainerService.createFolder(newFolder)
            .then(() => {
                loadFolderData(targetFolder);
            })
            .catch(({ response }) => {
                Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
            })
            .finally(() => {
                setIsCreateNewFolderDialogOpen(false);
            });
    };

    return (
        <>
            <BasicDialog
                onClose={handleClose}
                additionalProp={{
                    minWidth: '67.93rem',
                    maxHeight: '39.18rem',
                    minHeight: '39.18rem',
                    padding: '1.5rem',
                }}
                aria-labelledby="customized-dialog-title"
                onOpen={props.openDialog}
                title={`${t('COMMON.MOVE')} (${totalFoldersLength}) `}
                id="select-entity"
                fullWidth={true}
                maxWidth={'lg'}
                actions={
                    <DialogueActionContainer
                        handleClose={handleClose}
                        handleMoveFolder={props.handleMoveFolder}
                        targetFolder={targetFolder}
                    />
                }
            >
                <>
                    <Paper
                        elevation={0}
                        sx={{ display: { xs: 'none', md: 'flex' } }}
                    >
                        {moveParentFolderId === '' ? (
                            <>
                                <FolderOutlined sx={{ marginRight: '8px' }} />
                                <Typography sx={{ fontWeight: '600', fontSize: '1rem', fontColor: '#004b8d' }}>{t('NAVBAR.ALL_WORK_INSTRUCTIONS')}</Typography>
                            </>
                        ) : (
                            <CdsBreadCrumb
                                routes={breadCrumbRoute}
                                folderBreadCrumb={true}
                                setBreadCrumbRoute={setBreadCrumbRoute}
                                setCurrPage={setMoveCurrentPage}
                                loadData={loadFolderData}
                                workInstructionAppBar={props.workInstructionAppBar}
                                setWorkInstructionAppBar={props.setWorkInstructionAppBar}
                            />
                        )}
                    </Paper>

                    <TextField
                        sx={{ width: '208px', height: '32px', marginTop: '8px', mb: '8px' }}
                        fullWidth
                        id="standard-bare"
                        variant="outlined"
                        placeholder={t('COMMON.SEARCH')}
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        InputProps={{
                            startAdornment: (
                                <IconButton>
                                    <SearchOutlined />
                                </IconButton>
                            ),
                        }}
                    />
                    {(isLoading || loading) && (
                        <Box
                            component="div"
                            sx={{ marginLeft: '-25px' }}
                        >
                            <Loading />
                        </Box>
                    )}
                    {foldersData.length ? (
                        <>
                            <Box
                                component={'div'}
                                sx={{
                                    marginTop: '8px',
                                    width: '100%',
                                    '> table': {
                                        ' th:nth-child(1)': { width: '30%' },
                                        ' th:nth-child(2)': { width: '50%' },
                                        ' th:nth-child(3)': { width: '20%' },
                                    },
                                }}
                            >
                                <InfiniteScroll
                                    dataLength={totalFoldersLength || 10}
                                    next={() => {}}
                                    onScroll={() => {
                                        loadFolderData(moveParentFolderId ? moveParentFolderId : '', searchText, '', '', moveCurrentPage, totalFoldersLength);
                                    }}
                                    hasMore={moveHasNext}
                                    loader={<p>{moveHasNext ? t('COMMON.LOADING') : null}</p>}
                                >
                                    <Paper
                                        elevation={0}
                                        sx={{ display: 'unset', overflow: 'hidden', overflowY: 'auto' }}
                                    >
                                        <Box
                                            component="div"
                                            sx={{
                                                width: '100%',
                                                ' table tbody :not(tr:hover) .show-on-hover': {
                                                    display: 'none',
                                                },
                                                ' table tbody tr:hover .show-on-hover': {
                                                    display: 'inline-flex',
                                                },
                                                '> table': {
                                                    ' th:nth-child(1)': { width: '30%' },
                                                    ' th:nth-child(2)': { width: '55%' },
                                                    ' th:nth-child(3)': { width: '15%' },
                                                },
                                            }}
                                        >
                                            <TableContainer
                                                columns={columns}
                                                data={foldersData}
                                                onRowClick={handleRowClick}
                                                rowProps={(row) => ({
                                                    selected: row.original.folderId === targetFolder,
                                                })}
                                                isMoveFolderTable={true}
                                            />
                                        </Box>
                                    </Paper>
                                </InfiniteScroll>
                            </Box>
                        </>
                    ) : (
                        <>
                            {(isLoading || loading) && (
                                <Box
                                    component="div"
                                    sx={{ marginLeft: '-25px' }}
                                >
                                    <Loading />
                                </Box>
                            )}
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignContent: 'center',
                                    alignItems: 'center',
                                    // minWidth: '67.93rem',
                                    // maxHeight: '39.18rem',
                                    // minHeight: '39.18rem',
                                    // padding: '1.5rem',
                                    // position: 'relative',
                                }}
                                style={{ top: '40%', left: '45%' }}
                            >
                                <Box sx={{ marginBottom: '8px', marginTop: '50px' }}>
                                    <FolderIconNoFolderExist />
                                </Box>
                                <Box component="div">
                                    <Typography sx={{ fontSize: '20px' }}> {t('COMMON.NO_FOLDER_EXIST')}</Typography>
                                </Box>
                                <Box>
                                    <Box sx={{ marginTop: '8px' }}>
                                        <IconButton
                                            color="primary"
                                            sx={{ marginRight: '8px', width: 'auto', padding: '0' }}
                                            onClick={() => setIsCreateNewFolderDialogOpen(true)}
                                        >
                                            <CreateNewFolderOutlinedIcon sx={{ marginRight: '4px', width: '32px', height: '32px' }} />
                                            <Typography>{t('COMMON.CREATE_NEW_FOLDER')}</Typography>
                                        </IconButton>
                                    </Box>
                                </Box>
                            </Box>
                        </>
                    )}
                </>
            </BasicDialog>
            {isCreateNewFolderDialogOpen && (
                <CreateNewFolderDialog
                    isOpen={isCreateNewFolderDialogOpen}
                    onClose={() => {
                        setIsCreateNewFolderDialogOpen(false);
                    }}
                    isRenameNavBar={false}
                    handleCreateFolder={handleCreateFolder}
                />
            )}
        </>
    );
}

export const CreateNewFolderDialog = (props) => {
    const { onClose, isOpen, handleCreateFolder } = props;
    const [folderTitle, setFolderTitle] = React.useState<string>(props?.renameFolderData?.name ? props.renameFolderData.name : '');
    const [folderDescription, setFolderDescription] = React.useState<string>(props?.renameFolderData?.description ? props.renameFolderData.description : '');

    const { t } = useTranslation();

    const handleCreateFolderClick = () => {
        handleCreateFolder(folderTitle, folderDescription);
    };
    return (
        <BasicDialog
            onClose={onClose}
            onOpen={isOpen}
            title={props?.isRenameNavBar ? t('COMMON.RENAME_FOLDER') : t('COMMON.CREATE_NEW_FOLDER')}
            id="create-new-folder-dialog"
            fullWidth={true}
            maxWidth={'sm'}
            actions={
                <Box>
                    <Button
                        variant="outlined"
                        onClick={onClose}
                        sx={{ marginRight: '12px' }}
                    >
                        {' '}
                        {t('BUTTON.CANCEL')}
                    </Button>
                    <Button
                        variant="contained"
                        type="submit"
                        onClick={handleCreateFolderClick}
                    >
                        {' '}
                        {t('COMMON.ADD')}
                    </Button>
                </Box>
            }
        >
            <Box
                component="div"
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    margin: '0px 4px 0px 4px',
                }}
            >
                <CardContent
                    sx={{ display: 'flex', flexDirection: 'column', alignItems: 'inherit' }}
                    className="work-ins-card-content"
                >
                    <FormControl
                        sx={{
                            fontSize: '14px',
                            marginBottom: '8px',
                            textAlign: 'left',
                            height: '100%',
                            width: '100%',
                        }}
                        className="tour-instruction-name"
                    >
                        <TextField
                            InputProps={{ style: { fontSize: '0.8rem' } }}
                            size={'small'}
                            label=""
                            name="title"
                            onChange={(e) => setFolderTitle(e.target.value)}
                            value={folderTitle}
                            placeholder={t('COMMON.NAME') as string}
                            id="component-outlined"
                            style={{ borderRadius: '8px !important' }}
                            inputProps={{ maxLength: 55 }}
                            required
                            fullWidth
                        />
                    </FormControl>
                    <FormControl
                        sx={{ fontSize: '12px', textAlign: 'left', height: '100%', width: '100%' }}
                        className="tour-instruction-desc"
                    >
                        <TextField
                            InputProps={{ style: { fontSize: '0.8rem' } }}
                            size={'small'}
                            value={folderDescription}
                            sx={{ overflowY: 'scroll' }}
                            name="description"
                            id="outlined-multiline-static"
                            onChange={(e) => setFolderDescription(e.target.value)}
                            placeholder={t('COMMON.DESCRIPTION_OPTIONAL') as string}
                            multiline
                            rows={2}
                            inputProps={{ maxLength: 200 }}
                            required
                            fullWidth
                        />
                        <FormHelperText
                            id="component-helper-text"
                            className="helperText"
                            sx={{ fontSize: '10px' }}
                        >
                            {200 - folderDescription.length} {t('COMMON.CHARACTERS_LEFT') as string}
                        </FormHelperText>
                    </FormControl>
                </CardContent>
            </Box>
        </BasicDialog>
    );
};
