import { ArrowForward, MoreHoriz } from '@mui/icons-material';
import { Card, Box, CardContent, Typography, CardMedia, Button, Chip, Stack, Backdrop, IconButton, MenuItem, Menu, ClickAwayListener } from '@mui/material';
import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { IWorkInstructions } from '../../../entities/Trainee/MockTrainee';
import './WorkInstructionCards.scss';
import fallBackIcon from '../../../assets/fallback_img.svg';
import { getFormattedDateFromUnixTime } from '../../../shared/utils/formateDate';
import FileManagerService from '../../../services/FileManager/FileManagerService';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { translate } from '../../../shared/enums';
import Loading from '../../Loading/Loading';
import { useNavigate } from 'react-router-dom';
import DeleteWorkInstruction from '../../Trainer/Dialog/DeleteWorkInstruction/DeleteWorkInstruction';
import { DeleteEntityIcon, DuplicateIcon, MediaUploadIcon, MoveIcon, RestoreEntityIcon } from '../../Icons/CdsIcons';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { DeleteFolderDialog } from '../../Trainer/Dialog/DeleteFolderDialog/DeleteFolderDialog';
import { DuplicateFolderDialog } from '../../Trainer/Dialog/DuplicateFolderDialog/DuplicateFolderDialog';
import { useDispatch } from 'react-redux';
import { setDialogToShowAction } from '../../../redux/features/HomePage/HomePage.slice';
import { HOMEPAGE_DIALOG } from '../../../pages/Trainee/TraineeHome/constants';
import { ThumbnailDialog } from '../../Trainer/Dialog/ThumbnailDialog/ThumbnailDialog';
import { RecycleBinActionButton } from '../RecycleBinActionButton';

interface Props {
    workInstruction: IWorkInstructions;
    companyId?: string;
    isTrainer?: boolean;
    workInstructionImageURL?: string;
    showRecycleBinHome?: boolean;
    hideRecycleBinHome?: boolean;
    recycleBinCardHeight?: string;
    isDelete?: boolean;
    isShowProcedures?: boolean;
    isDeletedWorkInstructionBackButton?: boolean;
    id?: any;
    isRevisitRequest?: boolean;
    isRecertificationDue?: boolean;
    onClick?: any;
    selected?: any;
    overview?: any;
    parentFolderId?: String;
    setParentFolderId?: any;
    selectedFolderWiCard?: any;
    clearCardsSelection?: any;
    handleReloadData?: any;
    checkedMoveToFolderId?: any;
    setCheckedMoveToFolderId?: any;
    handleDuplicateFolder?: any;
    folders?: any;
    setIsCreateFolder?: any;
    setBreadCrumbRoute?: any;
    handleMoveFolder?: any;
    restoreDocument?: any;
    deletePermanentlyHandler?: any;
    is2dMap?: any;
    isApprovalWindow?: boolean;
}

const WorkInstructionCards: React.FC<Props> = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [contentReady, setContentReady] = React.useState<boolean>(true);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [showDeleteDialog, setShowDeleteDialog] = React.useState<boolean>(false);
    const [thumbnail, setThumbnail] = React.useState<any>('');
    const [deleteDialogCheck, setDeleteDialogCheck] = React.useState<boolean>(false);
    const companyId = localStorage.getItem('company');
    const [showDeleteFolderDialog, setShowDeleteFolderDialog] = React.useState<boolean>(false);
    const [showDuplicateFolderDialog, setShowDuplicateFolderDialog] = React.useState<boolean>(false);
    const [showThumbnailDialog, setShowThumbnailDialog] = React.useState<boolean>(false);
    // const [showMoveFolderDialog, setShowMoveFolderDialog] = React.useState<boolean>(false);
    const [filterNonSelectedFolders, setFilterNonSelectedFolders] = React.useState<any>([]);
    const [isHover, setIsHover] = React.useState<boolean>(false);

    const WS_STATUS = {
        '3D MODEL CONVERSION FAILED': '3D MODEL CONVERSION FAILED',
        '3D MODEL READY': '3D MODEL READY',
        '2D MODEL READY': '2D MODEL READY',
        'PREPARING 3D MODEL': 'PREPARING 3D MODEL',
        'APPROVAL PENDING': 'APPROVAL PENDING',
        'CAD FILE UPLOAD FAILED': 'CAD FILE UPLOAD FAILED',
    };

    const { t } = useTranslation();
    const theme = useTheme();

    const commonStyles = {
        border: 1,
    };

    const getStatusStyle = (status: string, viewType: string) => {
        const statusStyle = viewType === 'extended_view' ? 'status-style-extended-view' : 'status-style';
        const wiStatus = viewType === 'extended_view' ? 'wi-status-extended-view' : 'wi-status';
        let classes = `${statusStyle} ${wiStatus} one-line-ellipsis`;
        if (status === WS_STATUS['3D MODEL CONVERSION FAILED']) {
            classes = `${statusStyle} status-error-color ${wiStatus} error one-line-ellipsis`;
        } else if (status === WS_STATUS['3D MODEL READY'] || status === WS_STATUS['2D MODEL READY']) {
            classes = `${statusStyle} status-success-color ${wiStatus} success one-line-ellipsis`;
        } else if (status === WS_STATUS['PREPARING 3D MODEL'] || status === WS_STATUS['APPROVAL PENDING']) {
            classes = `${statusStyle} status-warning-color ${wiStatus} warning one-line-ellipsis`;
        } else {
            classes = `${statusStyle} status-error-color ${wiStatus} error one-line-ellipsis`;
        }
        return classes;
    };

    const deleteClickHandler = () => {
        setAnchorEl(null);
        setShowDeleteDialog(true);
        setDeleteDialogCheck(true);
    };

    const displayTraineeStatus = (status) => {
        if (status?.toUpperCase() === 'NOT STARTED') {
            return t('COMMON.WORKINSTRUCTION_TRAINEE_STATUS_NOT_STARTED');
        } else if (status?.toUpperCase() === 'IN-PROGRESS') {
            return t('COMMON.WORKINSTRUCTION_TRAINEE_STATUS_IN_PROGRESS');
        } else if (status?.toUpperCase() === 'COMPLETED') {
            return t('COMMON.WORKINSTRUCTION_TRAINEE_STATUS_COMPLETED');
        }
    };

    const closeDeleteDialogHandler = () => {
        setShowDeleteDialog(false);
    };

    const getRoute = () => {
        if (props.isDelete) {
            return props.isTrainer ? `/trainer/RecycleBinprocedures/${props.companyId}/${props.workInstruction.workInstructionId}` : `/procedures/${props.companyId}/${props.workInstruction.workInstructionId}`;
        } else if (props.isRevisitRequest) {
            return props.isTrainer ? `/trainer/RevisitProcedures/${props.workInstruction.workInstructionId}` : `/procedures/${props.companyId}/${props.workInstruction.workInstructionId}`;
        } else if (props.isRecertificationDue) {
            return `/procedures/${props.companyId}/${props.workInstruction.workInstructionId}?isRecertificationDue=${props.isRecertificationDue}`;
        } else {
            return props.isTrainer ? `/trainer/procedures/${props.companyId}/${props.workInstruction.workInstructionId}` : `/procedures/${props.companyId}/${props.workInstruction.workInstructionId}`;
        }
    };

    useEffect(() => {
        getThumbnailImage();
    }, [props.workInstructionImageURL, showThumbnailDialog]);

    const getThumbnailImage = () => {
        if (props.workInstructionImageURL)
            FileManagerService.getWorkInstructionThumbnailPath(props.workInstructionImageURL, false)
                .then((res) => {
                    const blob = new Blob([res.data]);
                    const url = URL.createObjectURL(blob);
                    setThumbnail(url);
                })
                .catch((err) => {
                    console.log(err);
                });
        else {
            return '';
        }
    };

    const handleMouseEnter = (e) => {
        setIsHover(true);
    };

    const handleMouseLeave = (e) => {
        setIsHover(false);
    };

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        if (!props.selected) {
            props.onClick && props.onClick();
        }
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMove = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(null);
        let movePayload: any = [];
        props?.selectedFolderWiCard?.forEach((value) => {
            const parsedValue = JSON.parse(value);
            movePayload.push(parsedValue.id);
        });
        const filteredNonSelected = props.folders.filter(function (value) {
            return movePayload.indexOf(value.folderId) === -1;
        });

        setFilterNonSelectedFolders(filteredNonSelected);
        // setShowMoveFolderDialog(true);
        dispatch(setDialogToShowAction(HOMEPAGE_DIALOG.MOVE_ENTITY));
    };

    const handleDuplicateDialog = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(null);
        setShowDuplicateFolderDialog(true);
    };

    const closeDuplicateFolderDialogHandler = () => {
        setShowDuplicateFolderDialog(false);
    };

    const handleDeleteDialog = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(null);
        setShowDeleteFolderDialog(true);
    };

    const closeDeleteFolderDialogHandler = () => {
        setShowDeleteFolderDialog(false);
    };

    const handleClickAwayCards = (event) => {
        if (props.selectedFolderWiCard?.size > 0 || showDeleteFolderDialog || showDuplicateFolderDialog) {
            if (Array.from(event.target.classList).includes('multiselect-option')) {
                event.stopPropagation();
                return;
            }
            if (props.selectedFolderWiCard?.size > 0 || showDeleteFolderDialog || showDuplicateFolderDialog) {
                event.stopPropagation();
                return;
            } else {
                props.clearCardsSelection();
            }
        }
    };

    const homePath = new RegExp('/home');
    const location = useLocation();
    return (
        <>
            {!contentReady && (
                <Backdrop
                    sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={!contentReady}
                >
                    <Loading />
                </Backdrop>
            )}
            <ClickAwayListener onClickAway={handleClickAwayCards}>
                <div>
                    {props.isTrainer && (
                        <>
                            {showThumbnailDialog ? (
                                <ThumbnailDialog
                                    openDialog={showThumbnailDialog}
                                    setShowThumbnailDialog={setShowThumbnailDialog}
                                    thumbnail={thumbnail}
                                    workInstruction={props.workInstruction}
                                    handleReloadData={props.handleReloadData}
                                    clearCardsSelection={props.clearCardsSelection}
                                />
                            ) : null}
                            <DeleteWorkInstruction
                                className="multiselect-option"
                                openDialog={showDeleteDialog}
                                closeDialog={closeDeleteDialogHandler}
                                id={props.workInstruction.workInstructionId}
                                isWorkinstruction={true}
                                isDeletePermanently={deleteDialogCheck}
                            />
                            {/* <MoveFolderDialog className="multiselect-option" openDialog={showMoveFolderDialog} closeDialog={closeMoveFolderDialogHandler} folders={filterNonSelectedFolders} handleMoveFolder={props.handleMoveFolder} checkedMoveToFolderId={props.checkedMoveToFolderId} setCheckedMoveToFolderId={props.setCheckedMoveToFolderId} parentFolderId={props.parentFolderId} setParentFolderId={props.setParentFolderId} isTrainer={props.isTrainer} setIsCreateFolder={props.setIsCreateFolder} clearCardsSelection={props.clearCardsSelection} setBreadCrumbRoute={props.setBreadCrumbRoute} selectedFolders={props.selectedFolderWiCard} /> */}
                            <DeleteFolderDialog
                                className="multiselect-option"
                                openDialog={showDeleteFolderDialog}
                                closeDialog={closeDeleteFolderDialogHandler}
                                selectedFolderWiCard={props.selectedFolderWiCard}
                                clearCardsSelection={props.clearCardsSelection}
                                handleReloadData={props.handleReloadData}
                            />
                            <DuplicateFolderDialog
                                className="multiselect-option"
                                openDialog={showDuplicateFolderDialog}
                                closeDialog={closeDuplicateFolderDialogHandler}
                                selectedFolderWiCard={props.selectedFolderWiCard}
                                clearCardsSelection={props.clearCardsSelection}
                                handleReloadData={props.handleReloadData}
                                handleDuplicateFolder={props.handleDuplicateFolder}
                                parentFolderId={props.parentFolderId}
                                is2dMap={props.is2dMap}
                            />
                        </>
                    )}

                    <Card
                        onMouseEnter={(e) => {
                            handleMouseEnter(e);
                        }}
                        onMouseLeave={(e) => {
                            handleMouseLeave(e);
                        }}
                        onClick={(e: any) => {
                            e.stopPropagation();
                            props.onClick && props.onClick();
                        }}
                        sx={{ position: 'relative', backgroundColor: props.selected ? theme.palette.primary.main + '45' : isHover ? theme.palette.primary.main + '20' : 'white', border: isHover ? `2px solid ${theme.palette.primary.main + '1c'}` : null, cursor: isHover && !props.restoreDocument ? 'pointer' : 'default', display: { xs: 'none', md: 'flex' }, borderRadius: '12px', alignItems: props.overview === 'extended_view' ? 'center' : null, boxShadow: 'rgba(149, 157, 165, 0.5) 0px 8px 24px' }}
                        elevation={0}
                        className="work-instruction-card"
                        style={{ height: props.overview === 'extended_view' && props.showRecycleBinHome ? '164px' : props.overview === 'extended_view' ? '144px' : '280px', width: '100%', minWidth: props.overview === 'extended_view' ? '245px' : '400px' }}
                    >
                        <Box
                            component="div"
                            className={props.overview === 'extended_view' ? 'null' : 'wi-img-container'}
                        >
                            {props.workInstructionImageURL && (
                                <CardMedia
                                    sx={{ width: props.overview === 'extended_view' ? '65px' : null, height: props.overview === 'extended_view' ? '65px' : null, objectFit: 'fill', paddingLeft: '1em', display: 'flex', alignItems: props.overview === 'extended_view' ? 'center' : null }}
                                    component="img"
                                    image={thumbnail}
                                    alt="work instruction model"
                                />
                            )}
                            {!props.workInstructionImageURL && (
                                <CardMedia
                                    component="img"
                                    sx={{ width: props.overview === 'extended_view' ? '65px' : null, height: props.overview === 'extended_view' ? '65px' : null, objectFit: 'fill', paddingLeft: '1em', display: 'flex', alignItems: props.overview === 'extended_view' ? 'center' : null }}
                                    image={fallBackIcon}
                                    alt="work instruction model"
                                ></CardMedia>
                            )}
                        </Box>
                        <Box
                            component="div"
                            sx={{ display: 'flex', flexDirection: 'column' }}
                            className={props.overview === 'extended_view' ? 'work-ins-card-right-extended-view' : 'work-ins-card-right'}
                        >
                            <CardContent
                                sx={{ flex: '1 0 auto' }}
                                className="work-ins-card-content"
                            >
                                <Stack
                                    sx={{ flexDirection: { xs: 'column', md: 'row' }, justifyContent: 'start' }}
                                    className="chip-stack status-msg-align"
                                    flexWrap={'wrap'}
                                >
                                    {!props.isRecertificationDue && !props.isTrainer && props.workInstruction?.completionStatus?.toUpperCase() !== 'NOT STARTED' && !props.workInstruction.hasExpiredProcedures && (
                                        <Chip
                                            sx={{ height: 23 }}
                                            label={displayTraineeStatus(props.workInstruction.completionStatus)}
                                            variant="outlined"
                                            className={props.workInstruction?.completionStatus?.toUpperCase() === 'COMPLETED' ? 'completed' : 'in-progress'}
                                        />
                                    )}
                                    {props.isRecertificationDue && props.workInstruction.recertificationDueCount > 0 && !props.isTrainer && (
                                        <Chip
                                            sx={{ height: 23, marginBottom: '4px', marginRight: '8px' }}
                                            label={`${props.workInstruction.recertificationDueCount} ${props.workInstruction.recertificationDueCount > 1 ? t('NAVBAR.RECERTIFICATIONS_DUE') : t('NAVBAR.RECERTIFICATION_DUE')}`}
                                            variant="outlined"
                                            className={'in-progress'}
                                        />
                                    )}
                                    {props.isRecertificationDue && props.workInstruction.recertificationExpiredCount > 0 && !props.isTrainer && (
                                        <Chip
                                            sx={{ height: 23, textTransform: 'uppercase' }}
                                            label={`${props.workInstruction.recertificationExpiredCount} ${props.workInstruction.recertificationExpiredCount > 1 ? t('NAVBAR.CERTIFICATES') : t('NAVBAR.CERTIFICATE')} ${t('NAVBAR.EXPIRED')}`}
                                            variant="outlined"
                                            className={'error style-error'}
                                        />
                                    )}
                                </Stack>

                                {!props.isRecertificationDue && !props.isTrainer && props.workInstruction?.completionStatus?.toUpperCase() !== 'NOT STARTED' && props.workInstruction.hasExpiredProcedures && (
                                    <Stack
                                        sx={{ flexDirection: { xs: 'column', md: 'row' }, justifyContent: 'start' }}
                                        className="chip-stack"
                                        flexWrap={'wrap'}
                                    >
                                        <Chip
                                            sx={{ height: 23, fontWeight: 600 }}
                                            label={displayTraineeStatus('IN-PROGRESS')}
                                            variant="outlined"
                                            className={props.workInstruction?.completionStatus?.toUpperCase() === 'COMPLETED' && !props.workInstruction?.hasExpiredProcedures ? 'completed' : 'in-progress'}
                                        />
                                    </Stack>
                                )}
                                {!props.isRecertificationDue && !props.isTrainer && props.workInstruction?.completionStatus?.toUpperCase() === 'NOT STARTED' && (
                                    <Box
                                        component="div"
                                        sx={{ height: '33px' }}
                                    ></Box>
                                )}
                                {!props.isDelete && props.isTrainer && !props.hideRecycleBinHome && (
                                    <Stack
                                        sx={{ flexDirection: { xs: 'column', md: 'row' }, justifyContent: 'start' }}
                                        className={props.overview === 'extended_view' ? 'status-msg-align-extended-view' : 'status-msg-align'}
                                        flexWrap={'wrap'}
                                    >
                                        {!props.isRevisitRequest && !props.workInstruction.is2d && props.workInstruction.status && (
                                            <Box
                                                component="span"
                                                className={'"' + getStatusStyle(props.workInstruction.status, props?.overview) + '"'}
                                                sx={{ ...commonStyles }}
                                                title={t('COMMON.' + translate[props.workInstruction.status]) as string}
                                            >
                                                {t('COMMON.' + translate[props.workInstruction.status]) as string}
                                                {/* {props.workInstruction.status} */}
                                            </Box>
                                        )}
                                        {!props.isRevisitRequest && props.workInstruction.is2d && (
                                            <Typography
                                                className={'"' + getStatusStyle('2D MODEL READY', props?.overview) + '"'}
                                                sx={{ ...commonStyles, fontSize: props.overview === 'extended_view' ? '6px' : '12px' }}
                                            >
                                                2D Model Ready
                                            </Typography>
                                        )}
                                        {!props.isRevisitRequest && props.workInstruction.pendingApprovals !== undefined && props.workInstruction.pendingApprovals > 0 && (
                                            <>
                                                <Box
                                                    component="span"
                                                    sx={{ width: '5px' }}
                                                >
                                                    &nbsp;
                                                </Box>
                                                <Box
                                                    component="span"
                                                    className={'"' + getStatusStyle('APPROVAL PENDING', props?.overview) + '"'}
                                                    sx={{ ...commonStyles }}
                                                    title="Approvals Pending"
                                                >
                                                    {props.workInstruction.pendingApprovals} {props.workInstruction.pendingApprovals > 1 ? t('PROCEDURE.APPROVALS_PENDING') : t('PROCEDURE.APPROVAL_PENDING')}
                                                </Box>
                                            </>
                                        )}
                                        {props.isRevisitRequest && props.workInstruction.pendingRevisits !== undefined && props.workInstruction.pendingRevisits > 0 && (
                                            <>
                                                <Box
                                                    component="span"
                                                    className={'"' + getStatusStyle('APPROVAL PENDING', props?.overview) + '"'}
                                                    sx={{ ...commonStyles }}
                                                    title="Approvals Pending"
                                                >
                                                    {props.workInstruction.pendingRevisits} {props.workInstruction.pendingRevisits > 1 ? t('NAVBAR.PERIODIC_REVIEWS_PENDING') : t('NAVBAR.PERIODIC_REVIEW_PENDING')}
                                                </Box>
                                            </>
                                        )}
                                    </Stack>
                                )}
                                {props.showRecycleBinHome && (
                                    <Typography
                                        component="div"
                                        sx={{ color: '#312D32', opacity: '70%', maxHeight: '2rem', fontSize: props.overview === 'extended_view' ? '10px' : '12px', fontWeight: '600' }}
                                        className="card-work-ins-text one-line-ellipsis"
                                        title={props.workInstruction.workInstructionName}
                                    >
                                        {t('COMMON.WORK_INSTRUCTION_CAPITAL')}
                                    </Typography>
                                )}
                                <Typography
                                    component="div"
                                    variant={props.overview === 'extended_view' ? 'inherit' : 'h5'}
                                    sx={{ fontSize: props.overview === 'extended_view' ? '14px' : '20px', maxHeight: '4rem', lineHeight: '1.2', color: '#1F1E20', fontWeight: '600' }}
                                    className={props.overview === 'extended_view' ? 'card-work-ins-text-extended-view one-line-ellipsis' : 'card-work-ins-text one-line-ellipsis'}
                                    title={props.workInstruction.workInstructionName}
                                >
                                    {props.workInstruction.workInstructionName}
                                </Typography>
                                <Typography
                                    sx={{ fontSize: props.overview === 'extended_view' ? '12px' : '14px', maxHeight: '2.8rem', marginBottom: props.overview === 'extended_view' ? '2px !important' : '3px !important', fontWeight: props.overview === 'extended_view' ? '500' : null, opacity: props.overview === 'extended_view' ? '70%' : null, color: props.overview === 'extended_view' ? '#312D32' : 'rgba(49, 45, 50, .7) !important', lineHeight: '1.2' }}
                                    color="text.secondary"
                                    component="div"
                                    className="card-work-ins-text  multi-line-ellipsis"
                                    title={props.workInstruction.workInstructionDescription}
                                >
                                    {props.workInstruction.workInstructionDescription}
                                </Typography>
                                {props.showRecycleBinHome && props.workInstruction.isDeleted && (
                                    <Typography
                                        sx={{ maxHeight: '2.8rem', lineHeight: '1', opacity: '70%', fontSize: props.overview === 'extended_view' ? '12px' : '14px', color: 'rgba(49, 45, 50, .7) !important' }}
                                        color="text.secondary"
                                        component="div"
                                        className="card-work-ins-text  multi-line-ellipsis"
                                    >
                                        {getFormattedDateFromUnixTime(props.workInstruction.deletedOn)}
                                    </Typography>
                                )}
                                {!props.hideRecycleBinHome && (
                                    <Typography
                                        variant={props.overview === 'extended_view' ? 'inherit' : 'subtitle2'}
                                        sx={{ fontSize: props.overview === 'extended_view' ? '12px' : '14px', fontWeight: props.overview === 'extended_view' ? '500' : null, opacity: props.overview === 'extended_view' ? '70%' : null, color: props.overview === 'extended_view' ? 'rgba(49, 45, 50, .7) !important' : null, maxHeight: '2.8rem', lineHeight: '1' }}
                                        color="text.secondary"
                                        component="div"
                                        className="card-work-ins-text  multi-line-ellipsis"
                                    >
                                        {getFormattedDateFromUnixTime(props.workInstruction.createdOn)}
                                    </Typography>
                                )}
                                {
                                    <Typography
                                        variant="button"
                                        color="text.secondary"
                                        component="div"
                                        sx={{ fontSize: props.overview === 'extended_view' ? '8px' : '12px', marginTop: props.overview === 'extended_view' ? '.3rem' : '.8rem', lineHeight: '1.2', fontWeight: props.overview === 'extended_view' ? '500' : null, opacity: props.overview === 'extended_view' ? '70%' : null, color: props.overview === 'extended_view' ? '1F1E20' : 'rgba(49, 45, 50, .7) !important' }}
                                        className="card-work-ins-text one-line-ellipsis"
                                        title={props.workInstruction.brand}
                                    >
                                        {props.workInstruction.brand}
                                    </Typography>
                                }
                            </CardContent>

                            {!props.isDelete && !props.isRevisitRequest && !homePath.test(location.pathname) && (
                                <Box
                                    component="div"
                                    sx={{ display: 'flex', marginBottom: '4px' }}
                                >
                                    <Link to={!props.workInstruction ? '#' : getRoute()}>
                                        <Button
                                            color="primary"
                                            disableRipple
                                            endIcon={<ArrowForward />}
                                            sx={{
                                                '&.MuiButtonBase-root:hover': { bgcolor: 'transparent' },
                                                m: 0,
                                                p: 0,
                                                fontSize: '16px',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                            }}
                                        >
                                            {t('WI_LIST.SEE_PROCEDURES')}
                                        </Button>
                                    </Link>
                                </Box>
                            )}

                            {props.overview === 'normal_view' && (
                                <RecycleBinActionButton
                                    showRecycleBinHome={props.showRecycleBinHome}
                                    isTrainer={props.isTrainer}
                                    overview={props.overview}
                                    restoreDocument={props.restoreDocument}
                                    deletePermanentlyHandler={props.deletePermanentlyHandler}
                                />
                            )}
                            {!props.isDelete && props.isRevisitRequest && (
                                <Box
                                    component="div"
                                    sx={{ display: 'flex', marginBottom: '4px' }}
                                >
                                    <Link to={!props.workInstruction ? '#' : getRoute()}>
                                        <Button
                                            color="primary"
                                            disableRipple
                                            endIcon={<ArrowForward />}
                                            sx={{
                                                '&.MuiButtonBase-root:hover': { bgcolor: 'transparent' },
                                                m: 0,
                                                p: 0,
                                                fontSize: '16px',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                            }}
                                        >
                                            {t('WI_LIST.SEE_PROCEDURES')}
                                        </Button>
                                    </Link>
                                </Box>
                            )}
                        </Box>
                        {!props.isApprovalWindow && isHover && !props.isDelete && !props.isRevisitRequest && props.isTrainer && (
                            <Box
                                sx={{
                                    alignItems: 'center',
                                    justifyContent: 'left',
                                    position: 'absolute',
                                    top: '12px',
                                    right: '12px',
                                    justifyItems: 'end',
                                }}
                            >
                                <IconButton
                                    size="small"
                                    onClick={handleClick}
                                    aria-controls={open ? 'account-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                    sx={{
                                        borderRadius: '5',
                                        p: 0,
                                        color: 'black',
                                    }}
                                >
                                    <MoreHoriz />
                                </IconButton>
                                <Menu
                                    anchorEl={anchorEl}
                                    id="account-menu"
                                    open={open}
                                    onClose={(e: any) => {
                                        e.stopPropagation();
                                        handleClose();
                                    }}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    sx={{ padding: '20px', alignContent: 'left', alignItems: 'left' }}
                                >
                                    {props.workInstruction.is2d && props.selectedFolderWiCard?.size === 1 && (
                                        <MenuItem
                                            onClick={(e) => {
                                                setShowThumbnailDialog(true);
                                                setAnchorEl(null);
                                                e.stopPropagation();
                                            }}
                                        >
                                            <MediaUploadIcon />
                                            &nbsp;{t('COMMON.CHANGE_THUMBNAIL')}
                                        </MenuItem>
                                    )}
                                    {
                                        <MenuItem
                                            onClick={(e) => {
                                                handleMove(e);
                                                e.stopPropagation();
                                            }}
                                        >
                                            <MoveIcon />
                                            &nbsp;{t('COMMON.MOVE')}
                                        </MenuItem>
                                    }
                                    {props.workInstruction.is2d && (
                                        <MenuItem
                                            onClick={(e) => {
                                                handleDuplicateDialog(e);
                                                e.stopPropagation();
                                            }}
                                        >
                                            <DuplicateIcon />
                                            &nbsp;{t('COMMON.DUPLICATE')}
                                        </MenuItem>
                                    )}
                                    {
                                        <MenuItem
                                            onClick={(e) => {
                                                handleDeleteDialog(e);
                                                e.stopPropagation();
                                            }}
                                        >
                                            <DeleteOutlinedIcon sx={{ color: 'black' }} />
                                            &nbsp;{t('COMMON.DELETE')}
                                        </MenuItem>
                                    }{' '}
                                </Menu>
                            </Box>
                        )}
                        {props.overview === 'extended_view' && props.showRecycleBinHome && props.isTrainer && (
                            <Box sx={{ position: 'absolute', right: '8px', bottom: '8px' }}>
                                <IconButton
                                    onClick={props.restoreDocument}
                                    sx={{
                                        borderRadius: '8px',
                                    }}
                                >
                                    <RestoreEntityIcon />
                                </IconButton>
                                <IconButton
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        props.deletePermanentlyHandler();
                                    }}
                                    sx={{
                                        borderRadius: '8px',
                                    }}
                                >
                                    <DeleteEntityIcon sx={{ color: theme.palette.primary.main }} />
                                </IconButton>
                            </Box>
                        )}
                    </Card>
                </div>
            </ClickAwayListener>
            <Card
                sx={{ flexGrow: 1, flexDirection: 'row', display: { xs: 'flex', md: 'none' }, borderRadius: '12px', boxShadow: 'rgba(149, 157, 165, 0.5) 0px 8px 24px', height: '212px' }}
                elevation={0}
                className="work-instruction-card"
                style={{ height: '100%', minHeight: props.recycleBinCardHeight || '212px', width: '100%', minWidth: '400px' }}
            >
                <Box
                    component="div"
                    className="wi-img-container"
                >
                    {props.workInstructionImageURL && (
                        <CardMedia
                            component="img"
                            className="wi-img"
                            image={thumbnail}
                            alt="work instruction model"
                        />
                    )}
                    {!props.workInstructionImageURL && (
                        <CardMedia
                            component="img"
                            className="wi-img"
                            image={fallBackIcon}
                            alt="work instruction model"
                        ></CardMedia>
                    )}
                </Box>
                <Box
                    component="div"
                    sx={{ display: 'flex', flexDirection: 'column' }}
                    className={props.overview === 'extended_view' ? 'work-ins-card-right-extended-view' : 'work-ins-card-right'}
                >
                    <CardContent
                        sx={{ flex: '1 0 auto' }}
                        className="work-ins-card-content"
                    >
                        {!props.isTrainer && props.workInstruction?.completionStatus?.toUpperCase() !== 'NOT STARTED' && (
                            <Stack
                                sx={{ flexDirection: { xs: 'column', md: 'row' }, justifyContent: 'start' }}
                                className="chip-stack"
                                flexWrap={'wrap'}
                            >
                                <Chip
                                    sx={{
                                        height: '18px',
                                        '& .MuiChip-label': {
                                            padding: '0px !important',
                                        },
                                    }}
                                    label={displayTraineeStatus(props.workInstruction.completionStatus)}
                                    variant="outlined"
                                    className={props.workInstruction?.completionStatus?.toUpperCase() === 'COMPLETED' ? 'wi-status-mobile-trainee completed' : 'wi-status-mobile-trainee in-progress'}
                                />
                            </Stack>
                        )}
                        {/* {!props.isTrainer && props.workInstruction?.completionStatus?.toUpperCase() === 'NOT STARTED' &&
                    <Box component="div" sx={{height: '33px'}}></Box>
                } */}
                        {props.isTrainer && (
                            <Stack
                                sx={{ flexDirection: { xs: 'column', md: 'row' }, justifyContent: 'start' }}
                                className="status-msg-align"
                                flexWrap={'wrap'}
                            >
                                {props.workInstruction.status && (
                                    <Box
                                        component="span"
                                        className={'"' + getStatusStyle(props.workInstruction.status, props?.overview) + '"'}
                                        sx={{ ...commonStyles }}
                                        title={t('COMMON.' + translate[props.workInstruction.status]) as string}
                                    >
                                        {props.workInstruction.is2d ? '2d Model Ready' : (t('COMMON.' + translate[props.workInstruction.status]) as string)}
                                    </Box>
                                )}
                            </Stack>
                        )}
                        <Typography
                            component="div"
                            variant="h6"
                            sx={{ maxHeight: '4rem', lineHeight: '1.2', fontSize: '16px', marginTop: props.overview === 'normal_view' ? '24px' : null }}
                            className="card-work-ins-text one-line-ellipsis"
                            title={props.workInstruction.workInstructionName}
                        >
                            {props.workInstruction.workInstructionName}
                        </Typography>
                        <Typography
                            variant="subtitle2"
                            sx={{ maxHeight: '2.8rem', marginBottom: '3px !important', lineHeight: '1.2', fontSize: '13px', color: 'rgba(49, 45, 50, .7) !important' }}
                            color="text.secondary"
                            component="div"
                            className="card-work-ins-text  multi-line-ellipsis"
                            title={props.workInstruction.workInstructionDescription}
                        >
                            {props.workInstruction.workInstructionDescription}
                        </Typography>
                        <Typography
                            variant="subtitle2"
                            sx={{ maxHeight: '2.8rem', lineHeight: '1', color: 'rgba(49, 45, 50, .7) !important' }}
                            color="text.secondary"
                            component="div"
                            className="card-work-ins-text  multi-line-ellipsis"
                        >
                            {getFormattedDateFromUnixTime(props.workInstruction.createdOn)}
                        </Typography>
                        {
                            <Typography
                                variant="button"
                                color="text.secondary"
                                component="div"
                                sx={{ color: 'rgba(49, 45, 50, .7) !important', marginTop: '.8rem', lineHeight: '1.2' }}
                                className="card-work-ins-text one-line-ellipsis"
                                title={props.workInstruction.brand}
                            >
                                {props.workInstruction.brand}
                            </Typography>
                        }
                    </CardContent>
                    <Box
                        component="div"
                        sx={{ display: 'flex', marginBottom: '4px' }}
                    >
                        <Link to={!props.workInstruction ? '#' : getRoute()}>
                            <Button
                                color="primary"
                                disableRipple
                                endIcon={<ArrowForward />}
                                sx={{ '&.MuiButtonBase-root:hover': { bgcolor: 'transparent' }, m: 0, p: 0 }}
                            >
                                {t('WI_LIST.SEE_PROCEDURES')}
                            </Button>
                        </Link>
                    </Box>
                </Box>
            </Card>
        </>
    );
};

export default WorkInstructionCards;
